export const openRequestPbModal = (predefinedFields = {}) => ({
    type: 'REQUEST_PB/openModal',
    payload: { predefinedFields },
})

export const overWriteRequestPbFields = (fields = {}) => ({
    type: 'REQUEST_PB/overwriteFields',
    payload: { fields },
})

export const hideRequestPbModal = () => ({ type: 'REQUEST_PB/hideModal' })

export const clearRequestPbModal = () => ({ type: 'REQUEST_PB/clearModal' })
