export const decreaseZIndex = () => {
    const waitForElement = (selector: string, zIndex?: number) => {
        const interval = setInterval(() => {
            const element = document.querySelector<HTMLElement>(selector)

            if (element) {
                element.style.zIndex = zIndex ? String(zIndex) : '900'
                clearInterval(interval)
            }
        }, 1000)
    }

    if (window.location.host.includes('hubspot.com')) {
        waitForElement('#hubspot-messages-iframe-container')
    } else if (window.location.host.includes('expensify.com')) {
        waitForElement('#native_chat_launcher')
    } else if (window.location.host.includes('guidde.monday.com')) {
        waitForElement('.apps-marketplace-portal', 9999)
    } else if (window.location.host.includes('jpu.io')) {
        waitForElement('#top-header')
        waitForElement('#main-header')
    } else if (window.location.host.includes('portal.yellowfinbi.com')) {
        waitForElement('#headerBG', 10000)
    } else if (window.location.host.includes('shamir.com')) {
        Array.from(window.document.styleSheets).forEach(stylesheet => {
            try {
                const rules = stylesheet.cssRules

                if (rules) {
                    Array.from(rules).forEach((rule, index) => {
                        if (rule.cssText.includes('button:not(.nav__burger)')) {
                            const modifiedRule = rule.cssText
                                .split(',')
                                .map(text =>
                                    text.includes('button:not')
                                        ? text.replace(
                                              'button:not',
                                              'button:not([class*="guidde"]):not'
                                          )
                                        : text
                                )
                                .join(',')

                            stylesheet.deleteRule(index)
                            stylesheet.insertRule(modifiedRule)
                        }
                    })
                }
            } catch (e) {}
        })
    } else if (window.location.host.includes('zendesk.com')) {
        // Dudes set {overflow:hidden} on body and it auto-scrolls to right on ext render
        let scrollLeft: number = 0

        const zendeskInterval = setInterval(() => {
            const body = document.querySelector<HTMLElement>('body')
            const guiddeExt = document.getElementById('guidde-extension')

            if (body && guiddeExt && body.scrollLeft !== scrollLeft) {
                body.scrollLeft = 0
                clearInterval(zendeskInterval)
            }

            scrollLeft = body?.scrollLeft || 0
        }, 10)
    }
}

/* When dragging an element over iframe, iframe doesn't bubble events and breaks drag-n-drop.
 *
 * To fix:
 * 1. Use "disablePointersOnIframes" on dragStart
 * 2. Use "restorePointersOnIframes" on dragEnd
 *  */
export const disablePointersOnIframes = () => {
    const iframes = document.getElementsByTagName('iframe')
    Array.from(iframes).forEach(iframe => {
        iframe.classList.add('GUIDDE-DisablePointers')
    })
}

export const restorePointersOnIframes = () => {
    const iframes = document.getElementsByTagName('iframe')
    Array.from(iframes).forEach(iframe => {
        iframe.classList.remove('GUIDDE-DisablePointers')
    })
}
