import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import clsx from 'clsx'

import {
    Avatar,
    Badge,
    Box,
    Button,
    Grid,
    makeStyles,
    MenuItem,
    MenuList,
    Paper,
    Typography,
} from '@material-ui/core'

import config from 'config'

import CloseIcon from '@material-ui/icons/Close'
import ShareIcon from '@material-ui/icons/Share'
import EditIcon from '@material-ui/icons/Edit'
import LaunchIcon from '@material-ui/icons/Launch'
import LinkIcon from '@material-ui/icons/Link'
import ViewIcon from '@material-ui/icons/Visibility'
import FastForwardIcon from '@material-ui/icons/FastForward'

import { ReactComponent as RatingIcon } from 'assets/icons/icon-feedback.svg'

import {
    changeVideoSpeed,
    logToMixpanel,
    openExternalLink,
    sendViewNotification,
} from 'background/services'

import { AppContext } from 'app/AppProvider'

import {
    Description,
    PlaybookVisibility,
    SpacedGroup,
    VideoPlayer,
    ExtensionOnly,
} from 'UI/Components'
import { VideoReactions, VideoReactionsAnswers } from './VideoReactions'

import { RootState } from 'ducks/rootReducer'
import { useBoolean, useRealtimeVideoTime } from 'hooks'

import { playbookToMixpanelProps } from 'services/mixpanel'
import { generatePlayerId } from 'services/video'
import { envLogicRouter, sdkOnly } from 'services/environmentService'
import { sendMessageToParentScript } from 'services/sdkServices'

const useStyles = makeStyles(theme => ({
    videoWrapper: {
        position: 'relative',
        width: '100%',
        height: 0,
        paddingBottom: '56.25%', // when the aspect ratio of a video is 16:9. (9 is 56.25% of 16). If your video is 4:3, set it to 75%.
        background: 'black',
    },
    gray: {
        color: '#78909c',
    },
    avatar: {
        width: '24px',
        height: '24px',
    },
    controlBtn: {
        position: 'absolute',
        right: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgb(38 50 56 / 0.50);',
        minWidth: '40px !important',
        minHeight: '40px',
        borderRadius: '50%',
        padding: 0,
        opacity: 0,
        transform: 'translateX(30px)',
        transition: 'opacity 0.1s linear, transform 0.1s linear',
        '&:hover': {
            backgroundColor: 'rgb(38 50 56 / 80%)',
        },
        '& svg': {
            color: 'white',
        },
    },
    controlBtnVisible: {
        opacity: 1,
        transform: 'translateX(0)',
    },
    closeBtn: {
        top: '8px',
    },
    shareBtn: {
        top: '56px',
        transitionDelay: '0.1s !important',
    },
    transcriptBtn: {
        top: '104px',
        transitionDelay: '0.2s !important',
    },
    speedBtn: {
        transitionDelay: ({ isSDK }: any) =>
            isSDK ? '0.1s !important' : '0.3s !important',
        top: ({ isSDK }: any) => (isSDK ? '56px' : '150x'),
    },
    copyBtn: {
        top: '196px',
        transitionDelay: '0.4s !important',
    },
    editBtn: {
        transitionDelay: '0.5s !important',
        top: '242px',
    },
    copyList: {
        top: '200px',
        position: 'absolute',
        right: '8px',
        zIndex: 2,
    },
    wrapText: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    label: {
        fontSize: '13px',
    },
    badge: {
        left: '-4px',
        top: '-4px',
        fontWeight: 600,
        fontSize: '10px',
    },
    countItem: {
        width: '20px',
        height: '20px',
        color: theme.palette.text.primary,
    },
    rantingIcon: {
        marginBottom: '-2px',
        marginRight: '-1px !important',
    },
}))

const formatDate = seconds =>
    new Date(seconds * 1000).toString().substring(4, 16)

const minimalRatingToShow = 50

export const VideoPanel = ({
    hoverState,
    showSidePanel,
    showShareDialog,
    playbook,
    startTime,
}) => {
    const classes = useStyles(
        envLogicRouter(
            () => ({ isSDK: true }),
            () => null
        )
    )

    const { playbookMode, active } = useContext(AppContext)
    const [videoSpeed, changeSpeed] = useState(1)

    const copyDropdown = useBoolean()
    const showReactions = useBoolean()
    const showFinishStep = useBoolean()

    const realVideoTime = useRealtimeVideoTime()

    const { isPublic, title: playbookTitle, originalFileName } = playbook

    const increaseVideoSpeed = useCallback(() => {
        changeSpeed(prevSpeed => {
            const newSpeed = prevSpeed === 2 ? 1 : prevSpeed + 0.25
            envLogicRouter(
                () => {
                    const playerId = generatePlayerId()

                    return ((
                        document.getElementById(playerId) as HTMLVideoElement
                    ).playbackRate = newSpeed)
                },
                () => changeVideoSpeed({ speed: newSpeed })
            )

            return newSpeed
        })
    }, [])

    const mixpanelPayload = playbookToMixpanelProps(playbook)

    const toggleShareDialog = () => showShareDialog.toggle()

    const title = playbookTitle || originalFileName?.replace(/\.mp4$/i, '')

    const { user, roles } = useSelector((state: RootState) => state.user)
    const { uid } = user

    const isOwner = playbook?.creator_uid === user.uid

    // Send email notification to the video owner every time when someone watches playbook
    useEffect(() => {
        if (
            playbook &&
            Object.values(playbook).length > 0 &&
            !isOwner &&
            !roles.s
        ) {
            const viewKey = `playbookViews/${user.uid}/${playbook.id}`
            const isViewed = !!sessionStorage.getItem(viewKey)
            if (isViewed) return

            sendViewNotification(() => {}, {
                orgId: roles?.o,
                uid: user.uid,
                playbookId: playbook.id,
            })
            logToMixpanel('videoPlay', playbookToMixpanelProps(playbook))

            sessionStorage.setItem(viewKey, Date.now().toString())
        }
    }, [playbook, roles.o, roles.s, user.uid, isOwner])

    const canEditPlaybook =
        isOwner ||
        roles.s ||
        (roles.a === 100 && roles.o === playbook.organizationId)

    const videoCopyLink = useMemo(() => {
        const path =
            (isPublic ? '/share/playbooks/' : '/playbooks/') +
            playbook.id +
            '?origin=' +
            uid

        return config.firebase.authDomain + path
    }, [playbook, uid])

    const onCopyLink = useCallback(() => {
        logToMixpanel('copyLink', {
            context: 'video-player',
            ...playbookToMixpanelProps(playbook),
        })
    }, [])

    return (
        <>
            <Box className={classes.videoWrapper}>
                <VideoPlayer playbookURL={playbook.url} startTime={startTime} />
                <VideoReactionsAnswers
                    playbookId={playbook.id}
                    showReactions={showReactions}
                    showFinishStep={showFinishStep}
                />
            </Box>
            {showReactions.isFalse && (
                <>
                    <Button
                        title="Close playbook"
                        color="default"
                        onClick={() => {
                            logToMixpanel('videoClose', mixpanelPayload)
                            playbookMode.setFalse()
                            sdkOnly(() => {
                                sendMessageToParentScript({
                                    action: 'RESIZE_TO_NORMAL',
                                })
                                active.setTrue()
                            })()
                        }}
                        size="small"
                        className={clsx(
                            classes.controlBtn,
                            classes.closeBtn,
                            hoverState.isTrue && classes.controlBtnVisible
                        )}
                    >
                        <CloseIcon />
                    </Button>

                    <ExtensionOnly>
                        <Button
                            title="Share playbook"
                            color="default"
                            onClick={() => {
                                logToMixpanel(
                                    'share_pb_btn_clicked',
                                    mixpanelPayload
                                )
                                toggleShareDialog()
                            }}
                            size="small"
                            className={clsx(
                                classes.controlBtn,
                                classes.shareBtn,
                                hoverState.isTrue && classes.controlBtnVisible
                            )}
                            style={{ pointerEvents: 'auto' }}
                        >
                            <ShareIcon />
                        </Button>
                    </ExtensionOnly>

                    <ExtensionOnly>
                        <Button
                            title="Toggle side panel"
                            color="default"
                            onClick={() => {
                                logToMixpanel(
                                    showSidePanel.isTrue
                                        ? 'hide_transcript'
                                        : 'show_transcript',
                                    mixpanelPayload
                                )
                                showSidePanel.toggle()
                            }}
                            size="small"
                            className={clsx(
                                classes.controlBtn,
                                classes.transcriptBtn,
                                hoverState.isTrue && classes.controlBtnVisible
                            )}
                        >
                            <LaunchIcon />
                        </Button>
                    </ExtensionOnly>

                    <Button
                        title="Change video speed"
                        color="default"
                        onClick={increaseVideoSpeed}
                        size="small"
                        className={clsx(
                            classes.controlBtn,
                            classes.speedBtn,
                            hoverState.isTrue && classes.controlBtnVisible
                        )}
                    >
                        <Badge
                            badgeContent={`${(
                                Math.round(videoSpeed * 100) / 100
                            ).toFixed(2)}x`}
                            color="primary"
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            classes={{
                                badge: classes.badge,
                            }}
                        >
                            <FastForwardIcon />
                        </Badge>
                    </Button>

                    <ExtensionOnly>
                        <Button
                            title="Copy playbook link"
                            color="default"
                            size="small"
                            className={clsx(
                                classes.controlBtn,
                                classes.copyBtn,
                                hoverState.isTrue && classes.controlBtnVisible
                            )}
                            onClick={copyDropdown.toggle}
                        >
                            <LinkIcon />
                        </Button>

                        {copyDropdown.isTrue && hoverState.isTrue && (
                            <Paper className={classes.copyList}>
                                <MenuList
                                    id="split-button-menu"
                                    onClick={copyDropdown.setFalse}
                                    onMouseLeave={copyDropdown.setFalse}
                                >
                                    <CopyToClipboard
                                        text={videoCopyLink}
                                        onCopy={onCopyLink}
                                    >
                                        <MenuItem>Copy Link</MenuItem>
                                    </CopyToClipboard>
                                    <CopyToClipboard
                                        text={`${videoCopyLink}&t=${realVideoTime}`}
                                        onCopy={onCopyLink}
                                    >
                                        <MenuItem>
                                            Copy Link at current time
                                        </MenuItem>
                                    </CopyToClipboard>
                                </MenuList>
                            </Paper>
                        )}

                        {canEditPlaybook && (
                            <Button
                                title="Edit playbook"
                                color="default"
                                size="small"
                                className={clsx(
                                    classes.controlBtn,
                                    classes.editBtn,
                                    hoverState.isTrue &&
                                        classes.controlBtnVisible
                                )}
                                onClick={() => {
                                    openExternalLink(null, {
                                        url: `playbooks/${playbook.id}/edit`,
                                    })
                                }}
                            >
                                <EditIcon />
                            </Button>
                        )}
                    </ExtensionOnly>
                </>
            )}

            <Box py={2} pl={2}>
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <SpacedGroup>
                            <Typography
                                className={classes.wrapText}
                                style={{ fontWeight: 'bold' }}
                            >
                                {title}
                            </Typography>

                            <SpacedGroup spacing={1}>
                                {playbook.views !== 0 && (
                                    <SpacedGroup spacing={0.3}>
                                        <ViewIcon
                                            className={classes.countItem}
                                        />
                                        <Typography className={classes.label}>
                                            {playbook.views}
                                        </Typography>
                                    </SpacedGroup>
                                )}
                                {playbook.rating > minimalRatingToShow && (
                                    <SpacedGroup
                                        spacing={0.3}
                                        style={{ marginBottom: '-2px' }}
                                    >
                                        <RatingIcon
                                            className={clsx(
                                                classes.countItem,
                                                classes.rantingIcon
                                            )}
                                        />
                                        <Typography className={classes.label}>
                                            {playbook.rating}%
                                        </Typography>
                                    </SpacedGroup>
                                )}
                            </SpacedGroup>
                        </SpacedGroup>
                    </Grid>

                    <ExtensionOnly>
                        {!isOwner && (
                            <Grid
                                item
                                xs={4}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    marginTop: '-3px',
                                }}
                            >
                                <VideoReactions
                                    playbookId={playbook.id}
                                    showReactions={showReactions}
                                    showFinishStep={showFinishStep}
                                />
                            </Grid>
                        )}
                    </ExtensionOnly>
                </Grid>
                <SpacedGroup spacing={0.625} style={{ marginTop: '5px' }}>
                    <Avatar
                        className={classes.avatar}
                        src={playbook?.userImage}
                    />
                    <Typography variant="body2">
                        {playbook?.username}
                    </Typography>
                    <Typography variant="body2">
                        {formatDate(playbook?.uploadedAt)}
                    </Typography>
                </SpacedGroup>
                <SpacedGroup spacing={1} style={{ marginTop: '10px' }}>
                    <ExtensionOnly>
                        <PlaybookVisibility visibility={playbook.visibility} />
                    </ExtensionOnly>

                    {playbook.applications?.length > 0 && (
                        <Typography variant="body2">
                            {playbook.applications
                                ?.map(it => it.applicationName)
                                .join(', ')}
                        </Typography>
                    )}

                    <ExtensionOnly>
                        <Typography
                            variant="body2"
                            className={clsx(classes.gray, classes.label)}
                        >
                            {playbook.tags?.map(tag => `#${tag}`).join('  ')}
                        </Typography>
                    </ExtensionOnly>
                </SpacedGroup>
                <Box mt={2}>
                    <Description description={playbook.description} />
                </Box>
            </Box>
        </>
    )
}
