import { checkIsExtension } from './environmentService'

/**
 * Converts a relative path to a resource to a path depending on the context,
 * in which the resource is loaded.
 * @param {string} path A relative path to a resource
 * @example
 * import logo from "./logo.svg";
 *
 * function ImageComponent() {
 *   return (
 *     <img
 *       src={EXT_SERVICES.getResourceURL(logo)}
 *       alt="logo"
 *     />
 *   );
 * }
 *
 */
export const getResourceURL = (path: string) => {
    if (checkIsExtension()) {
        return window.chrome.runtime.getURL(path)
    }

    return path
}

/* Chrome storage ops */

export const setToExtLocalStorage = (
    key: string,
    value: any,
    onSetKeys?: () => any
) => {
    chrome?.storage?.local.set({ [key]: value }, onSetKeys)

    // Because chrome storage won't work on localhost
    if (process.env.NODE_ENV === 'development') {
        onSetKeys?.()
    }
}

export const getFromExtLocalStorage = (
    keys: string[],
    onGetKeys: (result: any) => void
) => {
    chrome?.storage?.local.get(keys, onGetKeys)

    // Because chrome storage won't work on localhost
    if (process.env.NODE_ENV === 'development') {
        onGetKeys?.({})
    }
}

export const getExtensionVersion = () =>
    chrome?.runtime?.getManifest()?.version || ''
