import React, { memo, useEffect, useMemo, useRef, useState } from 'react'
import clsx from 'clsx'

import {
    Box,
    Chip,
    LinearProgress,
    makeStyles,
    Typography,
} from '@material-ui/core'
import { AccessTime } from '@material-ui/icons'

import { changeVideoTime, getTranscriptFile } from 'background/services'

import { useRealtimeVideoTime } from 'hooks/use-realtime-video-time'
import { generatePlayerId, generateTranscriptRanges } from 'services/video'
import { envLogicRouter } from '../../../../../services/environmentService'

const useStyles = makeStyles({
    chip: {
        fontSize: '10px',
    },
    transcriptText: {
        lineHeight: 2,
    },
    activeSegment: {
        background: 'rgba(203, 0, 0, 0.2)',
    },
})

export type Transcript = {
    time: string
    text: string
    startTime: number
}

const scrollToElement = el => {
    if (!el) return
    el.scrollIntoView({ behavior: 'smooth' })
}

const checkActiveSegment = (realVideoTime, transcriptRanges, idx) => {
    if (!transcriptRanges[idx]) return false

    return (
        transcriptRanges[idx].start < realVideoTime &&
        (transcriptRanges[idx].finish > realVideoTime ||
            transcriptRanges[idx].finish === 'none')
    )
}

type Props = {
    transcriptUrl: string
    startTime?: number
}

export const TranscriptTab = memo(({ transcriptUrl, startTime = 0 }: Props) => {
    const classes = useStyles()

    const [segments, setSegments] = useState<undefined | Array<Transcript>>()

    const mapList = useMemo(() => new Map(), [])
    const activeSegmentRef = useRef<number>(0)

    const realVideoTime = useRealtimeVideoTime() || startTime

    const transcriptRanges = useMemo(
        () => generateTranscriptRanges(segments),
        [segments]
    )

    useEffect(() => {
        getTranscriptFile(setSegments, { transcriptUrl })
    }, [transcriptUrl])

    useEffect(() => {
        if (!mapList.size) return

        const ind = segments?.findIndex((_, idx) =>
            checkActiveSegment(realVideoTime, transcriptRanges, idx)
        )
        const activeIndex = ind === undefined || ind < 0 ? 0 : ind

        const activeSegment = Array.from(mapList)[activeIndex]?.[1]

        if (activeSegmentRef.current !== activeIndex && activeSegment) {
            activeSegmentRef.current = activeIndex
            scrollToElement(activeSegment)
        }
    }, [mapList, realVideoTime, segments, transcriptRanges])

    if (!segments) return <LinearProgress />

    return (
        <>
            {segments?.length === 0 && (
                <Typography>
                    There is no transcript for this video. Please check again
                    later.
                </Typography>
            )}

            {segments?.map((segment, idx) => {
                return (
                    <div
                        ref={node => {
                            mapList.set(segment.startTime, node)
                        }}
                        key={idx}
                    >
                        <Box mt={2}>
                            <Chip
                                size="small"
                                label={segment.time}
                                avatar={<AccessTime />}
                                className={classes.chip}
                                onClick={() => {
                                    envLogicRouter(
                                        () => {
                                            const playerId = generatePlayerId()

                                            return ((
                                                document.getElementById(
                                                    playerId
                                                ) as HTMLVideoElement
                                            ).currentTime = segment.startTime)
                                        },
                                        () =>
                                            changeVideoTime({
                                                seconds: segment.startTime,
                                            })
                                    )
                                }}
                            />
                            <Box mt={1}>
                                <Typography
                                    variant="body2"
                                    className={clsx(classes.transcriptText, {
                                        [classes.activeSegment]:
                                            checkActiveSegment(
                                                realVideoTime,
                                                transcriptRanges,
                                                idx
                                            ),
                                    })}
                                >
                                    {segment.text}
                                </Typography>
                            </Box>
                        </Box>
                    </div>
                )
            })}
        </>
    )
})
