import { memo, useCallback, useMemo, useState } from 'react'

import { useSelector } from 'react-redux'

import {
    Box,
    Button,
    makeStyles,
    MenuItem,
    Select,
    Typography,
} from '@material-ui/core'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt'
import PublicIcon from '@material-ui/icons/Public'

import { useNotification } from 'hooks'
import { PlaybookType } from 'types/playbook'

import Config from 'config'

import { logToMixpanel } from 'background/services'
import { playbookToMixpanelProps } from 'services/mixpanel'
import { copyToClipboard } from 'services/utils'

import { RootState } from 'ducks/rootReducer'

const useStyles = makeStyles(theme => ({
    publicViewContainer: {
        padding: theme.spacing(2),
        height: '100px',
        width: '100%',
        borderRadius: '4px',
        position: 'absolute',
        bottom: '-120px',
        background: 'white',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',

        '& button': {
            flexShrink: 0,
            marginLeft: '16px',
        },

        '& > div': {
            marginRight: 'auto',
        },
    },
    roundIcon: {
        border: '1px solid gray',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '4px',
    },
}))

const publicity = {
    restricted: 'Restricted',
    anyone: 'Anyone with the link',
}

type Props = {
    playbook: PlaybookType
    disabled: boolean
    onClose?: () => void
    onCopyClick?: () => void
}

export const ShareableLink = memo(
    ({ playbook, onClose, onCopyClick, disabled = true }: Props) => {
        const { isPublic, isPlaylist, id: playbookId, creator_uid } = playbook

        const classes = useStyles()
        const { showSuccessNotification } = useNotification()

        const { user, roles } = useSelector((state: RootState) => state.user)
        const { email, uid, displayName } = user

        const [linkType, setLinkType] = useState(
            isPublic ? publicity.anyone : publicity.restricted
        )

        const isRestrictedType = useMemo(
            () => linkType === publicity.restricted,
            [linkType]
        )

        const copyLink = useCallback(() => {
            const commonView = isPlaylist
                ? '/playbooks/playlist/'
                : '/playbooks/'
            const publicView = isPlaylist
                ? '/share/playlists/'
                : '/share/playbooks/'

            const path =
                (isRestrictedType ? commonView : publicView) + playbookId
            const origin = '?origin=' + uid
            const link = Config.firebase.authDomain + path + origin

            logToMixpanel('copyLink', {
                context: 'share-link',
                ...playbookToMixpanelProps(playbook),
            })

            copyToClipboard(link).then(() =>
                showSuccessNotification('Link copied to the clipboard')
            )
            onCopyClick?.()
        }, [
            isPlaylist,
            isRestrictedType,
            onCopyClick,
            playbook,
            playbookId,
            showSuccessNotification,
            uid,
        ])

        const handleChange = e => {
            setLinkType(e.target.value)
        }

        const isOwner = creator_uid === uid
        const isAdmin = roles.a === 100
        const isContentManager = roles.a === 60
        const hasEditRights =
            (isAdmin || isContentManager) &&
            roles.o === playbook.uploadedByOrgId

        const canSeeTracking = isPublic && (isOwner || roles.s || hasEditRights)

        return (
            <Box className={classes.publicViewContainer}>
                <Box>
                    <Typography style={{ marginBottom: '8px' }}>
                        <b>Get shareable link</b>
                    </Typography>

                    <Box mt={1} display="flex" alignItems="center">
                        <Box mr={1} className={classes.roundIcon}>
                            {isRestrictedType ? (
                                <PeopleAltIcon fontSize="small" />
                            ) : (
                                <PublicIcon fontSize="small" />
                            )}
                        </Box>
                        <Box>
                            <Select
                                value={linkType}
                                onChange={handleChange}
                                disabled={disabled}
                                style={{ fontSize: '14px' }}
                            >
                                {Object.values(publicity).map((type, idx) => (
                                    <MenuItem key={idx} value={type}>
                                        {type}
                                    </MenuItem>
                                ))}
                            </Select>
                            <Typography variant="body2">
                                {isRestrictedType
                                    ? 'Only members and spaces added can open with this link'
                                    : 'Anyone on the internet with this link can view'}
                            </Typography>
                        </Box>
                    </Box>
                </Box>

                <Button color="default" onClick={copyLink}>
                    Copy link
                </Button>

                {canSeeTracking && (
                    <Button
                        onClick={() => {
                            onClose?.()
                            logToMixpanel('track_link', {
                                displayName,
                                uid,
                                email,
                            })
                        }}
                        color="default"
                    >
                        Track link
                    </Button>
                )}
            </Box>
        )
    }
)
