import React from 'react'
import clsx from 'clsx'

import Chip from '@material-ui/core/Chip'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    tag: {
        border: 'none',
        fontSize: '16px',
        flexDirection: 'row',
    },
    defaultTag: {
        color: theme.palette.primary.main,
    },
    popper: {
        position: 'absolute',
        top: '32px',
    },
}))

type Props = {
    tag: string
    isHighlighted?: boolean
    onDelete: () => void
}

export const Tag = ({ isHighlighted, tag, onDelete }: Props) => {
    const classes = useStyles()

    return (
        <Chip
            className={clsx(classes.tag, {
                [classes.defaultTag]: isHighlighted,
            })}
            variant="outlined"
            size="small"
            label={`#${tag}`}
            onDelete={onDelete}
        />
    )
}
