import {
    getApps,
    getPlaybookList,
    getPlaybook,
    getPlaybooks,
    getTagConditions,
    getTranscriptFile,
    sendViewNotification,
} from '../api'
import { logEvent } from '../background/mixpanel'
import { getUser } from '../firebase'

export const sdkScript = async ({ action, params }) => {
    switch (action) {
        case 'getFirebaseUser':
            return getUser()

        case 'getTagConditions':
            return await getTagConditions(params.domain)

        case 'logToMixpanel':
            return logEvent(params.event, params.params)

        case 'getApps':
            return getApps()

        case 'getPlaybook':
            return getPlaybook(params.playbookId)

        case 'getPlaybooks':
            return getPlaybooks(params)

        case 'getPlaybookList':
            return getPlaybookList(params)

        case 'getTranscriptFile':
            return getTranscriptFile(params.transcriptUrl)

        case 'sendViewNotification':
            return sendViewNotification({ params })
        default:
            return
    }
}
