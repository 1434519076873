import { useContext, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Box, Grid, LinearProgress, makeStyles } from '@material-ui/core'

import { useBoolean, useNotification } from 'hooks'

import { generatePlaybook } from 'UI/Pages/playbooks/PlaybookHits'
import { ShareDialog } from 'UI/Components'
import { VideoPanel } from './VideoPanel'
import { SidePanel } from './SidePanel'

import { getPlaybookList } from 'background/services'
import {
    resetPlaybook,
    resetPlaylist,
    setActivePlaybook,
    setPlaybookList,
} from 'ducks/actions'
import { RootState } from 'ducks/rootReducer'

import { isSDK, sdkOnly } from 'services/environmentService'
import { sendMessageToParentScript } from 'services/sdkServices'

import CompanyLogo from 'assets/icons/g-icon.png'
import { AppContext } from 'app/AppProvider'

const useStyles = makeStyles({
    wrapper: {
        display: 'flex',
        justifyContent: 'center',
        borderTop: '5px solid #cb0000',
        position: 'relative',
    },
    videoContainer: {
        position: 'relative',
    },
    transcript: {
        overflow: 'overlay',
    },
    iconWrapper: {
        display: 'flex',
        position: 'absolute',
        zIndex: 2,
        left: 0,
        top: 0,
        width: '17px',
        height: '17px',
        backgroundColor: '#cb0000',
    },
    icon: {
        width: '100%',
        height: '100%',
    },
})

export const PagePlaylist = () => {
    const classes = useStyles()

    const { playlistMode } = useContext(AppContext)
    const { showErrorNotification } = useNotification()

    const playerContainer = useRef<HTMLDivElement | null>(null)

    const { playbook, sdkConfig } =
        useSelector((state: RootState) => state) || {}

    const { icon, color } = sdkConfig

    const { playbookList, active } = useSelector(
        (state: RootState) => state.playlist
    )

    const hoverState = useBoolean()
    const showSidePanel = useBoolean(true)

    const dispatch = useDispatch()

    const showShareDialog = useBoolean()
    const toggleShareDialog = () => showShareDialog.toggle()

    useEffect(() => {
        getPlaybookList(
            payload => {
                if (payload) {
                    const generatedList = payload.playbookList?.map(playbook =>
                        generatePlaybook(playbook)
                    )

                    dispatch(setPlaybookList(generatedList || []))
                }
            },
            {
                playbookId: playbook?.id,
                playbookIds: playbook?.playbookList ?? [],
            }
        )
    }, [dispatch])

    useEffect(() => {
        return () => {
            dispatch(setActivePlaybook(0))
            dispatch(resetPlaybook())
            dispatch(resetPlaylist())
        }
    }, [dispatch])

    if (!playbookList) {
        return <LinearProgress />
    }

    if (
        !playbook ||
        Object.values(playbook).length === 0 ||
        playbookList.length === 0
    ) {
        playlistMode.setFalse()
        showErrorNotification(
            'Playlist is not available. Please try again later'
        )
        sdkOnly(() => {
            sendMessageToParentScript({
                action: 'RESIZE_TO_NORMAL',
            })
        })()
        return null
    }

    return (
        <Box
            className={classes.wrapper}
            style={{
                width: isSDK
                    ? 'auto'
                    : showSidePanel.isTrue
                    ? 'calc(max(1000px, 50vw))'
                    : 'calc(max(600px, 25vw))',
                borderColor: color || '#cb0000',
            }}
        >
            <Box
                className={classes.iconWrapper}
                style={{
                    backgroundColor: color || '#cb0000',
                }}
            >
                <img
                    src={icon || CompanyLogo}
                    alt="guidde_logo"
                    className={classes.icon}
                />
            </Box>

            <Grid
                container
                onMouseEnter={hoverState.setTrue}
                onMouseLeave={hoverState.setFalse}
            >
                <Grid
                    item
                    xs={12}
                    md={showSidePanel.isTrue ? 8 : 12}
                    className={classes.videoContainer}
                    ref={playerContainer}
                >
                    <VideoPanel
                        hoverState={hoverState}
                        showSidePanel={showSidePanel}
                        showShareDialog={showShareDialog}
                        playbook={playbookList?.[active]}
                    />
                </Grid>

                {showSidePanel.isTrue && (
                    <Grid
                        item
                        xs={12}
                        md={4}
                        className={classes.transcript}
                        style={{
                            maxHeight:
                                playerContainer?.current?.offsetHeight || 480,
                        }}
                    >
                        <SidePanel />
                    </Grid>
                )}
            </Grid>
            {showShareDialog.isTrue && (
                <ShareDialog
                    isOpen
                    onClose={toggleShareDialog}
                    playbook={playbook}
                    title="Share your playlist"
                    subtitle="Invite people to view your Playlist"
                />
            )}
        </Box>
    )
}
