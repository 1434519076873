import React, { useContext } from 'react'
import { Avatar, Box, Typography } from '@material-ui/core'
import { AppContext } from 'app/AppProvider'

type RateItemProps = {
    name: string
    imgURL: string
    imgVariant?: any
    rate: number | string
    id?: string
    className?: string
}

export const RateItem = ({
    rate,
    imgURL,
    imgVariant,
    name,
    id,
    className,
}: RateItemProps) => {
    const { setPlaybookId, openPlaybookMode } = useContext(AppContext)
    return (
        <Box
            p={2}
            display="flex"
            onClick={() => {
                if (id) {
                    openPlaybookMode()
                    setPlaybookId(id)
                }
            }}
            className={className}
        >
            <Avatar src={imgURL} variant={imgVariant} />
            <Box ml={2}>
                <Typography>{name}</Typography>
                <Typography>
                    <b> {rate}</b>
                </Typography>
            </Box>
        </Box>
    )
}
