import React, { useContext } from 'react'
import { useDispatch } from 'react-redux'

import {
    Box,
    List,
    ListItem,
    Typography,
    Link as MaterialLink,
} from '@material-ui/core'

import { AppContext } from 'app/AppProvider'

import { formatTime } from 'services/utils'
import { setPlaybook } from 'ducks/actions'

export const TranscriptList = ({ playbook, classes }) => {
    const dispatch = useDispatch()

    const { openPlaybookMode, openPlaylistMode } = useContext(AppContext)
    return (
        <List>
            {playbook?.sections?.map(o => {
                const result = o._snippetResult.transcript || null
                if (result && result.matchLevel !== 'none') {
                    return (
                        <ListItem key={o.id}>
                            <MaterialLink
                                key={playbook.id}
                                className={classes.listItemLink}
                                onClick={() => {
                                    dispatch(setPlaybook(playbook))

                                    if (playbook?.isPlaylist) {
                                        openPlaylistMode()
                                    } else {
                                        openPlaybookMode(
                                            Math.round(o.startTime)
                                        )
                                    }
                                }}
                            >
                                <Box display="flex" alignItems="center">
                                    <Typography className={classes.timeLabel}>
                                        {formatTime(o.startTime)}
                                    </Typography>
                                    <Typography
                                        noWrap
                                        style={{
                                            fontWeight: 'normal',
                                        }}
                                        dangerouslySetInnerHTML={{
                                            __html: `...${result.value}...`,
                                        }}
                                        component="span"
                                    />
                                </Box>
                            </MaterialLink>
                        </ListItem>
                    )
                }
                return null
            })}
        </List>
    )
}
