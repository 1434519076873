import clsx from 'clsx'
import { makeStyles, Divider, Card } from '@material-ui/core'

import { PlaybookHeader } from './PlaybookHeader'
import { PlaybookContent } from './PlaybookContent'
import { PlaybookFooter } from './PlaybookFooter'
import { ExtensionOnly } from 'UI/Components/EnvComponents'

import { TypeUseBoolean, useBoolean } from 'hooks'
import { PlaybookType } from 'types/playbook'

const useStyles = makeStyles({
    card: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        '&:hover': {
            boxShadow:
                '0 5px 6px -3px rgba(0, 0, 0, 0.2), 0 3px 16px 2px rgba(0, 0, 0, 0.12), 0 9px 12px 1px rgba(0, 0, 0, 0.14)',
        },
        overflow: 'visible',
    },
    highlighted: {
        zIndex: 3,
        border: '2px solid #C33625',
        boxShadow:
            '0 5px 6px -3px rgba(0, 0, 0, 0.2), 0 3px 16px 2px rgba(0, 0, 0, 0.12), 0 9px 12px 1px rgba(0, 0, 0, 0.14)',
    },
})

type Props = {
    playbook: PlaybookType
    firstInstall?: TypeUseBoolean
}

export const PlaybookCard = ({ playbook, firstInstall }: Props) => {
    const { duration, title, tags, uploadedAt, username } = playbook

    const isHighlighted: boolean = firstInstall?.isTrue || false

    const classes = useStyles()

    const renderState = useBoolean()
    const hoverState = useBoolean()

    return (
        <Card
            className={clsx(classes.card, {
                [classes.highlighted]: isHighlighted,
            })}
            variant="outlined"
            onMouseOver={() => {
                hoverState.setTrue()
                renderState.setTrue()
            }}
            onMouseLeave={hoverState.setFalse}
        >
            <PlaybookHeader
                firstInstall={firstInstall}
                isHighlighted={isHighlighted}
                playbook={playbook}
                showControls={hoverState.isTrue || isHighlighted}
                renderControls={renderState.isTrue || isHighlighted}
            />

            <PlaybookContent
                title={title}
                tags={tags}
                userName={username}
                duration={duration}
                uploadedAt={uploadedAt}
            />

            <Divider />

            <ExtensionOnly>
                <PlaybookFooter
                    views={playbook.views}
                    rating={playbook.rating}
                    visibility={playbook.visibility}
                />
            </ExtensionOnly>
        </Card>
    )
}
