import * as Sentry from '@sentry/browser'
import Config from '../config'
import { envLogicRouter, isLocal, isSDK } from '../services/environmentService'

const noop = () => {}
const isDevExtension = envLogicRouter(
    () => isLocal,
    () => process.env.GUIDDE_ENV !== 'prod'
)
const checkIsDev = func => (isDevExtension ? noop : func)
const sentryDsn: string = isSDK
    ? 'https://75a3636be2544413a4d644ec404f5fcd@o999875.ingest.sentry.io/6157574'
    : 'https://650ad5569e6b44168c6750dad16368a8@o999875.ingest.sentry.io/6006079'

export const initSentry = checkIsDev(() => {
    Sentry.init({
        dsn: sentryDsn,
        tracesSampleRate: 1.0,
        environment: Config.firebase.projectId,
    })

    Sentry.setTag('project-id', Config.firebase.projectId)
})

type SentryUserInfo = {
    id: string
    username: string
    email: string
}

export const setSentryUser = checkIsDev((userInfo: SentryUserInfo) => {
    Sentry.setUser(userInfo)
})

// All chrome extension callbacks should be wrapped manually with Sentry, otherwise the error won't be logged
export const establishListener = (chromeAPI, callback) => {
    const wrappedCallback = isDevExtension
        ? callback
        : (...args) => Sentry.wrap(() => callback(...args))

    chromeAPI?.addListener?.(wrappedCallback)

    return wrappedCallback
}

export const captureSentryError = checkIsDev((error, extraData = undefined) => {
    Sentry.captureException(error, extraData ?? { extra: extraData })
})
