import React, { useContext, useRef } from 'react'
import { useSelector } from 'react-redux'

import {
    Avatar,
    Badge,
    Box,
    IconButton,
    Link,
    makeStyles,
    Typography,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'

import NotificationsIcon from '@material-ui/icons/Notifications'
import GroupAddIcon from '@material-ui/icons/GroupAdd'
import BarChartIcon from '@material-ui/icons/BarChart'
import SettingsIcon from '@material-ui/icons/Settings'
import LaunchIcon from '@material-ui/icons/Launch'

import gRecordNoColor from 'assets/icons/g-record-grey.svg'
import { ReactComponent as GuiddeLogo } from 'assets/icons/guidde_red.svg'

import { ExtensionOnly, SDKOnly } from 'UI/Components'
import { VideoRecorder } from './VideoRecorder'
import { InviteDialog } from './InviteDialog'
import { NotificationsContext } from './NotificationsProvider'

import { RootState } from 'ducks/rootReducer'
import { useBoolean } from 'hooks'

import { getResourceURL } from 'services/extServices'
import { uiId } from 'services/utils'
import { isExtension } from 'services/environmentService'

const useStyles = makeStyles({
    panel: {
        minWidth: isExtension ? '56px' : '65px',
        width: isExtension ? '56px' : '65px',
        height: '100%',
        background: 'white',
        boxShadow: '-1px 0 0 0 #eeeeee',
        zIndex: 6,
    },
    iconButton: {
        padding: '4px',
    },
    avatar: {
        height: '25px',
        width: '25px',
        borderRadius: '50%',
    },
    badge: {
        boxSizing: 'content-box',
        border: '3px solid #ffffff',
        minWidth: 0,
        width: '8px !important',
        height: '8px',
        padding: '0px',
        top: '5px',
        left: '7px',
    },
    docsIcon: {
        width: '13px',
        height: '13px',
        color: '#212121',
    },
    poweredByContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    poweredByText: {
        lineHeight: 1,
        fontSize: '10px',
        color: 'gray',
    },
})

const docsURL = 'https://docs.guidde.com/'

type Props = {
    tab: number | string
    onSetTab: (tab: number) => void
}

export const SidePanel = ({ tab, onSetTab }: Props) => {
    const classes = useStyles()

    const showNotifications =
        useContext(NotificationsContext)?.notifications.filter(
            ({ status }) => status === 'new'
        ).length > 0

    const anchorRef = useRef<HTMLButtonElement | null>(null)

    const { user } = useSelector((state: RootState) => state.user)
    const {
        isTrue: isOpen,
        setTrue: openDialog,
        setFalse: onClose,
    } = useBoolean()

    return (
        <>
            <Box
                className={classes.panel}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="space-between"
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    mt={3}
                >
                    <IconButton
                        size="small"
                        onClick={() => onSetTab(0)}
                        id={uiId('side-panel-search-btn')}
                    >
                        <SearchIcon color={tab === 0 ? 'primary' : 'inherit'} />
                    </IconButton>

                    {/* NEXT COMPONENTS SHOULD RENDER IN EXT MODE ONLY */}
                    <ExtensionOnly>
                        <>
                            <Box mt={3} mb="14px">
                                <VideoRecorder
                                    customCTA={({ onClick }) => (
                                        <IconButton
                                            size="small"
                                            onClick={onClick}
                                            id={uiId('side-panel-record-btn')}
                                        >
                                            <img
                                                src={getResourceURL(
                                                    gRecordNoColor
                                                )}
                                                alt="record"
                                            />
                                        </IconButton>
                                    )}
                                />
                            </Box>
                            <Box mb="14px">
                                <IconButton
                                    size="small"
                                    onClick={() => onSetTab(2)}
                                    id={uiId('side-panel-insights-btn')}
                                >
                                    <BarChartIcon
                                        color={
                                            tab === 2 ? 'primary' : 'inherit'
                                        }
                                    />
                                </IconButton>
                            </Box>
                            <Badge
                                badgeContent={showNotifications ? '' : null}
                                color="primary"
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                classes={{
                                    badge: classes.badge,
                                }}
                            >
                                <IconButton
                                    size="small"
                                    onClick={() => onSetTab(1)}
                                    id={uiId('side-panel-notifications-btn')}
                                >
                                    <NotificationsIcon
                                        color={
                                            tab === 1 ? 'primary' : 'inherit'
                                        }
                                    />
                                </IconButton>
                            </Badge>
                        </>
                    </ExtensionOnly>
                </Box>

                <Box
                    mb={2}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                >
                    <ExtensionOnly>
                        <>
                            <Box mb={2}>
                                <IconButton
                                    size="small"
                                    title="Invite"
                                    onClick={openDialog}
                                    id={uiId('side-panel-invite-btn')}
                                >
                                    <GroupAddIcon />
                                </IconButton>
                            </Box>

                            <Box mb={2}>
                                <IconButton
                                    color={tab === 3 ? 'primary' : 'default'}
                                    size="small"
                                    title="Settings"
                                    id={uiId('side-panel-settings-btn')}
                                    onClick={() => onSetTab(3)}
                                >
                                    <SettingsIcon />
                                </IconButton>
                            </Box>

                            <IconButton
                                size="small"
                                ref={anchorRef}
                                title={`${user.displayName}\n${user.email}`}
                            >
                                <Avatar
                                    src={user?.photoURL}
                                    className={classes.avatar}
                                />
                            </IconButton>
                        </>
                    </ExtensionOnly>

                    <SDKOnly>
                        <Link href="https://www.guidde.com" target="_blank">
                            <Box className={classes.poweredByContainer}>
                                <Typography className={classes.poweredByText}>
                                    Powered by
                                </Typography>
                                <GuiddeLogo width="50px" />
                            </Box>
                        </Link>
                    </SDKOnly>

                    <ExtensionOnly>
                        <Link
                            variant="button"
                            color="secondary"
                            style={{
                                cursor: 'pointer',
                            }}
                            href={docsURL}
                            target="_blank"
                        >
                            <Box display="flex" alignItems="center" mb={0.5}>
                                <Typography
                                    variant="caption"
                                    color="textPrimary"
                                >
                                    Docs
                                </Typography>
                                <LaunchIcon className={classes.docsIcon} />
                            </Box>
                        </Link>
                    </ExtensionOnly>
                </Box>
            </Box>

            <ExtensionOnly>
                <>
                    {isOpen && <InviteDialog isOpen={true} onClose={onClose} />}
                </>
            </ExtensionOnly>
        </>
    )
}
