export type Recording = {
    uploadId: string
    url: string
}

export enum RecordingPathE {
    REQUEST = 'requestRecordings',
    FEEDBACK = 'feedbackRecordings',
}

export type WebcamSetting = 'disabled' | 'mini' | 'maxi'
