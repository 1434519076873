import { useContext, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Box, Grid, LinearProgress, makeStyles } from '@material-ui/core'

import { AppContext } from 'app/AppProvider'
import { useBoolean } from 'hooks'

import { generatePlaybook } from 'UI/Pages/playbooks/PlaybookHits'
import { ShareDialog } from 'UI/Components'
import { VideoPanel } from './VideoPanel'
import { SidePanel } from './SidePanel'

import { resetPlaybook, setPlaybook } from 'ducks/actions'
import { RootState } from 'ducks/rootReducer'

import CompanyLogo from 'assets/icons/g-icon.png'
import { isSDK } from 'services/environmentService'
import { getPlaybook } from 'background/services'

const useStyles = makeStyles(theme => ({
    videoContainer: {
        position: 'relative',
    },
    transcript: {
        overflow: 'overlay',
    },
    icon: {
        width: '100%',
        height: '100%',
    },
    iconWrapper: {
        display: 'flex',
        position: 'absolute',
        zIndex: 2,
        left: 0,
        top: 0,
        width: '17px',
        height: '17px',
    },
    wrapper: {
        display: 'flex',
        justifyContent: 'center',
        borderTop: '5px solid #cb0000',
        position: 'relative',
    },
}))

export const PagePlaybook = () => {
    const { startTime } = useContext(AppContext)

    const classes = useStyles()

    // as our search with text doesn't have transcriptUrl,
    // we have to refetch same playbook from another search api call to receive all the data
    const refetched = useBoolean()

    const playerContainer = useRef<HTMLDivElement | null>(null)

    const { playbook, sdkConfig } = useSelector((state: RootState) => state)

    const { icon, color } = sdkConfig

    const hoverState = useBoolean()
    const showSidePanel = useBoolean(true)

    const dispatch = useDispatch()

    const showShareDialog = useBoolean()
    const toggleShareDialog = () => showShareDialog.toggle()

    const playbookId = playbook?.id

    useEffect(() => {
        if (!playbookId) return

        getPlaybook(
            payload => {
                refetched.setTrue()
                if (payload) {
                    dispatch(setPlaybook(generatePlaybook(payload)))
                }
            },
            {
                playbookId,
            }
        )
    }, [dispatch, playbookId])

    useEffect(() => {
        return () => {
            dispatch(resetPlaybook())
        }
    }, [dispatch])

    if (refetched.isFalse) return <LinearProgress />

    if (!playbook || (playbook && Object.values(playbook).length === 0)) {
        return null
    }

    return (
        <Box
            className={classes.wrapper}
            style={{
                width: isSDK
                    ? 'auto'
                    : showSidePanel.isTrue
                    ? 'calc(max(1000px, 50vw))'
                    : 'calc(max(600px, 25vw))',
                borderColor: color || '#cb0000',
            }}
        >
            <Box
                className={classes.iconWrapper}
                style={{
                    backgroundColor: color || '#cb0000',
                }}
            >
                <img
                    src={icon || CompanyLogo}
                    alt="guidde_logo"
                    className={classes.icon}
                />
            </Box>

            <Grid
                container
                onMouseEnter={hoverState.setTrue}
                onMouseLeave={hoverState.setFalse}
            >
                <Grid
                    item
                    xs={8}
                    md={showSidePanel.isTrue ? 8 : 12}
                    className={classes.videoContainer}
                    ref={playerContainer}
                >
                    <VideoPanel
                        hoverState={hoverState}
                        showSidePanel={showSidePanel}
                        showShareDialog={showShareDialog}
                        playbook={playbook}
                        startTime={startTime}
                    />
                </Grid>

                {showSidePanel.isTrue && (
                    <Grid
                        item
                        xs={4}
                        md={4}
                        className={classes.transcript}
                        style={{
                            maxHeight:
                                playerContainer?.current?.offsetHeight || 480,
                        }}
                    >
                        <SidePanel startTime={startTime} />
                    </Grid>
                )}
            </Grid>
            {showShareDialog.isTrue && (
                <ShareDialog
                    isOpen
                    onClose={toggleShareDialog}
                    playbook={playbook}
                    title="Share your playbook"
                    subtitle="Invite people to view your Playbook"
                />
            )}
        </Box>
    )
}
