import React, { memo, useEffect } from 'react'
import { Box, Button, makeStyles, Typography } from '@material-ui/core'

import { TypeUseBoolean } from 'hooks'
import clsx from 'clsx'

import { SpacedGroup } from 'UI/Components/SpacedGroup'

import { setPlaybookReview } from 'background/services'

const useStyles = makeStyles(theme => ({
    negativeAnswers: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-end',
        paddingBottom: '5%',
        left: 0,
        right: 0,
        bottom: 0,
        height: '50%',
        backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 0), #000)',
    },
    negativeAnswer: {
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        maxWidth: '150px',
        padding: '6px 12px',
        height: '60px',
        backdropFilter: 'blur(10px)',
        '&:hover': {
            backgroundColor: '#fff',
            color: `${theme.palette.text.primary} !important`,
        },
    },
    title: {
        fontSize: '12px',
        fontWeight: 'bold',
        color: '#fff',
        textAlign: 'center',
        width: '100%',
        position: 'relative',
    },
    subTitle: {
        fontSize: '12px',
        color: '#fff',
        textAlign: 'center',
    },
    ratherNot: {
        fontSize: '12px',
        color: 'inherit',
        fontWeight: 'bold',
        position: 'absolute',
        paddingRight: theme.spacing(3),
        right: 0,
        top: 0,
        cursor: 'pointer',
    },
    finishStep: {
        paddingBottom: '10%',
    },
}))

const negativeAnswersList = [
    'Not what I was looking for',
    'The quality of the video was bad',
    "It's not up to date",
]

type Props = {
    showReactions: TypeUseBoolean
    playbookId: string
    showFinishStep: TypeUseBoolean
}

const notHelpfulReaction = 1

export const VideoReactionsAnswers = memo(
    ({ showReactions, playbookId, showFinishStep }: Props) => {
        const classes = useStyles()

        const handleClick = result => {
            setPlaybookReview(showFinishStep.setTrue, {
                playbookId,
                reaction: notHelpfulReaction,
                result,
            })
        }

        const finishStepIsActive = showFinishStep.isTrue

        useEffect(() => {
            let timeout
            if (finishStepIsActive) {
                timeout = setTimeout(() => {
                    showReactions.setFalse()
                    showFinishStep.setFalse()
                    clearTimeout(timeout)
                }, 1500)
            }

            return () => {
                if (timeout) clearTimeout(timeout)
            }
        }, [finishStepIsActive])

        if (showFinishStep.isTrue) {
            return (
                <Box
                    className={clsx(
                        classes.negativeAnswers,
                        classes.finishStep
                    )}
                >
                    <Typography className={classes.title}>
                        Thank you for your feedback!
                    </Typography>
                    <Typography className={classes.subTitle}>
                        We are working hard to avoid mismatches
                    </Typography>
                </Box>
            )
        }

        if (showReactions.isFalse) return null

        return (
            <Box className={classes.negativeAnswers}>
                <Box m={2} width="100%">
                    <Typography className={classes.title}>
                        Ouch! Would you mind tell us why?
                        <Typography
                            component="span"
                            className={classes.ratherNot}
                            onClick={() => handleClick(0)}
                        >
                            I rather not
                        </Typography>
                    </Typography>
                </Box>
                <SpacedGroup>
                    {negativeAnswersList.map((text, i) => (
                        <Button
                            variant="contained"
                            key={i}
                            className={classes.negativeAnswer}
                            onClick={() => handleClick(i + 1)}
                        >
                            {text}
                        </Button>
                    ))}
                </SpacedGroup>
            </Box>
        )
    }
)
