import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'ducks/rootReducer'

import {
    Typography,
    Box,
    Avatar,
    CircularProgress,
    Badge,
    makeStyles,
    Grid,
} from '@material-ui/core'
import StarsIcon from '@material-ui/icons/Star'

import { playbookToMixpanelProps } from 'services/mixpanel'

import { LineChart } from './LineChart'
import { NoData } from 'UI/Components/NoData'
import { getTopPlaybooksReports, logToMixpanel } from 'background/services'
import { AppContext } from 'app/AppProvider'
import { ReportState } from './PageInsights'

const useStyles = makeStyles(theme => ({
    badge: {
        width: '15px',
        height: '15px',
        fill: 'gold',
        stroke: 'gray',
    },
    title: {
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    appLogo: {
        objectFit: 'contain',
        marginRight: theme.spacing(1),
        '& img': {
            objectFit: 'contain',
        },
    },
}))

const PlaybookInfo = ({ title, author, app }) => {
    const classes = useStyles()
    return (
        <>
            <Typography title={title} className={classes.title}>
                {title}
            </Typography>
            <Grid container spacing={2} alignItems="center">
                <Grid item>
                    <Typography variant="caption">{author}</Typography>
                </Grid>
                <Grid item>
                    <Box display="flex" alignItems="center">
                        {app?.logo && (
                            <Avatar
                                src={app?.logo}
                                variant="square"
                                className={classes.appLogo}
                            />
                        )}
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

export const TopPlaybooks = ({ userId, setUserPlaybook }) => {
    const classes = useStyles()
    const { roles } = useSelector((state: RootState) => state.user)
    const allApps = useSelector((state: RootState) => state.apps)
    const [report, setReport] = useState({ data: [] })
    const [maxPlaybooks] = useState(5)
    const [maxValue, setMaxValue] = useState(0)
    const { setPlaybookId, openPlaybookMode, openPlaylistMode } =
        useContext(AppContext)
    const [topPlaybooks, setTopPlaybooks] = useState<null | ReportState>(null)

    const getAppInfo = pb => {
        const app = allApps?.find(
            ({ id }) =>
                id ===
                (pb?.applicationId || pb?.applications?.[0]?.applicationId)
        )

        if (pb?.applicationId) return app

        return {
            title: app?.applicationName,
            id: app?.applicationId,
            logo: null,
        }
    }

    useEffect(() => {
        getTopPlaybooksReports(
            resp => {
                const currentReport = resp?.find(
                    r => r.name === 'topPlaybooksReport-7-teams'
                )
                setTopPlaybooks(currentReport)
                setReport(currentReport)
                setMaxValue(currentReport?.data?.[0]?.value)
                setUserPlaybook(
                    currentReport?.data?.find(
                        p => p.uploadedByUid === userId
                    ) || null
                )
            },
            { orgId: roles.o }
        )
    }, [setUserPlaybook])

    const chartItems = report?.data
        ?.slice(0, maxPlaybooks)
        .filter(Boolean)
        .map((playbook: any, k) => {
            const {
                pbID,
                id,
                title,
                value,
                uploadedBy,
                screenshotUrl,
                isPlaylist,
                originalFileName,
            } = playbook

            const handlePbClick = () => {
                if (isPlaylist) {
                    openPlaylistMode()
                } else {
                    openPlaybookMode()
                }
                setPlaybookId(id || pbID)
                openPlaybookMode()
                logToMixpanel(
                    'videoPlayClicked',
                    playbookToMixpanelProps(playbook)
                )
            }
            const fileName = originalFileName?.split('.')[0]

            if (k === 0) {
                return (
                    <LineChart
                        key={k}
                        title={title || fileName || 'Unnamed playbook'}
                        value={value}
                        maxValue={maxValue}
                        Icon={({ className }) => (
                            <Badge
                                overlap="circular"
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                badgeContent={
                                    <StarsIcon className={classes.badge} />
                                }
                            >
                                <Avatar
                                    variant={'square'}
                                    className={className}
                                    src={screenshotUrl}
                                />
                            </Badge>
                        )}
                        onClick={handlePbClick}
                    >
                        <PlaybookInfo
                            title={title || fileName || 'Unnamed playbook'}
                            author={uploadedBy?.displayName || 'Anonymous'}
                            app={getAppInfo(playbook)}
                        />
                    </LineChart>
                )
            }
            return (
                <LineChart
                    key={k}
                    title={title || fileName || 'Unnamed Playbook'}
                    value={value}
                    maxValue={maxValue}
                    Icon={({ className }) => (
                        <Avatar
                            src={screenshotUrl}
                            className={className}
                            variant={'square'}
                        />
                    )}
                    onClick={handlePbClick}
                >
                    <PlaybookInfo
                        title={title || fileName || 'Unnamed Playbook'}
                        author={uploadedBy?.displayName || 'Anonymous'}
                        app={getAppInfo(playbook)}
                    />
                </LineChart>
            )
        })

    useEffect(() => {}, [])

    return (
        <Box p={2} pr={0}>
            <Typography variant="h5">Most Viewed Playbooks</Typography>
            <Box mt={3}>
                {!topPlaybooks ? (
                    <Box
                        p={2}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <CircularProgress />
                    </Box>
                ) : topPlaybooks && chartItems?.length ? (
                    chartItems
                ) : (
                    <Box p={2} pl={0}>
                        <NoData />
                    </Box>
                )}
            </Box>
        </Box>
    )
}
