import { useRef } from 'react'
import { Field } from 'formik'

import { Box, TextField, FormHelperText, makeStyles } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { option, uniqueArray } from 'services/utils'

const isBlank = value => {
    return /^\s*$/.test(value)
}

const useStyles = makeStyles({
    input: {
        minWidth: '100px !important',
    },
})

type Props = {
    name: string
    options: Array<{ value: string; label: string }>
    placeholder?: string
    fullWidth?: boolean
    width?: string
    freeSolo: boolean
}
export const ConnectedAutocomplete = ({
    name,
    options = [],
    placeholder,
    fullWidth,
    width,
    freeSolo = false,
}: Props) => {
    const classes = useStyles()

    const inputRef = useRef(null)

    return (
        <Field name={name}>
            {({ field, form, meta }) => (
                <Box>
                    <Autocomplete
                        autoComplete={false}
                        classes={{
                            input: classes.input,
                        }}
                        fullWidth={fullWidth}
                        multiple
                        filterSelectedOptions
                        options={options}
                        loading={!options}
                        getOptionLabel={option => {
                            if (typeof option === 'string') return option
                            return option?.label ?? ''
                        }}
                        getOptionSelected={(option, value) =>
                            option.value === value.value
                        }
                        value={field?.value || []}
                        onChange={(_e, value) => {
                            form.setFieldValue(
                                field.name,
                                uniqueArray(
                                    value.map(v =>
                                        typeof v === 'string' ? option(v, v) : v
                                    )
                                )
                            )
                        }}
                        freeSolo={freeSolo}
                        onBlur={() => {
                            // @ts-ignore
                            const tempValue = inputRef.current?.value

                            if (tempValue && !isBlank(tempValue)) {
                                form.setFieldValue(
                                    field.name,
                                    Array.from(
                                        new Set([
                                            ...(field.value || []),
                                            option(tempValue, tempValue),
                                        ])
                                    )
                                )
                            }
                        }}
                        clearOnBlur={false}
                        size="small"
                        disableCloseOnSelect
                        style={fullWidth ? {} : { width: width ?? '300px' }}
                        renderInput={params => (
                            <TextField
                                {...params}
                                inputRef={inputRef}
                                variant="outlined"
                                placeholder={placeholder}
                                margin="none"
                                InputProps={{
                                    ...params.InputProps,
                                    margin: 'none',
                                    fullWidth: true,
                                    autoComplete: 'off',
                                }}
                            />
                        )}
                    />

                    {meta.touched && meta.error !== undefined && (
                        <FormHelperText>{meta.error}</FormHelperText>
                    )}
                </Box>
            )}
        </Field>
    )
}
