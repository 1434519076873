import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
    Box,
    CircularProgress,
    IconButton,
    makeStyles,
} from '@material-ui/core'
import StopIcon from '@material-ui/icons/Stop'

import {
    getRecordingStatus,
    logToMixpanel,
    onRecordFromContext,
    onRecordFromKeyboard,
    onRecordFromWebapp,
    startScreenCapture,
    stopScreenCapture,
} from 'background/services'
import { useCursorHighlighter } from './use-cursor-highlightning'

import { getSelectionText } from 'services/utils'
import * as EXT_SERVICES from 'services/extServices'
import { trimResultTag } from 'services/tagsParser'

import { RootState } from 'ducks/rootReducer'
import { setRecordingStatus } from 'ducks/actions'

import {
    useNotification,
    useBoolean,
    useMicrophone,
    useTagsParser,
} from 'hooks'

import gRecord from 'assets/icons/g-record.svg'

import { RecordingSettingsDialog } from 'UI/Components/RecordingSettingsDialog'

const useStyles = makeStyles({
    iconButton: {
        marginBottom: '10px !important',
        backgroundColor: 'white',
        '&:hover': {
            backgroundColor: 'white',
            boxShadow: '0 6px 14px 0 rgba(0, 0, 0, 0.4) !important',
        },
    },
    stopButton: {
        backgroundColor: '#cb0000',
        color: 'white',
        '&:hover': {
            backgroundColor: '#cb0000',
            boxShadow: '0 6px 14px 0 rgba(0, 0, 0, 0.4)',
        },
    },
})

type Props = {
    size?: number
    recordingType?: string // Specify if upload is not to playbooks folder
    customCTA?: ({ onClick }) => ReactNode
    onInfoReceived?: (additionalInfo: any) => void
    onStart?: () => void
    onRecordingCancelled?: () => void
}

type MixpanelRecordingSource =
    | ''
    | 'start_recording'
    | 'start_recording_from_webapp'
    | 'start_recording_from_context_menu'
    | 'start_recording_from_keyboard_shortcut'

export const VideoRecorder = ({
    size,
    recordingType,
    customCTA,
    onStart = () => {},
    onInfoReceived = () => {},
    onRecordingCancelled,
}: Props) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const recordingSettingsModal = useBoolean(false)
    const [textSelection, setTextSelection] = useState<string>('')
    const [mixpanelSource, setMixpanelSource] =
        useState<MixpanelRecordingSource>('')
    const micSettings = useMicrophone()
    const defaultFilters = useTagsParser()

    const mixpanelParams = {
        ...(defaultFilters.app && { appId: defaultFilters.app }),
        ...(defaultFilters.appName && { appName: defaultFilters.appName }),
        ...(defaultFilters.domain && { domain: defaultFilters.domain }),
        ...(defaultFilters.tags.length > 0 && { tags: defaultFilters.tags }),
    }

    const { status } = useSelector((state: RootState) => state.screenCapture)

    const { showErrorNotification } = useNotification()

    const isRecording = status === 'recording'
    const isProcessing = status === 'processing'
    const isStopped = status === 'stopped' || status === 'cancelled'
    useCursorHighlighter(isRecording)

    const startCapture = useCallback(
        (micId: string) => {
            recordingSettingsModal.setFalse()
            const params = {
                defaultFilters: {
                    ...defaultFilters,
                    tags: (defaultFilters.tags || []).concat(
                        textSelection ? [textSelection] : []
                    ),
                },
                link: window.location.href,
                recordingType,
                micId,
            }

            startScreenCapture(null, params)
            onStart()
            logToMixpanel(mixpanelSource || 'start_recording', params)
            setTextSelection('')
            setMixpanelSource('')
        },
        [
            recordingSettingsModal,
            mixpanelSource,
            defaultFilters,
            recordingType,
            textSelection,
        ]
    )

    const showSettingsOrRecord = useCallback(
        async (mixpanelEvent: MixpanelRecordingSource) => {
            setMixpanelSource(mixpanelEvent)

            // Before recording, we revalidate chosen mic, cause it could've changed in settings or elsewhere
            const microphone: string = await micSettings.fetchMics()
            if (microphone) {
                startCapture(microphone)
            } else {
                recordingSettingsModal.setTrue()
            }
        },
        [micSettings.fetchMics, recordingSettingsModal, startCapture]
    )

    const hideRecordingSettings = useCallback(() => {
        setTextSelection('')
        setMixpanelSource('')
        recordingSettingsModal.setFalse()
    }, [recordingSettingsModal])

    const stopCapture = () => stopScreenCapture(null, {})

    useEffect(() => {
        getRecordingStatus(payload => {
            if (payload.status === 'cancelled') {
                onRecordingCancelled?.()
            }
            if (payload.status === 'error') {
                showErrorNotification(
                    'Please check your microphone permission, reload the page and try again.'
                )
                return dispatch(setRecordingStatus('stopped'))
            }
            if (payload.status === 'upload_error') {
                showErrorNotification(
                    'Something went wrong while uploading your video, please try again'
                )
                return dispatch(setRecordingStatus('stopped'))
            }

            dispatch(setRecordingStatus(payload.status))

            if (payload.additionalInfo) {
                onInfoReceived(payload.additionalInfo)
            }
        }, {})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])

    useEffect(() => {
        onRecordFromWebapp(() => {
            if (!isRecording) {
                showSettingsOrRecord('start_recording_from_webapp')
            }
        })

        onRecordFromContext(({ selection }) => {
            if (isRecording) {
                stopCapture()
            } else {
                if (selection) {
                    const newTag = trimResultTag(selection)
                    setTextSelection(newTag)
                }

                showSettingsOrRecord('start_recording_from_context_menu')
            }
        })

        onRecordFromKeyboard(() => {
            if (isRecording) {
                stopCapture()
            } else {
                const selectedText = getSelectionText()

                if (selectedText) {
                    const newTag = selectedText
                        ? trimResultTag(selectedText)
                        : null
                    setTextSelection(newTag)
                }

                showSettingsOrRecord('start_recording_from_keyboard_shortcut')
            }
        })
    }, [isRecording, showSettingsOrRecord])

    const onCTAClick = () => showSettingsOrRecord('start_recording')

    return (
        <>
            <Box
                display="flex"
                alignItems="center"
                flexDirection="column"
                justifyContent="center"
                {...(size
                    ? {
                          style: {
                              minWidth: `${size}px`,
                              minHeight: `${size}px`,
                          },
                      }
                    : {})}
            >
                {isStopped &&
                    (customCTA ? (
                        customCTA({
                            onClick: onCTAClick,
                        })
                    ) : (
                        <IconButton
                            onClick={onCTAClick}
                            size="small"
                            className={classes.iconButton}
                        >
                            <img
                                src={EXT_SERVICES.getResourceURL(gRecord)}
                                alt="g-record"
                            />
                        </IconButton>
                    ))}

                {isRecording && (
                    <IconButton
                        onClick={() => {
                            stopCapture()
                            logToMixpanel('stop_recording', mixpanelParams)
                        }}
                        size="small"
                        className={classes.stopButton}
                        {...(size
                            ? {
                                  style: {
                                      width: `${size}px`,
                                      height: `${size}px`,
                                  },
                              }
                            : {})}
                    >
                        <StopIcon />
                    </IconButton>
                )}

                {isProcessing && (
                    <CircularProgress size={size ? size - 10 : 26} />
                )}
            </Box>

            <RecordingSettingsDialog
                isOpen={recordingSettingsModal.isTrue}
                onClose={hideRecordingSettings}
                startCapture={startCapture}
                micSettings={micSettings}
            />
        </>
    )
}
