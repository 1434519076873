/* IMPORTANT!!! This component is a candidate for deprecation */

import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Draggable from 'react-draggable'

import { Typography } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import StopIcon from '@material-ui/icons/Stop'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import { makeStyles } from '@material-ui/core/styles'

import { mainZIndex } from 'app/zIndex'
import {
    getRecordingStartTime,
    logToMixpanel,
    stopScreenCapture,
} from 'background/services'
import { useBoolean } from 'hooks'
import { RootState } from 'ducks/rootReducer'
import { getResourceURL } from 'services/extServices'
import { DiscardRecordingDialog } from './DiscardRecordingDialog'

import gLogo from 'assets/icons/g-icon.svg'

const MAX_VIDEO_LENGTH = 30 * 60 // seconds

const useStyles = makeStyles(theme => ({
    wrapper: {
        position: 'fixed',
        bottom: '50px',
        right: '50px',
        display: 'flex',
        alignItems: 'center',
        width: 'fit-content',
        padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
        backgroundColor: 'white',
        border: `2px solid ${theme.palette.primary.main}`,
        borderRadius: '10px',
        boxShadow: '2px 2px 10px 0 rgb(0 0 0 / 30%)',
        zIndex: mainZIndex,
        '& > *': {
            marginRight: theme.spacing(1),
        },
    },
    logo: {
        height: '30px',
    },
    timer: {
        minWidth: '50px',
        textAlign: 'center',
    },
    stop: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
        },
    },
    drag: {
        marginRight: '0',
        cursor: 'move',
    },
}))

export const DraggableRecControls = () => {
    const classes = useStyles()
    const recDiscardModal = useBoolean(false)
    const [countDown, setCountDown] = useState<number>(0)

    const recStatus = useSelector(
        (state: RootState) => state.screenCapture.status
    )

    const stopCapture = useCallback(() => {
        stopScreenCapture(null, {})
        logToMixpanel('stop_recording')
    }, [])

    useEffect(() => {
        // Useeffect used instead of onStart to show correct countdown in all tabs
        if (recStatus === 'recording') {
            getRecordingStartTime(startTime => {
                const recTimeInProgress: number = Math.round(
                    (Date.now() - startTime) / 1000
                )
                const countDownTime = MAX_VIDEO_LENGTH - recTimeInProgress

                if (startTime && countDownTime > 0) {
                    setCountDown(countDownTime)

                    const intervalId = window.setInterval(() => {
                        setCountDown(prevCountDown => {
                            if (prevCountDown === 0) {
                                stopCapture()
                                window.clearInterval(intervalId)
                                return prevCountDown
                            }

                            return prevCountDown - 1
                        })
                    }, 1000)
                }
            })
        } else {
            setCountDown(0)
        }
    }, [recStatus, stopCapture])

    return recStatus === 'recording' ? (
        <Draggable
            handle="#GUIDDE-JS-DragControl"
            /* If dragging panel over iframe, events won't bubble and dragging won't work.
            So while dragging, we temporary disable pointer-events for all the iframes on a page. */
            onStart={() => {
                const iframes = document.getElementsByTagName('iframe')
                Array.from(iframes).forEach(iframe => {
                    iframe.classList.add('GUIDDE-DisablePointers')
                })
            }}
            onStop={() => {
                const iframes = document.getElementsByTagName('iframe')
                Array.from(iframes).forEach(iframe => {
                    iframe.classList.remove('GUIDDE-DisablePointers')
                })
            }}
        >
            <div className={classes.wrapper}>
                <img
                    src={getResourceURL(gLogo)}
                    alt="Guidde"
                    className={classes.logo}
                />

                <IconButton
                    size="small"
                    title="Discard recording"
                    onClick={() => {
                        recDiscardModal.setTrue()
                    }}
                >
                    <DeleteForeverIcon />
                </IconButton>

                <DiscardRecordingDialog
                    isOpen={recDiscardModal.isTrue}
                    onClose={recDiscardModal.setFalse}
                />

                {countDown > 0 && (
                    <Typography
                        title="Recording time left"
                        className={classes.timer}
                    >
                        {Math.floor(countDown / 60)}:
                        {countDown % 60 < 10
                            ? `0${countDown % 60}`
                            : countDown % 60}
                    </Typography>
                )}

                <IconButton
                    title="Finish recording"
                    onClick={stopCapture}
                    size="small"
                    className={classes.stop}
                >
                    <StopIcon />
                </IconButton>

                <IconButton
                    size="small"
                    id="GUIDDE-JS-DragControl"
                    title="Drag the panel"
                    className={classes.drag}
                >
                    <DragIndicatorIcon />
                </IconButton>
            </div>
        </Draggable>
    ) : null
}
