import { useEffect } from 'react'

const CURSOR_ID = 'guidde-cursor'

const cursorPointer = document.createElement('div')
cursorPointer.id = CURSOR_ID

const styles = {
    position: 'absolute',
    backgroundColor: '#FFC300',
    width: '50px',
    height: '50px',
    opacity: '0.25',
    borderRadius: '25px',
    zIndex: '1000000000000',
    pointerEvents: 'none',
}

Object.assign(cursorPointer.style, styles)

const mhHandleMouseMove = (e: MouseEvent) => {
    const newX = e.pageX - 25
    const newY = e.pageY - 25

    cursorPointer.style.left = newX + 'px'
    cursorPointer.style.top = newY + 'px'
}

const startAnimation = () => {
    cursorPointer.animate(
        [{ transform: 'scale(1.2)' }, { transform: 'scale(1)' }],
        {
            duration: 1000,
            easing: 'ease',
        }
    )
}
const addCursorHighlights = () => {
    document.getElementsByTagName('body')[0].appendChild(cursorPointer)

    document.addEventListener('mousemove', mhHandleMouseMove)
    document.addEventListener('mousedown', startAnimation)
}

const removerCursorHighlights = () => {
    if (!document.getElementById(CURSOR_ID)) return

    cursorPointer.remove()
    document.removeEventListener('mousemove', mhHandleMouseMove)
    document.removeEventListener('mousedown', startAnimation)
}

export const useCursorHighlighter = (isRecording: boolean) => {
    useEffect(() => {
        if (!isRecording) {
            removerCursorHighlights()
            return
        }
        addCursorHighlights()
        return () => {
            removerCursorHighlights()
        }
    }, [isRecording])
}
