import { useContext, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { Box, makeStyles } from '@material-ui/core'

import { SidePanel } from 'UI/Components'
import { PagePlaybook, PagePlaybooks, PagePlaylist } from 'UI/Pages'

import { getApps, logToMixpanel } from 'background/services'
import { AppContext } from 'app/AppProvider'
import { setApps } from './ducks/actions'
import { useTagsParser } from './hooks'

const useStyles = makeStyles({
    extContainer: {
        height: '100vh',
        width: '100%',
        backdropFilter: 'blur(5px)',
    },
    playContainer: {
        width: '100%',
        height: '100%',
        position: 'fixed',
        bottom: 0,
        right: 0,
        background: 'white',
        zIndex: 10,
    },
})

export const AppSdk = () => {
    const classes = useStyles()
    const defaultFilters = useTagsParser()

    const { playbookMode, playlistMode } = useContext(AppContext)

    const dispatch = useDispatch()

    useEffect(() => {
        getApps(data => {
            dispatch(setApps(data))
            logToMixpanel('page_loaded')
        }, {})
    }, [dispatch])

    return (
        <>
            <Box className={classes.extContainer}>
                <Box display="flex" flexDirection="row" height="100%">
                    <PagePlaybooks
                        defaultFilters={defaultFilters}
                        predefinedSearch={null}
                    />

                    {/*We should show playbooks page only*/}
                    <SidePanel tab={0} onSetTab={() => {}} />
                </Box>

                {/* Play playbook page */}
                {playbookMode.isTrue && (
                    <Box className={classes.playContainer}>
                        <PagePlaybook />
                    </Box>
                )}

                {/* Play playlist page */}
                {playlistMode.isTrue && (
                    <Box className={classes.playContainer}>
                        <PagePlaylist />
                    </Box>
                )}
            </Box>
        </>
    )
}
