import { ReactNode, CSSProperties } from 'react'
import { makeStyles } from '@material-ui/core'

type StyleProps = {
    spacing: number
}
const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        '& > *:not(:last-child)': {
            marginRight: ({ spacing }: StyleProps) => theme.spacing(spacing),
        },
    },
}))

type Props = {
    children: ReactNode
    className?: string
    style?: CSSProperties
    spacing?: number
}

export const SpacedGroup = ({
    children,
    className,
    style,
    spacing = 2,
}: Props) => {
    const classes = useStyles({ spacing })

    return (
        <div className={`${classes.root} ${className}`} style={style}>
            {children}
        </div>
    )
}
