import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'

import { Badge, Box, IconButton, makeStyles } from '@material-ui/core'
import Fade from '@material-ui/core/Fade'

import * as AssetsService from 'services/extServices'
import {
    disablePointersOnIframes,
    restorePointersOnIframes,
} from 'services/styleFixer'

import { redirectToOnboarding } from 'background/services'

import { useBoolean, useTagsParser } from 'hooks'
import { AppContext } from 'app/AppProvider'
import { openRequestPbModal } from 'ducks/actions'
import { RootState } from 'ducks/rootReducer'

import { NotificationsContext, VideoRecorder } from 'UI/Components'

import ctaRecordIcon from 'assets/icons/cta-record.svg'
import ctaRequestPbIcon from 'assets/icons/cta-request-playbook.svg'
import ctaSearchIcon from 'assets/icons/cta-search.svg'
import gLogo from 'assets/icons/g-label.svg'
import { envLogicRouter } from '../../services/environmentService'

const LOGO_SIZE = 40
const useStyles = makeStyles({
    gContainer: {
        position: 'absolute',
        left: '-39px',
        cursor: 'pointer',
    },
    gLogo: {
        width: `${LOGO_SIZE}px`,
        height: `${LOGO_SIZE}px`,
    },
    badge: {
        border: '2px solid #ffffff',
    },
    gRecord: {
        position: 'relative',
        top: '100%',
        right: `calc(100% - ${LOGO_SIZE}px)`,
        marginRight: '12px',
        borderRadius: '4px',
        cursor: 'pointer',
        '& > *': {
            marginBottom: '10px !important',
        },
    },
    iconButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '8px',
        minWidth: '40px',
        height: '40px',
        backgroundColor: 'white',
        color: '#090C10',
        fontSize: '16px',
        boxShadow: '0 2px 14px 0 rgba(0, 0, 0, 0.4)',
        '&:hover': {
            backgroundColor: 'white',
        },
    },
    noDisplay: {
        display: 'none',
    },
})

const availableHeight = window.innerHeight - 20 // half of the height of G button

type Props = {
    onClick: () => void
    onSearchBtnClick?: () => void
    roles: Roles | null // @TODO CHECK WHETHER WE CAN USE IT AS NULL
}

type Roles = {
    o: string // user's org
    n: boolean // onboarding
    g: boolean // guest
}

type BtnProps = {
    title: string
    children: React.ReactNode
    onClick?: () => void
}

const CTAButton = ({ title, children, onClick }: BtnProps) => {
    const classes = useStyles()
    return (
        <IconButton
            disableRipple={!onClick}
            title={title}
            size="small"
            className={classes.iconButton}
            {...(onClick ? { onClick } : {})}
        >
            {children}
        </IconButton>
    )
}

export const DraggableGButton = ({
    onClick,
    onSearchBtnClick,
    roles,
}: Props) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const currentAppTag = useTagsParser()

    const notificationsCount = useContext(
        NotificationsContext
    )?.notifications.filter(({ status }) => status === 'new').length
    const { active: openPanel } = useContext(AppContext)

    const recStatus = useSelector(
        (state: RootState) => state.screenCapture.status
    )

    const draggable = useBoolean()
    const dragged = useBoolean(false)
    const hoverState = useBoolean()

    const [yPosition, setPosition] = useState<number>(
        localStorage.yPosition || 140 // default position
    )

    const onMouseMove = (event: MouseEvent) => {
        // we need manipulation with 20 px (half of the g button's height) in order to place g button's center on the mouse cursor

        const y =
            event.clientY - 20 > 0
                ? event.clientY < availableHeight - 16
                    ? event.clientY
                    : availableHeight - 16
                : 20

        setPosition(y)
    }

    // @TODO check on click handler with roles (after login)
    const handleClick = () => {
        if (!roles) return

        if (roles.o && !roles.n) onClick?.()
        else {
            redirectToOnboarding()
        }
    }

    const isDraggable = draggable.isTrue
    const resetDraggable = draggable.setFalse

    useEffect(() => {
        if (isDraggable) {
            document.onmousemove = onMouseMove
            document.onmouseup = () => {
                restorePointersOnIframes()
                resetDraggable()
                localStorage.yPosition = yPosition
            }
        }
        return () => {
            document.onmousemove = null
            document.onmouseup = null
        }
    }, [isDraggable, resetDraggable, yPosition])

    const showButtons = hoverState.isTrue || recStatus === 'processing'

    return (
        <Box
            position="absolute"
            display="flex"
            flexDirection="column"
            style={{
                marginTop: '20px',
                top: (yPosition || 20) - 20 || 0 + 'px',
            }}
            className={classes.gContainer}
            onMouseEnter={hoverState.setTrue}
            onMouseLeave={hoverState.setFalse}
        >
            <Badge
                badgeContent={
                    notificationsCount > 0 ? notificationsCount : null
                }
                color="primary"
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                classes={{
                    badge: classes.badge,
                }}
            >
                <img
                    src={AssetsService.getResourceURL(gLogo)}
                    className={classes.gLogo}
                    alt="g-logo"
                    onMouseDown={event => {
                        event.preventDefault()
                        draggable.setTrue()
                        disablePointersOnIframes()
                    }}
                    onMouseMove={() => {
                        if (draggable.isTrue) {
                            dragged.setTrue()
                        }
                    }}
                    onMouseUp={event => {
                        if (dragged.isTrue) {
                            event.preventDefault()
                            draggable.setFalse()
                            dragged.setFalse()
                        } else {
                            envLogicRouter(
                                () => onClick(),
                                () => handleClick()
                            )
                        }
                    }}
                />
            </Badge>

            {/*@TODO Check this components*/}
            {/* Bottom buttons shouldn't overlap host page in hidden state, so display none is applied */}
            {roles?.o && !roles?.n && (
                <Fade in={showButtons} timeout={800}>
                    <Box
                        mt={2}
                        className={clsx(
                            classes.gRecord,
                            !showButtons && classes.noDisplay
                        )}
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-end"
                        justifyContent="center"
                    >
                        <CTAButton
                            title="Search playbooks"
                            onClick={() => {
                                if (openPanel.isFalse) {
                                    openPanel.setTrue()
                                }

                                onSearchBtnClick?.()
                            }}
                        >
                            <img
                                alt="search"
                                src={AssetsService.getResourceURL(
                                    ctaSearchIcon
                                )}
                            />
                        </CTAButton>

                        <VideoRecorder
                            size={40}
                            customCTA={({ onClick }) => (
                                <CTAButton
                                    title="Record playbook"
                                    onClick={onClick}
                                >
                                    <img
                                        src={AssetsService.getResourceURL(
                                            ctaRecordIcon
                                        )}
                                        alt="g-record"
                                    />
                                </CTAButton>
                            )}
                        />

                        <CTAButton
                            title="Ask a Guidde"
                            onClick={() => {
                                dispatch(
                                    openRequestPbModal({
                                        applicationId: currentAppTag.app,
                                        tags: currentAppTag.tags,
                                    })
                                )
                            }}
                        >
                            <img
                                alt="request-playbook"
                                src={AssetsService.getResourceURL(
                                    ctaRequestPbIcon
                                )}
                            />
                        </CTAButton>
                    </Box>
                </Fade>
            )}
        </Box>
    )
}
