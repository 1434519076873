import React, { useEffect } from 'react'

import MicIcon from '@material-ui/icons/Mic'
import MicOffIcon from '@material-ui/icons/MicOff'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Box from '@material-ui/core/Box'

type Props = {
    chosenMic: string
    micOptions: { deviceId: string; label: string }[]
    onMicChange: (newMic: string) => void
}

export const MicSetting = ({ chosenMic, micOptions, onMicChange }: Props) => {
    useEffect(() => {
        // On mount pre-select any first device, if none is selected
        if (!chosenMic && micOptions.length > 1) {
            onMicChange(micOptions[0].deviceId || '')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [micOptions.length])

    return (
        <Box display="flex" alignItems="center">
            <Box mr={2}>
                {chosenMic === 'mute' ? <MicOffIcon /> : <MicIcon />}
            </Box>

            <TextField
                placeholder="No microphone found"
                select={Boolean(micOptions.length)}
                disabled={!micOptions.length}
                color="secondary"
                value={chosenMic}
                onChange={e => onMicChange(e.target.value)}
                label="Choose default microphone"
                InputLabelProps={{ shrink: true }}
                style={{ width: '100%' }}
            >
                {micOptions.length !== 0 &&
                    micOptions.map((mic, index) => (
                        <MenuItem key={mic.deviceId} value={mic.deviceId}>
                            {mic.label || `Microphone ${index + 1}`}
                        </MenuItem>
                    ))}
            </TextField>
        </Box>
    )
}
