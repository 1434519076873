import React from 'react'

import * as AssetsService from 'services/extServices'

import { Box, makeStyles, Typography } from '@material-ui/core'
import PublicIcon from '@material-ui/icons/Public'

import IconLockRed from 'assets/icons/lock-red.svg'
import IconTeam from 'assets/icons/icon-team.svg'
import IconCommunity from 'assets/icons/icon-community.svg'

const useStyles = makeStyles({
    label: {
        fontSize: '13px',
        marginLeft: '4px',
        color: 'inherit',
        flexWrap: 'nowrap',
    },
})

const getIconColor = visibility => {
    switch (visibility) {
        default:
        case 'Private':
            return '#cd0000'
        case 'Team':
            return '#00a2eb'
        case 'Connected':
            return '#09b15b'
        case 'Public':
            return '#b953d4'
    }
}

const getPlaybookTypeIcon = visibility => {
    switch (visibility) {
        case 'Public':
            return (
                <PublicIcon
                    style={{ color: getIconColor(visibility) }}
                    fontSize="small"
                />
            )
        case 'Team':
            return (
                <img src={AssetsService.getResourceURL(IconTeam)} alt="team" />
            )
        case 'Connected':
            return (
                <img
                    src={AssetsService.getResourceURL(IconCommunity)}
                    alt="connected"
                />
            )
        case 'Private':
            return (
                <img
                    src={AssetsService.getResourceURL(IconLockRed)}
                    alt="private"
                />
            )
        default:
            return
    }
}

export type VisibilityType = 'Private' | 'Team' | 'Connected' | 'Public'

export type Props = {
    visibility: VisibilityType
}

export const PlaybookVisibility = ({ visibility = 'Private' }: Props) => {
    const classes = useStyles()

    return (
        <Box display="flex" alignItems="center">
            <Box
                style={{ color: getIconColor(visibility) }}
                display="flex"
                alignItems="center"
            >
                {getPlaybookTypeIcon(visibility)}
                <Typography className={classes.label}>{visibility}</Typography>
            </Box>
        </Box>
    )
}
