/* This is an integration with Mixpanel (event-tracker) JS SDK
 *  https://developer.mixpanel.com/docs/javascript-quickstart
 */
import mixpanel from 'mixpanel-browser'

import { DEV_EXT_ID } from './constants'
import { setSentryUser } from './sentry'
import { getExtensionVersion } from '../services/extServices'
import {
    extensionOnly,
    envLogicRouter,
    isLocal,
} from '../services/environmentService'
import Config from '../config'

const pjson = require('../../package.json')

const isDevExtension = extensionOnly(() => chrome.runtime.id === DEV_EXT_ID)
const noop = () => {}
const checkIsDev = func =>
    envLogicRouter(
        () => isLocal,
        () => isDevExtension
    )
        ? noop
        : func

const getMixpanelToken = () =>
    Config.firebase.projectId === 'guidde-production'
        ? 'ce15fdc89e126278c35370cb0001d462'
        : 'd287c9d57dcabe3765cd10c3da69b39e'

const initMixpanel = checkIsDev(() => {
    mixpanel.init(getMixpanelToken())
})

// Super properties are properties that are added to each event.
// Calling this function will erase previous super properties.
const registerMixpanelSuperProps = checkIsDev(
    (
        user: { roles: any; user: any; customProps: any } | null = null,
        baseProps?: any
    ) => {
        const extensionInfo = {
            ...envLogicRouter(
                () => ({
                    ...(baseProps || {}),
                    source: 'sdk',
                    'sdk-version': pjson.version,
                    environment: Config.firebase.projectId,
                }),
                () => ({
                    source: 'chrome-extension',
                    'ext-id': chrome.runtime.id,
                    'ext-version': getExtensionVersion(),
                    environment: Config.firebase.projectId,
                })
            ),
        }

        const userInfo = user
            ? {
                  // Custom props should be before the main user props, because main props should prevail
                  ...(user.customProps || {}),
                  'user-id': user.user.uid,
                  email: user.user.email,
                  name: user.user.displayName,
                  'organization-id': envLogicRouter(
                      () => baseProps['sdk-org'],
                      () => user.roles?.o
                  ),
              }
            : {}

        mixpanel.register({ ...extensionInfo, ...userInfo })

        // Identify function allows us to use guidde user id as mixpanel user id
        if (user) {
            mixpanel.identify(user.user.uid)
            mixpanel.people.set(userInfo)

            // Also sets Sentry user
            setSentryUser({
                ...extensionInfo,
                ...userInfo,
                id: user.user.uid,
                username: user.user.displayName,
                email: user.user.email,
            })
        }
    }
)

// "Tracked" events are exported to analytics
export type MixpanelEvent =
    | 'extension_installed'
    | 'extension_updated'
    | 'login'
    | 'logout'
    | 'page_loaded'
    | 'search' // Tracked
    | 'search_fail'
    | 'search-no-results' // Tracked
    | 'videoPlayClicked'
    | 'videoClose'
    | 'hide_transcript'
    | 'show_transcript'
    | 'guidde_button_clicked'
    | 'request_playbook_btn_clicked'
    | 'request_playbook_call_made' // Tracked
    | 'user_feedback_clicked'
    | 'user_feedback_sent'
    | 'share_pb_btn_clicked'
    | 'share_playbook' // Tracked
    | 'start_recording'
    | 'stop_recording'
    | 'upload_after_recording' // Tracked
    | 'videoPlay' // Tracked
    | 'failed_to_upload_after_recording'
    | 'video_reaction_sent'
    | 'background_search'
    | 'search_from_keyboard_shortcut'
    | 'search_from_context_menu'
    | 'share_playlist_btn_clicked'
    | 'copyLink'
    | 'start_recording_from_context_menu'
    | 'start_recording_from_webapp'
    | 'start_recording_from_keyboard_shortcut'
    | 'playbook_info_item_clicked'
    | 'track_link'
    | 'add_tracking_link'
    | 'delete_tracking_link'
// open_playlist - Why not tracking???

const logEvent = checkIsDev(
    (event: MixpanelEvent, eventData = {}, sender: any = null) => {
        const tabInfo = sender ? { url: sender.url } : {}
        const eventProps = { ...eventData, ...tabInfo }
        mixpanel.track(event, eventProps)
    }
)

export { initMixpanel, registerMixpanelSuperProps, logEvent }
