import React, { useEffect, useState } from 'react'
import {
    Typography,
    Box,
    Avatar,
    CircularProgress,
    Badge,
    makeStyles,
} from '@material-ui/core'
import { LineChart } from './LineChart'

import { NoData } from 'UI/Components/NoData'
import { useSelector } from 'react-redux'
import { RootState } from 'ducks/rootReducer'
import { getTopCreatorsReports } from 'background/services'
import StarsIcon from '@material-ui/icons/Star'
import { ReportState } from './PageInsights'

const useStyles = makeStyles(theme => ({
    badge: {
        width: '15px',
        height: '15px',
        fill: 'gold',
        stroke: 'gray',
    },
    greatestAvatar: {
        position: 'relative',
    },
}))

const initialReport = { data: [] }

export const TopCreators = ({ userId, setRank }) => {
    const classes = useStyles()
    const { roles } = useSelector((state: RootState) => state.user)
    const [report, setReport] = useState(initialReport)
    const [maxPlaybooks] = useState(5)
    const [maxValue, setMaxValue] = useState(0)
    const [topCreators, setTopCreators] = useState<null | ReportState>(null)

    const removeDeletedUsers = report => ({
        data: report?.filter(c => c.uid).sort((a, b) => b?.value - a?.value),
    })

    useEffect(() => {
        getTopCreatorsReports(
            resp => {
                const currentReport = resp?.find(
                    r => r.name === 'topCreatorsReport-7-teams'
                )
                setTopCreators(currentReport)
                setReport(removeDeletedUsers(currentReport?.data))
                setMaxValue(currentReport?.data?.[0]?.value || 0)
            },
            { orgId: roles.o }
        )
    }, [roles.o])

    useEffect(() => {
        if (report !== initialReport) {
            const rank = report.data?.findIndex(
                (u: { uid: string }) => u?.uid === userId
            )
            setRank(rank >= 0 ? rank + 1 : null)
        }
    }, [report])

    const chartItems = report?.data
        ?.slice(0, maxPlaybooks)
        .map(({ displayName, value, photoURL }, k) => {
            if (k === 0) {
                return (
                    <LineChart
                        key={k}
                        title={displayName || 'Unnamed User'}
                        value={value}
                        maxValue={maxValue}
                        Icon={({ className }) => (
                            <Badge
                                className={classes.greatestAvatar}
                                overlap="circular"
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                badgeContent={
                                    <StarsIcon className={classes.badge} />
                                }
                            >
                                <Avatar className={className} src={photoURL} />
                            </Badge>
                        )}
                    />
                )
            }
            return (
                <LineChart
                    key={k}
                    Icon={({ className }) => (
                        <Avatar src={photoURL} className={className} />
                    )}
                    title={displayName || 'Unnamed User'}
                    value={value}
                    maxValue={maxValue}
                />
            )
        })

    return (
        <Box p={2} pl={0}>
            <Typography variant="h5">Top 5 Creators</Typography>
            <Box mt={3}>
                {!topCreators ? (
                    <Box
                        p={2}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <CircularProgress />
                    </Box>
                ) : topCreators && chartItems?.length ? (
                    chartItems
                ) : (
                    <Box p={2} pl={0}>
                        <NoData />
                    </Box>
                )}
            </Box>
        </Box>
    )
}
