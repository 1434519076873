import moment from 'moment'
import { useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useBoolean, useNotification } from 'hooks'

import { Paper, IconButton, makeStyles } from '@material-ui/core'
import { DataGrid } from '@material-ui/data-grid'
import { Delete as DeleteIcon, Link as LinkIcon } from '@material-ui/icons'
import { ConfirmationDialog, CopyLinkedGif } from 'UI/Components'

import { PlaybookType } from 'types'
import { RootState } from 'ducks/rootReducer'

import { TrackingLinkType } from 'background/trackingLinks'
import { deleteTrackingLink, logToMixpanel } from 'background/services'
import { playbookToMixpanelProps } from 'services/mixpanel'
import { copyToClipboard } from 'services/utils'
import Config from 'config'

const useStyles = makeStyles(theme => ({
    grid: {
        '& .MuiDataGrid-cell, & .MuiDataGrid-columnHeader': {
            outline: 'none!important',
        },
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
}))

type TableProps = {
    onLinkDelete: () => void
    playbook: PlaybookType
    trackingLinks: Array<TrackingLinkType>
    onCopyClick?: () => void
}

export const TrackLinksTable = ({
    playbook,
    trackingLinks,
    onLinkDelete,
    onCopyClick,
}: TableProps) => {
    const classes = useStyles()

    const {
        user: { uid },
    } = useSelector((state: RootState) => state.user)

    const {
        isPlaylist,
        isPublic,
        id: playbookId,
        gifUrl,
        screenshotUrl,
        visibility,
        title,
    } = playbook

    const loading = useBoolean()

    // working with dataGrid default sort leads to
    const sortedTrackingLinks = useMemo(() => {
        return trackingLinks.sort((a, b) => b.createdAt - a.createdAt)
    }, [trackingLinks])

    const [trackingLinkToRemove, setTrackingLinkToRemove] =
        useState<TrackingLinkType | null>(null)

    const thumbnail = gifUrl || screenshotUrl
    const isDisabled = visibility === 'Private'
    const gifColor = '#757575'

    const columns = [
        {
            field: 'name',
            headerName: 'Name',
            width: 140,
        },
        {
            field: 'domain',
            headerName: 'Domain',
            width: 140,
        },
        {
            field: 'createdAt',
            headerName: 'Created on',
            width: 140,
            renderCell: ({ row }) =>
                moment(row.createdAt).format('MMM DD YYYY'),
        },
        {
            field: 'actions',
            headerName: 'Actions',
            sortable: false,
            width: 140,
            renderCell: ({ row }) => (
                <>
                    <IconButton
                        size="small"
                        title="Copy link"
                        onClick={() => {
                            handleCopyLink(row)
                            onCopyClick?.()
                        }}
                    >
                        <LinkIcon />
                    </IconButton>
                    <CopyLinkedGif
                        onCopyClick={onCopyClick}
                        isPublic={isPublic}
                        playbookId={playbookId}
                        isPlaylist={isPlaylist}
                        playbookTitle={title}
                        playbookThumbnail={thumbnail}
                        disabled={isDisabled}
                        color={gifColor}
                        trackLinkName={row.name}
                        trackLinkId={row.id}
                    />
                    <IconButton
                        size="small"
                        title="Remove Link"
                        onClick={() => setTrackingLinkToRemove(row)}
                    >
                        <DeleteIcon />
                    </IconButton>
                </>
            ),
        },
    ]

    const generateLink = useMemo(() => {
        const host = `https://${Config.firebase.authDomain}`
        const playBookPath = `/share/playbooks/${playbookId}`
        const playListPath = `/share/playlists/${playbookId}`
        const path = isPlaylist ? playListPath : playBookPath

        return host + path
    }, [playbookId, isPlaylist])

    const [page, setPage] = useState(0)

    const { showSuccessNotification, showErrorNotification } = useNotification()

    const handleCopyLink = (link: TrackingLinkType) => {
        const { id: linkId, name, domain } = link
        const companyLink = `${generateLink}?track_link_name=${name}&track_link_id=${linkId}&origin=${uid}`

        logToMixpanel('copyLink', {
            track_name: name,
            track_domain: domain || '',
            utm_campaign: name,
            context: 'track-link',
            ...playbookToMixpanelProps(playbook),
        })

        copyToClipboard(companyLink).then(() => {
            showSuccessNotification('Link copied to the clipboard')
        })
    }

    const handleDeleteLink = () => {
        const { id, name, domain } = trackingLinkToRemove as TrackingLinkType
        loading.setTrue()

        const callback = error => {
            if (error) {
                showErrorNotification('Something went wrong, Please try again')
            } else {
                onLinkDelete()
                logToMixpanel('delete_tracking_link', {
                    track_name: name,
                    track_domain: domain || '',
                    utm_campaign: name,
                })
                setTrackingLinkToRemove(null)
            }

            loading.setFalse()
        }

        deleteTrackingLink(callback, { id })
    }

    return (
        <>
            <Paper className={classes.paper}>
                <DataGrid
                    className={classes.grid}
                    autoHeight
                    rows={sortedTrackingLinks}
                    columns={columns}
                    page={page}
                    // @ts-ignore
                    onPageChange={setPage}
                    sortingOrder={['asc', 'desc']}
                    disableColumnMenu
                    disableColumnFilter
                    disableSelectionOnClick
                    pageSize={5}
                />
            </Paper>

            <ConfirmationDialog
                title="Remove Link"
                text={`Are you sure you want to remove the ${trackingLinkToRemove?.name} link?`}
                isOpen={Boolean(trackingLinkToRemove)}
                onClose={() => {
                    setTrackingLinkToRemove(null)
                    setPage(0)
                }}
                onConfirm={handleDeleteLink}
                loading={loading.isTrue}
                confirmBtnText="Delete"
            />
        </>
    )
}
