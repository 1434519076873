import { UserFeedbackPayload } from 'ducks/reducers/userFeedbackReducer'

export enum UserFeedbackActionE {
    openModal = 'USER_FEEDBACK/openModal',
    hideModal = 'USER_FEEDBACK/hideModal',
    clearModal = 'USER_FEEDBACK/clearModal',
    overwriteFields = 'USER_FEEDBACK/overwriteFields',
}

export const openUserFeedbackModal = (payload: UserFeedbackPayload = {}) => ({
    type: UserFeedbackActionE.openModal,
    payload,
})

export const overWriteUserFeedbackFields = (
    payload: UserFeedbackPayload = {}
) => ({
    type: UserFeedbackActionE.overwriteFields,
    payload,
})

export const hideUserFeedbackModal = () => ({
    type: UserFeedbackActionE.hideModal,
})

export const clearUserFeedbackModal = () => ({
    type: UserFeedbackActionE.clearModal,
})
