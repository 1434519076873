import React, { useContext, memo } from 'react'
import { Backdrop, Box, Grid } from '@material-ui/core'

import { PlaybookCard } from 'UI/Components'

import { AppContext } from 'app/AppProvider'
import { PlaybookType } from 'types/playbook'

const RATING_FRACTION_DIGITS = 1

const mapResult = (o): PlaybookType => ({
    url: o.url,
    screenshotUrl: o.img,
    creator_uid: o.creator_uid,
    duration: o.duration,
    id: o.id,
    username: o.creator_name,
    userImage: o.creator_img,
    uploadedAt: o.created,
    title: o.title,
    description: o.description,
    tags: o.tags,
    transcript: o.transcript || ' ',
    transcriptUrl: o.transcriptUrl || ' ',
    rating: +(o.rating || 0).toFixed(RATING_FRACTION_DIGITS),
    type: o.type,
    isPlaylist: o.isPlaylist,
    playbookList: o.playbookList,
    isPublished: !o.private,
    views: o.views || 0,
    applications: o.applications,
    files: o.files,
    links: o.links,
    visibility: o.visibility,
    currentTranscriptLang: o.currentTranscriptLang,
    isPublic: o.isPublic,
    uploadedByOrgId: o.uploadedByOrgId,
    gifUrl: o.gifUrl,
    teams: o.teams,
    publishedForUsers: o.publishedForUsers,
    organizationId: o.organizationId,
    originalFileName: o.originalFileName,
    isMainIncluded: o.isMainIncluded,
})

export const generatePlaybook = (playbook): PlaybookType => {
    return mapResult(playbook)
}

type PlaybookHitsProps = { playbooks: Array<PlaybookType> }

export const PlaybookHits = memo(({ playbooks }: PlaybookHitsProps) => {
    const { firstInstall } = useContext(AppContext)

    if (!playbooks?.length) return null

    return (
        <Box mt={2}>
            <Backdrop style={{ zIndex: 2 }} open={firstInstall.isTrue} />
            <Grid
                item
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                style={{ flexDirection: 'row' }}
            >
                {playbooks.map((hit, index) => (
                    <Grid
                        item
                        sm={6}
                        md={4}
                        lg={3}
                        key={hit.id}
                        style={{ width: '100%' }}
                    >
                        <PlaybookCard
                            firstInstall={
                                firstInstall.isTrue && index === 0
                                    ? firstInstall
                                    : null
                            }
                            playbook={generatePlaybook(hit)}
                        />
                    </Grid>
                ))}
            </Grid>
        </Box>
    )
})
