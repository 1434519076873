import { request } from '../services/utils'

export const getApps = async () => {
    try {
        return await request('/k/v1/getApps', 'POST')
    } catch (e) {
        console.error(e)
        return null
    }
}
