import React, { memo, useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { uiId } from 'services/utils'
import { getPlaybooks } from 'background/services'
import { setTags } from 'ducks/actions'

import { Box, Button, makeStyles, Typography } from '@material-ui/core'

import * as AssetsService from 'services/extServices'
import IconFlagRed from 'assets/icons/icon-flag-reg.svg'

import { PlaybookHits } from 'UI/Pages/playbooks/PlaybookHits'
import {
    SearchByAppContext,
    isMainSpace,
    generateSpaceIds,
} from './PagePlaybooks'
import { ExtensionOnly } from 'UI/Components/EnvComponents'

import { useBoolean, TypeUseBoolean } from 'hooks'

const pageSize = 8

const useStyles = makeStyles({
    noResult: {
        textAlign: 'center',
    },
    emptySubTitle: {
        color: '#78909c',
    },
})

type Props = {
    index: number
    tags: Array<string>
    defaultTags?: Array<string> | null
    applicationId: string
    hasMore?: TypeUseBoolean
    increasePage: () => void
    spaces: string[]
}

const Page = memo(
    ({
        index,
        tags,
        defaultTags,
        applicationId,
        hasMore,
        increasePage,
        spaces,
    }: Props) => {
        const { setSearchResult, setSearchWithDefaultTagsResult } =
            useContext(SearchByAppContext)
        const dispatch = useDispatch()
        const classes = useStyles()

        const [data, setData] = useState<any>(undefined)

        useEffect(() => {
            if (!data) return

            setSearchResult(data?.playbooks?.length < 1)
        }, [data, setSearchResult])

        useEffect(() => {
            // prevent api call before we get default tags for current page
            if (defaultTags === null) return

            getPlaybooks(
                payload => {
                    if (index === 0) {
                        const emptyWithDefaultTags =
                            defaultTags &&
                            defaultTags.length > 0 &&
                            payload?.playbooks?.length === 0

                        setSearchWithDefaultTagsResult(!!emptyWithDefaultTags)

                        if (emptyWithDefaultTags) {
                            return getPlaybooks(
                                newPayload => {
                                    dispatch(
                                        setTags(
                                            newPayload?.facets?.tags || null
                                        )
                                    )
                                    setData(newPayload)
                                },
                                {
                                    page: index,
                                    size: pageSize,
                                    search: defaultTags?.join(' '),
                                    tags: tags.filter(
                                        tag => !defaultTags?.includes(tag)
                                    ),
                                    applicationId,
                                    isMainSpace: isMainSpace(spaces),
                                    spaceIds: generateSpaceIds(spaces),
                                }
                            )
                        }
                        dispatch(setTags(payload?.facets?.tags || null))
                    }
                    setData(payload)
                },
                {
                    page: index,
                    size: pageSize,
                    tags: [
                        ...(defaultTags
                            ? defaultTags.filter(
                                  defTag => !tags.includes(defTag)
                              )
                            : []),
                        ...tags,
                    ],
                    applicationId,
                    isMainSpace: isMainSpace(spaces),
                    spaceIds: generateSpaceIds(spaces),
                }
            )
        }, [dispatch, index, applicationId, tags, defaultTags, spaces])

        const playbooks = data?.playbooks
        const hitsNumber = data?.nbHits

        useEffect(() => {
            if (hasMore !== undefined) {
                if (hitsNumber && hitsNumber > (index + 1) * pageSize) {
                    hasMore.setTrue()
                } else {
                    hasMore.setFalse()
                }
            }
        }, [hasMore, hitsNumber, index])

        if (data === null || playbooks?.length === 0) {
            return (
                <Box mt={4} className={classes.noResult}>
                    <img
                        src={AssetsService.getResourceURL(IconFlagRed)}
                        alt="red flag"
                    />
                    <Typography>
                        <b>No results found </b>
                    </Typography>
                    <ExtensionOnly>
                        <Typography
                            variant="body2"
                            className={classes.emptySubTitle}
                        >
                            <b>
                                Couldn't find what you were looking for? You can
                                always ask a Guidde
                            </b>
                        </Typography>
                    </ExtensionOnly>
                </Box>
            )
        }

        if (playbooks?.length) {
            return (
                <>
                    <PlaybookHits playbooks={playbooks} />
                    {hasMore?.isTrue && (
                        <Box mt={3} display="inline-block">
                            <Button
                                size="small"
                                variant="outlined"
                                onClick={event => {
                                    event.preventDefault()
                                    increasePage()
                                }}
                                id={uiId('show-more-btn')}
                            >
                                <Typography component="span">
                                    Show more
                                </Typography>
                            </Button>
                        </Box>
                    )}
                </>
            )
        }

        return (
            <Box mt={3} mb={5} pb={3}>
                <Typography>Loading...</Typography>
            </Box>
        )
    }
)

export const PaginatedPlaybooks = memo(
    ({ appFilter, tagsFilter, spaceFilter }: any) => {
        const [page, setPage] = useState(0)
        const increasePage = () => setPage(prevState => prevState + 1)
        const resetPage = () => setPage(0)

        const hasMore = useBoolean()

        const [pages, setPages] = useState<Array<JSX.Element>>([])

        // reset current page on each app and tags change
        useEffect(() => {
            resetPage()
            setPages([])
        }, [appFilter.state, tagsFilter.state])

        const rerenderPages = () => {
            const tempPages: Array<JSX.Element> = []

            for (let i = 0; i <= page; i++) {
                tempPages.push(
                    <Page
                        index={i}
                        key={i}
                        hasMore={i === page ? hasMore : undefined}
                        increasePage={increasePage}
                        applicationId={
                            appFilter.state || appFilter.defaultState || ''
                        }
                        tags={tagsFilter.state}
                        defaultTags={
                            i === 0 ? tagsFilter.defaultState : undefined
                        }
                        spaces={spaceFilter.state}
                    />
                )
            }
            setPages(tempPages)
        }

        useEffect(() => {
            rerenderPages()
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [page, hasMore, spaceFilter.state])

        useEffect(() => {
            if (page !== 0) {
                return undefined
            }
            rerenderPages()
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [
            appFilter.state,
            appFilter.defaultState,
            tagsFilter.defaultState,
            tagsFilter.state,
        ])

        return <Box id={uiId('playbooks-list')}>{pages}</Box>
    }
)
