import { UserFeedbackActionE } from 'ducks/actions/userFeedbackActions'
import { Reducer } from 'redux'
import { Recording } from 'types/recording'

type UserFeedbackState = {
    isModalOpen: boolean
    subject: string
    requestType: string
    recordings: Recording[]
}

export type UserFeedbackPayload = Partial<UserFeedbackState>

type UserFeedbackAction = {
    type: UserFeedbackActionE
    payload: UserFeedbackPayload
}

type UserFeedbackReducer = Reducer<UserFeedbackState, UserFeedbackAction>

const initialUserFeedbackState: UserFeedbackState = {
    isModalOpen: false,
    subject: '',
    requestType: 'feedback',
    recordings: [],
}

export const userFeedbackReducer: UserFeedbackReducer = (
    state = initialUserFeedbackState,
    action
) => {
    const { type, payload } = action

    switch (type) {
        case UserFeedbackActionE.openModal:
            return {
                ...state,
                isModalOpen: true,
                ...payload,
            }
        case UserFeedbackActionE.hideModal:
            return { ...state, isModalOpen: false }
        case UserFeedbackActionE.clearModal:
            return initialUserFeedbackState
        case UserFeedbackActionE.overwriteFields:
            return { ...state, ...payload }
        default:
            return state
    }
}
