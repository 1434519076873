import React from 'react'
import {
    Avatar,
    Box,
    Link,
    ListItem,
    ListItemAvatar,
    ListItemText,
    makeStyles,
} from '@material-ui/core'
import clsx from 'clsx'
import { InfoItem } from './InfoTab'
import { logToMixpanel } from 'background/services'

const useStyles = makeStyles({
    pdfLogo: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '40px',
        height: '40px',
        color: '#828282',
        backgroundColor: '#ededed',
        fontWeight: 'bold',
        fontFamily: 'Montserrat, sans-serif',
        fontSize: '14px',
    },
    primary: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        width: '100%',
    },
    link: {
        fontFamily: 'Montserrat, sans-serif',
        textDecoration: 'underline',
        '&:visited': {
            textDecoration: 'none',
            color: 'inherit',
        },
        '&:hover': {
            color: '#166ABC',
        },
        '&:active': {
            color: '#145EA8',
        },
    },
    linkWrapper: {
        minWidth: 0,
        height: '40px',
        display: 'flex',
        alignItems: 'center',
    },
})

export type PreviewFile = {
    name: string
    url: string
}

type Props = {
    item: InfoItem
    isLink?: boolean
}

export const InfoListItem = ({ item: { name, url }, isLink }: Props) => {
    const classes = useStyles()

    const handleClick = () => {
        logToMixpanel('playbook_info_item_clicked')
        window.open(url)
    }

    const isPdf = name?.endsWith('pdf')

    return (
        <ListItem button={!isLink as any} onClick={handleClick}>
            {!isLink && (
                <ListItemAvatar>
                    {isPdf ? (
                        <Box className={classes.pdfLogo}>PDF</Box>
                    ) : (
                        <Avatar variant="square" src={url} alt="img" />
                    )}
                </ListItemAvatar>
            )}
            {isLink ? (
                <Box className={classes.linkWrapper}>
                    <Link
                        title={url}
                        href={url}
                        target="_blank"
                        className={clsx(classes.primary, classes.link)}
                    >
                        {name ?? url}
                    </Link>
                </Box>
            ) : (
                <ListItemText
                    primary={name ?? url}
                    classes={{
                        primary: classes.primary,
                    }}
                />
            )}
        </ListItem>
    )
}
