import { Box, makeStyles, Typography } from '@material-ui/core'
import clsx from 'clsx'

import * as AssetsService from 'services/extServices'
import RatingIcon from 'assets/icons/icon-feedback.svg'
import ViewIcon from '@material-ui/icons/Visibility'

import { SpacedGroup, PlaybookVisibility, VisibilityType } from 'UI/Components'

const useStyles = makeStyles(theme => ({
    playbookFooter: {
        position: 'relative',
        fontSize: theme.typography.pxToRem(12),
        display: 'flex',
        padding: theme.spacing(2, 1),
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    countItem: {
        width: '20px',
        height: '20px',
    },
    label: {
        fontSize: '13px',
        marginLeft: '2px',
        flexWrap: 'nowrap',
    },
    rantingIcon: {
        width: '15px',
        height: '15px',
        marginBottom: '2px',
    },
}))

type Props = {
    views: number
    rating: number
    visibility: VisibilityType
}

const minimalRatingToShow = 50

export const PlaybookFooter = ({ views, rating, visibility }: Props) => {
    const classes = useStyles()

    return (
        <Box className={classes.playbookFooter}>
            <PlaybookVisibility visibility={visibility} />
            <SpacedGroup spacing={0.5}>
                {views !== 0 && (
                    <Box display="flex" alignItems="center">
                        <ViewIcon className={classes.countItem} />
                        <Typography className={classes.label}>
                            {views}
                        </Typography>
                    </Box>
                )}
                {rating > minimalRatingToShow && (
                    <Box display="flex" alignItems="center">
                        <img
                            src={AssetsService.getResourceURL(RatingIcon)}
                            className={clsx(
                                classes.countItem,
                                classes.rantingIcon
                            )}
                            alt="img"
                        />

                        <Typography className={classes.label}>
                            {rating}%
                        </Typography>
                    </Box>
                )}
            </SpacedGroup>
        </Box>
    )
}
