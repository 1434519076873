import { Box, makeStyles, Typography } from '@material-ui/core'

const useStyles = makeStyles({
    pbTags: {
        marginTop: '8px',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        display: 'inline-block',
        textAlign: 'left',
    },
    pbSingleTag: {
        marginRight: '8px',
        fontSize: '12px',
        whiteSpace: 'nowrap',
        display: 'inline-block',
    },
})

type Props = {
    tags: Array<string>
}

export const Tags = ({ tags }: Props) => {
    const classes = useStyles()

    const hasExtraTags = tags?.length > 3

    return (
        <Box
            width="100%"
            height="20px"
            className={classes.pbTags}
            title={(hasExtraTags ? tags.slice(2) : tags).join(', ')}
        >
            {(hasExtraTags ? tags.slice(0, 2) : tags).map(
                (tag, i) =>
                    tag && (
                        <Typography
                            key={i}
                            display="inline"
                            component="span"
                            className={classes.pbSingleTag}
                        >
                            #{tag}
                        </Typography>
                    )
            )}
            <Typography variant="body2" component="span">
                {hasExtraTags && ` +${tags.length - 2}`}
            </Typography>
        </Box>
    )
}
