import { forwardRef, useImperativeHandle, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useNotification } from 'hooks'

import { IconButton } from '@material-ui/core'
import { Gif as GifIcon } from '@material-ui/icons'

import { RootState } from 'ducks/rootReducer'
import Config from 'config'

type Props = {
    isPublic: boolean
    playbookId: string
    isPlaylist: boolean
    playbookTitle: string
    playbookThumbnail: string
    disabled: boolean
    color?: string
    trackLinkName?: string
    trackLinkId?: string
    rootClass?: string
    onCopyClick?: () => void
}

export const CopyLinkedGif = forwardRef(
    (
        {
            isPublic,
            playbookId,
            isPlaylist,
            playbookTitle,
            playbookThumbnail,
            disabled,
            color,
            trackLinkName,
            trackLinkId,
            rootClass,
            onCopyClick,
        }: Props,
        ref
    ) => {
        const {
            user: { uid },
        } = useSelector((state: RootState) => state.user)

        const { showSuccessNotification, showWarningNotification } =
            useNotification()

        const playbookPath = !isPublic
            ? `/playbooks/${playbookId}`
            : `/share/playbooks/${playbookId}`

        const playlistPath = !isPublic
            ? `/playbooks/playlist/${playbookId}`
            : `/share/playlists/${playbookId}`

        const copyToClipboard = useCallback(
            (html: string) => {
                const container = document.createElement('div')
                container.innerHTML = html
                container.style.position = 'fixed'
                container.style.pointerEvents = 'none'
                container.style.opacity = '0'
                document.body.appendChild(container)
                window?.getSelection()?.removeAllRanges()
                const range = document.createRange()
                range.selectNode(container)
                window?.getSelection()?.addRange(range)
                document.execCommand('copy')
                document.body.removeChild(container)
                showSuccessNotification(
                    'Linked GIF was copied to the clipboard'
                )
            },
            [showSuccessNotification]
        )

        const handleIconClick = useCallback(() => {
            if (disabled)
                return showWarningNotification(
                    'The video privacy settings require you to make it non-private in order to copy the linked GIF'
                )
            const path = isPlaylist ? playlistPath : playbookPath
            const urlParams = new URLSearchParams()

            if (trackLinkName) {
                urlParams.append('track_link_name', trackLinkName)
            }

            if (trackLinkId) {
                urlParams.append('track_link_id', trackLinkId)
            }

            const host = `https://${Config.firebase.authDomain}`

            urlParams.append('origin', uid)
            const link = `${host}${path}?${urlParams.toString()}`

            const imageHtml = `<a href=${link}>
        <p>${playbookTitle}</p>
        <img style="width:250px;height:145px;object-fit:contain;border-radius:2px" src=${playbookThumbnail} />
        </a>`
            copyToClipboard(imageHtml)
            onCopyClick?.()
        }, [
            copyToClipboard,
            disabled,
            isPlaylist,
            playbookPath,
            playbookThumbnail,
            playbookTitle,
            playlistPath,
            showWarningNotification,
            trackLinkId,
            trackLinkName,
            uid,
            onCopyClick,
        ])

        useImperativeHandle(
            ref,
            () => ({
                copyGifLink: handleIconClick,
            }),
            [handleIconClick]
        )

        return (
            <>
                <IconButton
                    className={rootClass}
                    size="small"
                    title="Copy linked GIF"
                    onClick={handleIconClick}
                >
                    <GifIcon style={{ color: color ? color : 'white' }} />
                </IconButton>
            </>
        )
    }
)
