import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import { changeVideoSpeed, changeVideoTime } from 'background/services'

import { generatePlayerId } from 'services/video'
import { isSDK } from '../../services/environmentService'

const useStyles = makeStyles({
    video: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        cursor: 'pointer',
    },
})

type Props = {
    playbookURL: string
    defaultSpeed?: number
    startTime?: number
}

export const VideoPlayer = ({
    playbookURL,
    defaultSpeed,
    startTime,
}: Props) => {
    const classes = useStyles()

    /* WARNING: This is just a decoy for localhost:3000.
       Don't worry if some of the video functionality is not working locally -
       try testing it in extension build. */
    if (process.env.NODE_ENV === 'development' || isSDK) {
        return (
            <video
                controls
                autoPlay
                controlsList="nodownload"
                className={classes.video}
                src={playbookURL}
                id={generatePlayerId()}
            />
        )
    }

    /* Iframe is used as a proxy to avoid CSP issues.
       When we try to load a video on a website, we download it under their CSP policies,
       often restricting unknown resources. Video will be blocked.
       That is why we load a video inside an iframe,
       then it is loaded under chrome extension CSP policy (our policy). */

    const source = `${window.chrome.extension.getURL(
        '/videoPlayer/player.html'
    )}?video=${encodeURIComponent(playbookURL)}`

    return (
        <iframe
            frameBorder={0}
            allow="autoplay; fullscreen"
            className={classes.video}
            title="Video Player"
            src={source}
            onLoad={() => {
                if (defaultSpeed && defaultSpeed > 1) {
                    changeVideoSpeed({ speed: defaultSpeed })
                }
                if (startTime) {
                    changeVideoTime({ seconds: startTime })
                }
            }}
        />
    )
}
