const noop = () => {}

const buildTarget = process.env.REACT_APP_BUILD_TARGET

export const checkIsExtension = () => buildTarget === 'ext'

export const isLocal = process.env.NODE_ENV === 'development'
export const isSDK = buildTarget === 'sdk'
export const isExtension = buildTarget === 'ext'

export const envLogicRouter = (sdkFunc = noop, extFunc = noop) => {
    if (isSDK) {
        return sdkFunc?.()
    }

    if (isExtension) {
        return extFunc?.()
    }

    return noop
}
export const sdkOnly = sdkFunc => (isSDK ? sdkFunc : noop)
export const extensionOnly = extFunc => (isExtension ? extFunc : noop)
