import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
    DialogProps,
} from '@material-ui/core'
import { LinearProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import { ReactNode } from 'react'

const useStyles = makeStyles(theme => ({
    title: {
        fontSize: '1.5rem',
        textAlign: 'center',
        fontWeight: 600,
    },
    text: {
        textAlign: 'center',
    },
    closeButton: {
        position: 'absolute',
        right: 0,
        top: 0,
        padding: theme.spacing(0.5),
        margin: theme.spacing(0.5),
    },
    actions: {
        padding: theme.spacing(3),
        justifyContent: 'center',
    },
}))

type Props = {
    isOpen: boolean
    title: string
    text?: ReactNode
    body?: ReactNode
    closeBtnText?: string
    confirmBtnText?: string
    onClose: () => void
    onConfirm: () => void
    loading?: boolean
    maxWidth?: DialogProps['maxWidth']
}

export const ConfirmationDialog = ({
    isOpen,
    title,
    text,
    body,
    closeBtnText = 'Cancel',
    confirmBtnText = 'Confirm',
    onClose,
    onConfirm,
    loading,
    maxWidth = 'xs',
}: Props) => {
    const classes = useStyles()

    return (
        <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth={maxWidth}>
            {loading && <LinearProgress />}
            <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={onClose}
            >
                <CloseIcon />
            </IconButton>

            <DialogTitle>
                <Typography className={classes.title}>{title}</Typography>
            </DialogTitle>
            <DialogContent>
                <Typography variant="body1" className={classes.text}>
                    {text}
                </Typography>
                {body}
            </DialogContent>
            <DialogActions className={classes.actions}>
                <Button size="medium" color="default" onClick={onClose}>
                    {closeBtnText}
                </Button>
                <Button size="medium" onClick={onConfirm} disabled={loading}>
                    {confirmBtnText}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
