import { useContext } from 'react'
import { Box, Button, List, makeStyles, Typography } from '@material-ui/core'

import { Notification } from './Notification'
import { NotificationsContext } from 'UI/Components'

const useStyles = makeStyles(({ spacing }) => ({
    head: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #e0e0e0',
        padding: spacing(3),
        paddingBottom: spacing(1),
        position: 'sticky',
        top: 0,
        backgroundColor: 'white',
        zIndex: 2,
    },
    clearBtn: {
        display: 'block',
        color: '#212121',
        fontSize: '12px !important',
        minHeight: 0,
        minWidth: 0,
        width: 'fit-content',
        marginRight: -spacing(1),
    },
    emptyMsgWrapper: {
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        padding: spacing(3),
    },
    notificationsWrapper: {
        padding: `${spacing(2)}px ${spacing(3)}px`,
    },
}))

export const PageNotifications = () => {
    const classes = useStyles()

    const { clearNotifications, notifications } =
        useContext(NotificationsContext)

    if (!notifications.length) {
        return (
            <Box className={classes.emptyMsgWrapper}>
                <Typography variant="h6">
                    You don't have any notifications
                </Typography>
            </Box>
        )
    }

    return (
        <>
            <Box className={classes.head}>
                <Typography variant="subtitle2">
                    <b>Notifications</b>
                </Typography>
                <Button
                    variant="text"
                    className={classes.clearBtn}
                    onClick={clearNotifications}
                >
                    Clear all
                </Button>
            </Box>
            <List component="nav" className={classes.notificationsWrapper}>
                {notifications.map((ntf, idx) => (
                    <Notification notification={ntf} key={idx} />
                ))}
            </List>
        </>
    )
}
