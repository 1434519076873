import { initReact } from 'initReact'
import { registerSDKToFirebaseAuth } from './firebase'
import { initMixpanel, registerMixpanelSuperProps } from './background/mixpanel'

import { setSdkConfig } from './ducks/actions'
import { store } from './ducks/store'
import { isLocal } from './services/environmentService'
import Config from './config'
import { initSentry } from './background/sentry'

let inactive = true

const RESERVED_USER_VARS = new Set([
    'userId',
    'userEmail',
    'userName',
    'accountId',
    'accountName',
])

const addMixpanelPrefix = userInfo =>
    userInfo
        ? Object.entries(userInfo).reduce(
              (acc, [key, value]) =>
                  // Add 'tenant_' prefix to non-standard vars, so they don't override our mixpanel properties
                  RESERVED_USER_VARS.has(key)
                      ? { ...acc, [key]: value }
                      : { ...acc, [`tenant_${key}`]: value },
              {}
          )
        : userInfo

const onAuthMessageReceived = async event => {
    const { type, tenant, session, apiKey, userInfo, organizationId, styles } =
        event?.data

    if (type === 'INIT_SDK' && inactive && tenant && session) {
        if (isLocal) {
            // @ts-ignore - set up proxy for local env
            window.localProxy = `https://${apiKey}.sdk.${Config.firebase.authDomain}`
        }

        registerSDKToFirebaseAuth(
            tenant,
            apiKey,
            session,
            userInfo,
            firebaseUser => {
                initMixpanel()
                registerMixpanelSuperProps(
                    {
                        ...firebaseUser,
                        customProps: addMixpanelPrefix(userInfo),
                    },
                    {
                        'sdk-tenant': tenant,
                        'sdk-org': organizationId,
                    }
                )
                initReact(false)
                window.removeEventListener('message', onAuthMessageReceived)
                store.dispatch(setSdkConfig(styles))
            }
        )
        inactive = false
    }
}

const initSDKContentScript = () => {
    window.addEventListener('message', onAuthMessageReceived, false)
    initSentry()
}

export default initSDKContentScript
