import { useContext, useRef } from 'react'
import clsx from 'clsx'
import { useDispatch, useSelector } from 'react-redux'

import {
    Box,
    CircularProgress,
    IconButton,
    makeStyles,
    Theme,
    Typography,
} from '@material-ui/core'
import IconPlay from '@material-ui/icons/PlayArrow'
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary'

import { SpacedGroup } from 'UI/Components/SpacedGroup'
import { Menu } from './Menu'
import { ExtensionOnly } from 'UI/Components/EnvComponents'

import { logToMixpanel } from 'background/services'
import { playbookToMixpanelProps } from 'services/mixpanel'
import { RootState } from 'ducks/rootReducer'
import { AppContext } from 'app/AppProvider'

import { TypeUseBoolean } from 'hooks'
import { PlaybookType } from 'types/playbook'
import { setPlaybook } from 'ducks/actions'

const useApplicationLogo = (appName: string): string | undefined => {
    const apps = useSelector((state: RootState) => state.apps)
    return apps.find(app => appName !== undefined && app.title === appName)
        ?.logo
}

const useStyles = makeStyles<Theme, { showControls: boolean }>(theme => ({
    '@keyframes pulse': {
        '0%': {
            transform: 'scaleX(1) scaleY(1)',
        },
        '10%': {
            transform: 'scaleX(0.85) scaleY(0.85)',
        },
        '20%': {
            transform: 'scaleX(1) scaleY(1)',
        },
        '30%': {
            transform: 'scaleX(0.85) scaleY(0.85)',
        },
        '40%': {
            transform: 'scaleX(1) scaleY(1)',
        },
        '50%': {
            transform: 'scaleX(0.85) scaleY(0.85)',
        },
        '60%': {
            transform: 'scaleX(1) scaleY(1)',
        },
        '70%': {
            transform: 'scaleX(0.85) scaleY(0.85)',
        },
        '80%': {
            transform: 'scaleX(1) scaleY(1)',
        },
        '90%': {
            transform: 'scaleX(0.85) scaleY(0.85)',
        },
        '100%': {
            transform: 'scaleX(1) scaleY(1)',
        },
    },
    pulseAnimation: {
        animation: '$pulse 5s infinite',
        border: '1px solid black',
    },
    cardHeader: {
        height: '125px',
        position: 'relative',
    },
    placeholder: {
        height: '100%',
        width: '100%',
        objectFit: 'cover',
    },
    applicationLogo: {
        position: 'absolute',
        bottom: '0px',
        width: '100%',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        background: '#ffffffcc',
        zIndex: 1,
        boxSizing: 'border-box',
    },
    controlsContainer: {
        position: 'absolute',
        top: 0,
        opacity: 0,
        transition: 'opacity 0.3s linear',
        zIndex: ({ showControls }) => (showControls ? 3 : 2),
    },
    playBtn: {
        background: 'white',
        cursor: 'pointer',
        position: 'absolute',
        color: 'black',
        '&:hover': {
            opacity: 0.8,
            background: 'white',
        },
    },
    playlistOverlay: {
        background: 'rgb(0 0 0 / 70%)',
        position: 'absolute',
        right: 0,
        top: 0,
        height: '100%',
        width: '25%',
    },
    playbookCount: {
        fontWeight: 500,
        color: 'white',
    },
    appLogo: {
        maxHeight: '35px',
        width: 'auto',
        maxWidth: '50px',
        minHeight: '20px',
        objectFit: 'contain',
    },
}))

type Props = {
    playbook: PlaybookType
    firstInstall?: TypeUseBoolean
    isHighlighted: boolean
    showControls: boolean
    renderControls: boolean
}

export const PlaybookHeader = ({
    firstInstall,
    isHighlighted,
    playbook,
    showControls,
    renderControls,
}: Props) => {
    const classes = useStyles({ showControls })

    const dispatch = useDispatch()

    const { description, screenshotUrl, isPlaylist, applications } = playbook
    const useApplicationLogoRef = useRef(useApplicationLogo)
    const appLogoSrc = useApplicationLogo(applications?.[0]?.applicationName)
    const appLogosSrcFromApplications = applications
        ?.slice(0, 3)
        .map(it => useApplicationLogoRef.current(it.applicationName))

    const appLogosSrc =
        appLogosSrcFromApplications?.length > 0
            ? appLogosSrcFromApplications
            : [appLogoSrc]

    const { openPlaybookMode, openPlaylistMode } = useContext(AppContext)

    return (
        <Box className={classes.cardHeader} title={description || ''}>
            {appLogosSrc && (
                <Box className={classes.applicationLogo}>
                    <SpacedGroup spacing={0.625}>
                        {appLogosSrc.filter(Boolean).map((src, i) => (
                            <img
                                key={(src || '') + i}
                                className={classes.appLogo}
                                src={src}
                            />
                        ))}
                    </SpacedGroup>
                </Box>
            )}

            <Box height="100%">
                {screenshotUrl ? (
                    <img
                        alt="placeholder"
                        className={classes.placeholder}
                        src={screenshotUrl}
                    />
                ) : (
                    <Box
                        px={2}
                        height="100%"
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <CircularProgress />
                    </Box>
                )}
            </Box>

            {renderControls && (
                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                    style={{ opacity: showControls || isHighlighted ? 1 : 0 }}
                    className={classes.controlsContainer}
                    width="100%"
                    height="100%"
                >
                    <IconButton
                        size="small"
                        className={clsx(classes.playBtn, {
                            [classes.pulseAnimation]: isHighlighted,
                        })}
                        onClick={() => {
                            if (isHighlighted) {
                                firstInstall?.setFalse()
                            }

                            if (playbook.isPlaylist) {
                                openPlaylistMode()
                            } else {
                                openPlaybookMode()
                            }

                            dispatch(setPlaybook(playbook))
                            logToMixpanel(
                                'videoPlayClicked',
                                playbookToMixpanelProps(playbook)
                            )
                        }}
                    >
                        <IconPlay fontSize="large" />
                    </IconButton>

                    <ExtensionOnly>
                        <Menu playbook={playbook} />
                    </ExtensionOnly>
                </Box>
            )}

            {isPlaylist && (
                <Box
                    className={classes.playlistOverlay}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Box
                        pt={1}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        flexDirection="column"
                    >
                        <Typography className={classes.playbookCount}>
                            {playbook.playbookList?.length}
                        </Typography>
                        <VideoLibraryIcon style={{ color: 'white' }} />
                    </Box>
                </Box>
            )}
        </Box>
    )
}
