import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { Box, Button, Dialog, IconButton, makeStyles } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import { useBoolean } from 'hooks'
import { RootState } from 'ducks/rootReducer'
import { getScheduleUrl } from 'background/services'
import { uiId } from 'services/utils'

const useStyles = makeStyles(theme => ({
    paperWidthFalse: {
        width: '1000px',
        paddingBottom: '30px',
    },
    scheduleBtn: {
        backgroundColor: 'white',
        color: '#cd0000',
        fontSize: '14px',
    },
    closeButton: {
        position: 'absolute',
        right: 0,
        top: 0,
        padding: theme.spacing(0.5),
        margin: theme.spacing(0.5),
        zIndex: 1,
    },
    iframe: {
        border: 'none',
    },
}))

export const ScheduleSession = () => {
    const classes = useStyles()

    const openDialog = useBoolean()

    const orgId = useSelector((state: RootState) => state.user.roles.o)

    const [scheduleUrl, setScheduleUrl] = useState(null)

    useEffect(() => {
        getScheduleUrl(url => setScheduleUrl(url), { orgId })
    }, [orgId])

    if (!scheduleUrl) {
        return null
    }

    return (
        <Box ml={2}>
            <Button
                size="small"
                id={uiId('schedule-a-session-btn')}
                color="default"
                className={classes.scheduleBtn}
                onClick={openDialog.setTrue}
            >
                Schedule a session
            </Button>

            <Dialog
                open={openDialog.isTrue}
                onClose={openDialog.setFalse}
                maxWidth={false}
                classes={{
                    paperWidthFalse: classes.paperWidthFalse,
                }}
            >
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={openDialog.setFalse}
                >
                    <CloseIcon />
                </IconButton>
                <iframe
                    className={classes.iframe}
                    title="calendly"
                    height="650px"
                    src={scheduleUrl || undefined}
                />
            </Dialog>
        </Box>
    )
}
