import short from 'short-uuid'

import Config from '../config'
import { getTenant, getToken } from '../firebase'
import { envLogicRouter, isSDK, isLocal } from './environmentService'
import { captureSentryError } from '../background/sentry'

export const getHost = () =>
    envLogicRouter(
        () => (isLocal ? (window as any).localProxy : ''),
        () => `https://${Config.firebase.authDomain}`
    )

export const request = (
    url: string,
    method: 'GET' | 'POST' | 'PUT' | 'DELETE',
    body?: any
) =>
    fetch(`${getHost()}${url}`, {
        method,
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${getToken()}`,
            ...(isSDK ? { 'X-Guidde-Tenant': getTenant() } : {}),
        },
        ...(body && { body: JSON.stringify(body) }),
    }).then((res: Response) => {
        if (res.ok) {
            return res.json()
        } else {
            envLogicRouter(
                () => {
                    captureSentryError(
                        new Error(`API Error ${res?.status}: ${res?.url}`),
                        res
                    )
                },
                () => {
                    throw new Error('Something went wrong')
                }
            )
        }
    })

export const option = (value: any, label: string) => ({ value, label })

export const uniqueArray = (a: Array<any>): Array<any> =>
    [...new Set(a.map(o => JSON.stringify(o)))].map(s => JSON.parse(s))

export const round = number => Math.round(number * 100) / 100

export const uuid = () => short.generate()

export const formatTime = seconds => {
    const format = val => `0${Math.floor(val)}`.slice(-2)

    const hours = seconds / 3600
    const minutes = (seconds % 3600) / 60
    const secs = seconds % 60

    return [...(hours >= 1 ? [hours] : []), minutes, secs].map(format).join(':')
}

export const getSelectionText = () => window.getSelection?.()?.toString()

export const uiId = id => `ui-t-ext-${id}`

export const isObjectsEqual = (a, b) => JSON.stringify(a) === JSON.stringify(b)

export const copyToClipboard = (text?: string) =>
    navigator.clipboard.writeText(text || '')
