import { useEffect, useMemo, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { isEqual } from 'services/lodash'

import { useInterval } from 'hooks'
import { RootState } from 'ducks/rootReducer'
import { tagsParser, TagsParserResponse } from 'services/tagsParser'
import { extensionOnly, sdkOnly } from '../services/environmentService'
import { sendMessageToParentScript } from '../services/sdkServices'

const countNodes = (selectors: string[]) =>
    selectors.map(selector => ({
        selector,
        count: document.querySelectorAll(selector).length,
    }))

export const useTagsParser = () => {
    const [defaultFilters, setDefaultFilters] = useState<TagsParserResponse>({
        app: '',
        appName: '',
        domain: '',
        tags: [],
        querySelectors: [], // All selectors we should keep in mind
    })

    // Href and querySelectors are in the same state to trigger setState only once on params change
    const [checkResult, setCheckResult] = useState<{
        href: string
        nodeCount: { selector: string; count: number }[]
    }>({
        href: document.location.href,
        nodeCount: [],
    })

    const [customLocation, setCustomLocation] = useState(undefined)

    const apps = useSelector((state: RootState) => state.apps)

    useEffect(() => {
        if (apps.length) {
            tagsParser(apps, customLocation).then(res => {
                setDefaultFilters(res)

                // For mixpanel events to resolve by default
                window._GUIDDE = {
                    CURRENT_APP: res.app,
                    CURRENT_DOMAIN: res.domain,
                    DEFAULT_TAGS: res.tags,
                }
            })
        }
    }, [apps, checkResult, customLocation])

    useEffect(
        sdkOnly(() => {
            window.addEventListener('message', event => {
                const { type, location } = event?.data

                if (type === 'URL_CHANGED' && location) {
                    const parsedLocation = JSON.parse(location)
                    setCustomLocation(parsedLocation)
                }
            })

            // Request location for the first time in case event listener was established after the load
            sendMessageToParentScript({ action: 'GET_LOCATION' })
        }),
        []
    )

    // Check whether location was changed or querySelectors added/removed from DOM
    const intervalCheckExt = useCallback(() => {
        const updatedLocation = document.location.href
        const updatedNodeCount = defaultFilters.querySelectors.length
            ? countNodes(defaultFilters.querySelectors)
            : []

        const shouldUpdateLocation = checkResult.href !== updatedLocation
        const shouldUpdateNodeCount = defaultFilters.querySelectors.length
            ? !isEqual(checkResult.nodeCount, updatedNodeCount)
            : false

        if (shouldUpdateLocation || shouldUpdateNodeCount) {
            setCheckResult({
                href: updatedLocation,
                nodeCount: updatedNodeCount,
            })
        }
    }, [checkResult, defaultFilters.querySelectors])

    // Does not make sense to check SDK url change, it's going to be iframe URL
    useInterval(extensionOnly(intervalCheckExt), 3000)

    return useMemo(() => defaultFilters, [defaultFilters])
}
