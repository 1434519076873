import {
    Box,
    CardContent,
    Grid,
    makeStyles,
    Typography,
} from '@material-ui/core'
import { Tags } from './Tags'

import * as AssetsService from 'services/extServices'
import IconWatch from 'assets/icons/watch.svg'

function pad(num) {
    return ('0' + num).slice(-2)
}

const formatDuration = duration => {
    let minutes = Math.floor(duration / 60)
    let secs = Math.floor(duration % 60)
    minutes = minutes % 60

    return `${pad(minutes)}:${pad(secs)}`
}

const showPlaybookDate = uploadedAt =>
    new Date((uploadedAt?.seconds ?? uploadedAt) * 1000)
        .toString()
        .substring(4, 16)

const useStyles = makeStyles({
    title: {
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '13px',
        height: '36px',

        // Webkit only solution
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    userRow: {
        fontSize: '10px',
        height: '28px',
    },
    date: {
        color: '#78909c',
    },
    duration: {
        color: '#78909c',
        textAlign: 'right',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        flexDirection: 'row',
    },
    iconWatch: {
        height: '10px',
        paddingRight: '4px',
    },
})

export const PlaybookContent = ({
    title,
    tags,
    userName,
    duration,
    uploadedAt,
}) => {
    const classes = useStyles()

    return (
        <CardContent>
            <Typography className={classes.title} title={title || undefined}>
                {title}
            </Typography>

            <Box mb={2}>
                <Tags tags={tags} />
            </Box>

            <Grid container className={classes.userRow}>
                <Grid item xs={5}>
                    <Box mr={1}>{userName}</Box>
                </Grid>
                <Grid item xs className={classes.date}>
                    {showPlaybookDate(uploadedAt)}
                </Grid>
                {duration && (
                    <Grid item xs className={classes.duration}>
                        <img
                            className={classes.iconWatch}
                            src={AssetsService.getResourceURL(IconWatch)}
                            alt="watch"
                        />
                        {formatDuration(duration)}
                    </Grid>
                )}
            </Grid>
        </CardContent>
    )
}
