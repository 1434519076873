import { useCallback, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'

import { Box, ClickAwayListener, makeStyles } from '@material-ui/core'

import {
    PageNotifications,
    PagePlaybooks,
    PagePlaybook,
    PagePlaylist,
    PageInsights,
    PageSettings,
} from 'UI/Pages'

import {
    DraggableGButton,
    SidePanel,
    NotificationsContext,
} from 'UI/Components'
import {
    RequestPlaybookDialog,
    UserFeedbackDialog,
} from 'UI/Components/PlaybooksFooter/dialogs'

import { AppContext } from 'app/AppProvider'
import { initialUserState } from 'ducks/reducers'
import { RootState } from 'ducks/rootReducer'
import { setApps, setSpaces } from 'ducks/actions'
import { useTagsParser } from 'hooks'

import {
    getApps,
    getExtensionId,
    getFirebaseUser,
    getUserSpaces,
    logToMixpanel,
    markAllNotificationsAsRead,
    onSearchFromContext,
    onSearchFromKeyboard,
} from 'background/services'
import { getSelectionText } from 'services/utils'
import { mainZIndex } from 'app/zIndex'

const useStyles = makeStyles({
    extContainer: {
        position: 'fixed',
        top: 0,
        right: 0,
        transform: 'translateX(100%)',
        transition: 'transform 0.3s linear',
        zIndex: mainZIndex,
        height: '100vh',
    },
    active: {
        transform: 'translateX(0)',
    },
    innerContent: {
        height: '100%',
        width: 'max(1000px, 50vw)',
        maxWidth: '90vw',
        backdropFilter: 'blur(5px)',
        boxShadow: '-4px -1px 20px 0 rgba(0, 0, 0, 0.1)',
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        transition: 'width 0.3s linear',
    },
    notificationsWidth: {
        width: '413px',
    },
    settingsWidth: {
        width: '450px',
    },
    playContainer: {
        position: 'fixed',
        bottom: 0,
        right: 0,
        backdropFilter: 'blur(5px)',
        boxShadow: '-4px -1px 20px 0 rgba(0, 0, 0, 0.1)',
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        zIndex: mainZIndex,
    },
    tabsWrapper: {
        overflow: 'auto',
        flex: '1 1 0%',
        height: '100%',
    },
})

const TABS_MENU = {
    playbooks: 0,
    notifications: 1,
    insights: 2,
    settings: 3,
}

const DEFAULT_TAB_INDEX = TABS_MENU.playbooks

type Props = {
    isOpen: boolean
}

export const App = ({ isOpen }: Props) => {
    const classes = useStyles()
    const [extensionId, setExtensionId] = useState(null)

    const { active, playbookMode, playlistMode, firstInstall } =
        useContext(AppContext)
    const { notifications } = useContext(NotificationsContext)
    const [tab, setTab] = useState<number>(DEFAULT_TAB_INDEX)
    const changeTab = (tabIndex: number) => {
        const closedOnNotifications =
            active.isFalse && tab === TABS_MENU.notifications
        const leftNotificationsTab =
            tab === TABS_MENU.notifications &&
            tabIndex !== TABS_MENU.notifications
        const newNotificationsFound = notifications.find(
            ({ status }) => status === 'new'
        )

        if (
            (closedOnNotifications || leftNotificationsTab) &&
            newNotificationsFound
        ) {
            markAllNotificationsAsRead(null, {})
        }

        setTab(tabIndex)
    }

    const dispatch = useDispatch()

    useEffect(() => {
        getFirebaseUser(payload => {
            if (!payload) {
                changeTab(TABS_MENU.playbooks)
            }

            dispatch({
                type: 'userChanged',
                payload: payload || initialUserState,
            })
        })
        getUserSpaces(spaces => dispatch(setSpaces(spaces)), null)
    }, [dispatch])

    const openExtension = active.setTrue

    const roles = useSelector((state: RootState) => state.user.roles)
    const orgId = roles.o
    const isRequestPbModalOpen = useSelector(
        (state: RootState) => state.requestPb.isModalOpen
    )
    const isFeedbackModalOpen = useSelector(
        (state: RootState) => state.userFeedback.isModalOpen
    )

    useEffect(() => {
        if (isOpen && roles.o && !roles.n) {
            openExtension()
        }
    }, [isOpen, openExtension, roles])

    useEffect(() => {
        if (!active.isFalse) {
            return
        }

        const timeoutId = setTimeout(() => changeTab(DEFAULT_TAB_INDEX), 300)

        return () => clearTimeout(timeoutId)
    }, [active.isFalse])

    useEffect(() => {
        getExtensionId(id => setExtensionId(id), {})

        getApps(
            ({ data }) => {
                dispatch(setApps(data))
                logToMixpanel('page_loaded')
            },
            { orgId }
        )
    }, [dispatch, orgId])

    // Context menu and keyboard listeners
    const defaultFilters = useTagsParser()

    const [predefinedSearch, setPredefinedSearch] = useState('')
    const triggerSearch = useCallback(
        selection => {
            active.setTrue()
            changeTab(TABS_MENU.playbooks)
            setPredefinedSearch(selection)
        },
        [active]
    )
    useEffect(() => {
        onSearchFromContext(({ selection }) => {
            triggerSearch(selection)
            logToMixpanel('search_from_context_menu', selection)
        })

        onSearchFromKeyboard(() => {
            const selectedText = getSelectionText() || ''
            triggerSearch(selectedText)
            logToMixpanel('search_from_keyboard_shortcut', selectedText)
        })
    }, [triggerSearch])

    return (
        <>
            {/* We need an iframe with content.html in order to request access to the microphone which we use in background script.
            Once the access is provided, the extension will not ask about permission again */}
            {extensionId && (
                <iframe
                    title="content page"
                    style={{ display: 'none' }}
                    src={`chrome-extension://${extensionId}/content.html`}
                    allow={`camera chrome-extension://${extensionId};microphone chrome-extension://${extensionId}`}
                />
            )}

            <ClickAwayListener
                mouseEvent="onMouseDown"
                onClickAway={firstInstall.isTrue ? () => {} : active.setFalse}
            >
                <Box>
                    <Box
                        display="flex"
                        flexDirection="row"
                        height="100%"
                        className={clsx(classes.extContainer, {
                            [classes.active]: active.isTrue,
                        })}
                    >
                        <DraggableGButton
                            roles={roles}
                            onClick={() => {
                                logToMixpanel('guidde_button_clicked')
                                active.toggle()
                                if (playbookMode.isTrue) {
                                    playbookMode.setFalse()
                                }
                                if (playlistMode.isTrue) {
                                    playlistMode.setFalse()
                                }
                            }}
                            onSearchBtnClick={() =>
                                changeTab(DEFAULT_TAB_INDEX)
                            }
                        />
                        <Box
                            className={clsx(classes.innerContent, {
                                [classes.notificationsWidth]:
                                    tab === TABS_MENU.notifications,
                                [classes.settingsWidth]:
                                    tab === TABS_MENU.settings,
                            })}
                        >
                            <Box
                                display="flex"
                                flexDirection="row"
                                height="100%"
                            >
                                <Box className={classes.tabsWrapper}>
                                    {tab === TABS_MENU.playbooks && (
                                        <PagePlaybooks
                                            defaultFilters={defaultFilters}
                                            predefinedSearch={predefinedSearch}
                                        />
                                    )}
                                    {tab === TABS_MENU.notifications && (
                                        <PageNotifications />
                                    )}
                                    {tab === TABS_MENU.insights && (
                                        <PageInsights />
                                    )}
                                    {tab === TABS_MENU.settings && (
                                        <PageSettings />
                                    )}
                                </Box>
                                <SidePanel tab={tab} onSetTab={changeTab} />
                            </Box>
                        </Box>
                    </Box>

                    {/* Play playbook page */}
                    {playbookMode.isTrue && (
                        <Box className={classes.playContainer}>
                            <PagePlaybook />
                        </Box>
                    )}

                    {/* Play playlist page */}
                    {playlistMode.isTrue && (
                        <Box className={classes.playContainer}>
                            <PagePlaylist />
                        </Box>
                    )}

                    {isRequestPbModalOpen && <RequestPlaybookDialog />}
                    {isFeedbackModalOpen && <UserFeedbackDialog />}
                </Box>
            </ClickAwayListener>
        </>
    )
}
