import { useContext, useRef } from 'react'
import { useSelector } from 'react-redux'

import {
    Box,
    IconButton,
    makeStyles,
    MenuItem,
    ListItemIcon,
    ListItemText,
    Paper,
    ClickAwayListener,
    MenuList,
    Popper,
    Divider,
} from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import ShareIcon from '@material-ui/icons/Share'
import EditIcon from '@material-ui/icons/Edit'

import { logToMixpanel, openExternalLink } from 'background/services'
import { playbookToMixpanelProps } from 'services/mixpanel'
import { useBoolean } from 'hooks'
import { ShareDialog, CopyLinkedGif } from 'UI/Components'

import { RootState } from 'ducks/rootReducer'
import { AppContext } from 'app/AppProvider'

const useStyles = makeStyles({
    menuIcon: {
        color: 'black',
        position: 'absolute',
        top: 10,
        right: 10,
        padding: '2px',
        background: 'white',
        border: '1px solid gainsboro',
        '&:hover': {
            background: 'white',
        },
    },
    itemIcon: {
        minWidth: '35px',
        color: 'black',
    },
    container: {
        minWidth: '200px',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    divider: {
        margin: '10px 18px',
        background: '#cfd8dc',
    },
    copyGifBtn: {
        padding: 0,
        marginRight: '11px',
        pointerEvents: 'none',
    },
})

export const Menu = ({ playbook }) => {
    const classes = useStyles()

    const anchorRef = useRef<HTMLButtonElement | null>(null)
    const copyGifBtnRef = useRef<{ copyGifLink: () => void }>()

    const open = useBoolean()
    const { active } = useContext(AppContext)

    const { user, roles } = useSelector((state: RootState) => state.user)

    const isOwner = playbook?.creator_uid === user.uid
    const isAdmin = roles.a === 100
    const isContentManager = roles.a === 60
    const hasEditRights =
        (isAdmin || isContentManager) && roles.o === playbook.uploadedByOrgId

    const canEdit = isOwner || roles.s || hasEditRights

    const showShareDialog = useBoolean()
    const toggleShareDialog = () => showShareDialog.toggle()

    const {
        isPlaylist,
        isPublic,
        id: playbookId,
        gifUrl,
        screenshotUrl,
        visibility,
        title,
    } = playbook

    const thumbnail = gifUrl || screenshotUrl
    const isDisabled = visibility === 'Private'
    const gifColor = '#000'

    return (
        <>
            <Box className={classes.container}>
                <IconButton
                    className={classes.menuIcon}
                    ref={anchorRef}
                    onClick={event => {
                        event.preventDefault()
                        event.stopPropagation()

                        open.toggle()
                    }}
                >
                    <MoreVertIcon />
                </IconButton>

                <Popper
                    open={open.isTrue}
                    anchorEl={anchorRef?.current}
                    placement="bottom-start"
                    disablePortal
                    style={{ zIndex: 1 }}
                >
                    <ClickAwayListener onClickAway={open.setFalse}>
                        <Paper
                            style={{
                                width: '160px',
                            }}
                        >
                            <MenuList
                                autoFocusItem={false}
                                style={{ padding: 0 }}
                            >
                                <MenuItem
                                    style={{ pointerEvents: 'auto' }}
                                    onClick={event => {
                                        event.stopPropagation()

                                        toggleShareDialog()
                                        logToMixpanel(
                                            playbook.isPlaylist
                                                ? 'share_playlist_btn_clicked'
                                                : 'share_pb_btn_clicked',
                                            playbookToMixpanelProps(playbook)
                                        )
                                    }}
                                    title={`Share ${
                                        playbook.isPlaylist
                                            ? 'playlist'
                                            : 'playbook'
                                    }`}
                                >
                                    <ListItemIcon className={classes.itemIcon}>
                                        <ShareIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText primary="Share" />
                                </MenuItem>

                                <MenuItem
                                    style={{ pointerEvents: 'auto' }}
                                    onClick={() =>
                                        copyGifBtnRef.current?.copyGifLink()
                                    }
                                    title="Copy GIF"
                                >
                                    <CopyLinkedGif
                                        onCopyClick={() => {
                                            active.setFalse()
                                            showShareDialog.setFalse()
                                        }}
                                        ref={copyGifBtnRef}
                                        rootClass={classes.copyGifBtn}
                                        isPublic={isPublic}
                                        playbookId={playbookId}
                                        isPlaylist={isPlaylist}
                                        playbookTitle={title}
                                        playbookThumbnail={thumbnail}
                                        disabled={isDisabled}
                                        color={gifColor}
                                    />
                                    <ListItemText primary="Copy GIF" />
                                </MenuItem>

                                {canEdit && (
                                    <Box>
                                        <Divider
                                            variant="middle"
                                            className={classes.divider}
                                        />
                                        <MenuItem
                                            style={{ pointerEvents: 'auto' }}
                                            onClick={() => {
                                                openExternalLink(null, {
                                                    url: `playbooks${
                                                        playbook.isPlaylist
                                                            ? '/playlist'
                                                            : ''
                                                    }/${playbook.id}/edit`,
                                                })
                                            }}
                                            title="Edit"
                                        >
                                            <ListItemIcon
                                                className={classes.itemIcon}
                                            >
                                                <EditIcon fontSize="small" />
                                            </ListItemIcon>
                                            <ListItemText primary="Edit" />
                                        </MenuItem>
                                    </Box>
                                )}
                            </MenuList>
                        </Paper>
                    </ClickAwayListener>
                </Popper>
            </Box>
            {showShareDialog.isTrue && (
                <ShareDialog
                    isOpen
                    onClose={toggleShareDialog}
                    playbook={playbook}
                    title={`Share your ${
                        playbook.isPlaylist ? 'playlist' : 'playbook'
                    }`}
                    subtitle={`Invite people to view your ${
                        playbook.isPlaylist ? 'Playlist' : 'Playbook'
                    }`}
                />
            )}
        </>
    )
}
