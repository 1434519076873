import React from 'react'
import BlockIcon from '@material-ui/icons/Block'
import { Box, Typography } from '@material-ui/core'

export const NoData = () => {
    return (
        <Box
            mt={2}
            height="100%"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
        >
            <BlockIcon />
            <Typography>No data for this report</Typography>
        </Box>
    )
}
