import { useState } from 'react'
import { useSelector } from 'react-redux'

import { Box, makeStyles, Tab, Tabs } from '@material-ui/core'

import { RootState } from 'ducks/rootReducer'
import { TranscriptTab, InfoTab } from './tabs'

const useStyles = makeStyles({
    tab: {
        minWidth: '140px',
    },
})

const TabPanel = props => {
    const { children, value, index, ...other } = props

    return (
        <Box hidden={value !== index} {...other}>
            <Box p={3}>{children}</Box>
        </Box>
    )
}

type Props = {
    startTime?: number
}

export const SidePanel = ({ startTime }: Props) => {
    const classes = useStyles()
    const [tabValue, setTabValue] = useState(0)
    const handleTabChange = (_, newValue) => {
        setTabValue(newValue)
    }
    const { files, links, transcriptUrl } = useSelector(
        (state: RootState) => state.playbook
    )
    const isInfoVisible = !!(files?.length || links?.length)

    return (
        <Box p={2}>
            <Tabs
                value={tabValue}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                centered
                TabIndicatorProps={{
                    style: {
                        display: isInfoVisible ? 'block' : 'none',
                    },
                }}
            >
                <Tab
                    label="Transcript"
                    className={classes.tab}
                    disabled={!isInfoVisible}
                />
                {isInfoVisible && <Tab label="Info" className={classes.tab} />}
            </Tabs>
            <TabPanel value={tabValue} index={0}>
                <TranscriptTab
                    transcriptUrl={transcriptUrl}
                    startTime={startTime}
                />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
                <InfoTab
                    files={files}
                    links={links}
                    isInfoVisible={isInfoVisible}
                />
            </TabPanel>
        </Box>
    )
}
