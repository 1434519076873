import { ReactElement } from 'react'
import { isSDK, isExtension } from '../../services/environmentService'

type Props = {
    extComponent?: ReactElement | null
    sdkComponent?: ReactElement | null
}

export const DoubleComponent = ({
    extComponent = null,
    sdkComponent = null,
}: Props) => {
    if (isSDK) {
        return sdkComponent
    }

    if (isExtension) {
        return extComponent
    }

    return null
}

export const ExtensionOnly = ({ children }) => {
    return isExtension ? children : null
}

export const SDKOnly = ({ children }) => {
    return isSDK ? children : null
}
