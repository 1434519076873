import { createContext, useState, Context, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'

import {
    getPlaybook,
    getPlayMessage,
    onTriggerVideoPlayer,
} from 'background/services'
import { useBoolean } from 'hooks'
import { setPlaybook } from 'ducks/actions'

import { extensionOnly, isSDK, sdkOnly } from 'services/environmentService'
import { sendMessageToParentScript } from 'services/sdkServices'

type PlayerPosition = 'bottomRight' | 'center'

export const AppContext: Context<any> = createContext({
    firstInstall: {},
    active: {},
    playbookMode: {},
    openPlaybookMode: () => {},
    playlistMode: {},
    openPlaylistMode: () => {},
    startTime: null,
    setStartTime: () => {},
})

export const AppProvider = ({ isFirstInstall, children }) => {
    const dispatch = useDispatch()

    const active = useBoolean(isSDK)

    const playbookMode = useBoolean(false)
    const playlistMode = useBoolean()

    const firstInstall = useBoolean(isFirstInstall)

    const [startTime, setStartTime] = useState()

    const closeExtension = active.setFalse
    const setPlaybookMode = playbookMode.setTrue
    const setPlaylistMode = playlistMode.setTrue

    const openPlaybookMode = useCallback(
        (startTime = null, playerPosition?: PlayerPosition) => {
            setStartTime(startTime)
            closeExtension()
            setPlaybookMode()

            sdkOnly(() => {
                sendMessageToParentScript({
                    action: 'RESIZE_TO_PLAYER',
                    params: { playerPosition },
                })
            })()
        },
        [closeExtension, setPlaybookMode]
    )

    const openPlaylistMode = useCallback(
        (playerPosition?: PlayerPosition) => {
            closeExtension()
            setPlaylistMode()

            sdkOnly(() => {
                sendMessageToParentScript({
                    action: 'RESIZE_TO_PLAYER',
                    params: { playerPosition },
                })
            })()
        },
        [closeExtension, setPlaylistMode]
    )

    const contextValue = {
        firstInstall,
        active,
        playbookMode,
        openPlaybookMode,
        playlistMode,
        openPlaylistMode,
        startTime,
        setStartTime,
    }

    useEffect(() => {
        const openVideoPlayer = (payload, playerPosition?: PlayerPosition) => {
            if (!payload || (Array.isArray(payload) && payload.length === 0))
                return false

            dispatch(setPlaybook(payload))
            if (payload.isPlaylist) {
                openPlaylistMode(playerPosition)
            } else {
                openPlaybookMode(null, playerPosition)
            }
        }

        // Go and watch feature that allows to play playbook on external url
        extensionOnly(() => getPlayMessage(openVideoPlayer, {}))

        // Link replacement feature that opens extension video player on Guidde link click
        extensionOnly(() => onTriggerVideoPlayer(openVideoPlayer))

        sdkOnly(() => {
            window.addEventListener('message', event => {
                const { type, playbookId, position } = event?.data

                if (type === 'OPEN_PLAYBOOK') {
                    getPlaybook(
                        playbook => {
                            if (playbook) {
                                openVideoPlayer(playbook, position)
                            } else {
                                console.error(
                                    `GUIDDE SDK: Could not find a playbook with id ${playbookId}. Please, check if the playbook exists and is available in current sdk space.`
                                )
                            }
                        },
                        { playbookId }
                    )
                }
            })
        })()
    }, [openPlaybookMode, openPlaylistMode])

    return (
        <AppContext.Provider value={contextValue}>
            {children}
        </AppContext.Provider>
    )
}
