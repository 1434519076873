import React, { memo, useEffect, useState } from 'react'

import * as AssetsService from 'services/extServices'

import HelpfulIcon from 'assets/icons/helpful-reaction.svg'
import NotHelpfulIcon from 'assets/icons/not-helpful-reaction.svg'

import HelpfulYellowIcon from 'assets/icons/helpful-reaction-yellow.svg'
import NotHelpfulYellowIcon from 'assets/icons/not-helpful-reaction-yellow.svg'

import HelpfulSelectedIcon from 'assets/icons/helpful-selected-reaction.svg'
import NotHelpfulSelectedIcon from 'assets/icons/not-helpful-selected-reaction.svg'

import { Button, makeStyles, Box } from '@material-ui/core'

import { TypeUseBoolean, useBoolean } from 'hooks'

import { setPlaybookReview, getPlaybookUserReview } from 'background/services'

const useStyles = makeStyles(theme => ({
    label: {
        color: '#7f8c9a',
        lineHeight: 1,
        marginBottom: theme.spacing(2),
    },
    button: {
        padding: '3px 5px',
        fontSize: '12px !important',
        whiteSpace: 'nowrap',
        color: theme.palette.text.primary,
    },
    disabled: {
        color: `inherit !important`,
        '& img': {
            width: '22px !important',
            height: '22px !important',
        },
    },
    startIcon: {
        cursor: 'pointer',
        marginRight: '5px',
        transition: 'transform 0.2s ease 0s',
    },
}))

const notHelpfulReaction = 1
const helpfulReaction = 2

type Props = {
    showReactions: TypeUseBoolean
    playbookId: string
    showFinishStep: TypeUseBoolean
}

type PlaybookDataState = {
    reaction: number
    result?: number
}

const generateImg = (path: string): React.ReactNode => (
    <img
        style={{ width: '18px', height: '18px' }}
        src={AssetsService.getResourceURL(path)}
    />
)

export const VideoReactions = memo(
    ({ showReactions, playbookId, showFinishStep }: Props) => {
        const classes = useStyles()
        const helpfulHovered = useBoolean()
        const notHelpfulHovered = useBoolean()

        const [playbookData, setPlaybookData] = useState<PlaybookDataState>({
            reaction: 0,
        })

        const getUserReview = () => {
            getPlaybookUserReview(setPlaybookData, { playbookId })
        }

        useEffect(() => {
            getUserReview()
        }, [showFinishStep])

        const handleClick = () => {
            setPlaybookReview(showFinishStep.setTrue, {
                playbookId,
                reaction: helpfulReaction,
            })
        }

        const isActive = value => playbookData?.reaction === value

        const getImg = (
            hovered: TypeUseBoolean,
            type: number,
            imgHovered: string,
            imgSelected: string,
            img: string
        ): React.ReactNode => {
            if (hovered.isTrue) return generateImg(imgHovered)

            return generateImg(isActive(type) ? imgSelected : img)
        }

        return (
            <Box display="flex">
                <Button
                    variant="text"
                    className={classes.button}
                    onClick={handleClick}
                    disabled={isActive(helpfulReaction)}
                    size="small"
                    classes={{
                        disabled: classes.disabled,
                        startIcon: classes.startIcon,
                    }}
                    onMouseMove={helpfulHovered.setTrue}
                    onMouseLeave={helpfulHovered.setFalse}
                    startIcon={getImg(
                        helpfulHovered,
                        helpfulReaction,
                        HelpfulYellowIcon,
                        HelpfulSelectedIcon,
                        HelpfulIcon
                    )}
                >
                    Helpful
                </Button>

                <Button
                    variant="text"
                    size="small"
                    className={classes.button}
                    onClick={showReactions.setTrue}
                    disabled={isActive(notHelpfulReaction)}
                    classes={{
                        disabled: classes.disabled,
                        startIcon: classes.startIcon,
                    }}
                    onMouseMove={notHelpfulHovered.setTrue}
                    onMouseLeave={notHelpfulHovered.setFalse}
                    startIcon={getImg(
                        notHelpfulHovered,
                        notHelpfulReaction,
                        NotHelpfulYellowIcon,
                        NotHelpfulSelectedIcon,
                        NotHelpfulIcon
                    )}
                >
                    Not helpful
                </Button>
            </Box>
        )
    }
)
