import { useState, useCallback, useMemo } from 'react'

export type TypeUseBoolean = {
    isTrue: boolean
    isFalse: boolean
    setTrue: () => void
    setFalse: () => void
    toggle: () => void
    set: (val: boolean) => void
}

export const useBoolean = (initialValue?: boolean): TypeUseBoolean => {
    const [isTrue, setIsTrue] = useState<boolean>(initialValue ?? false)

    const setTrue = useCallback(() => {
        setIsTrue(true)
    }, [])

    const setFalse = useCallback(() => {
        setIsTrue(false)
    }, [])

    const toggle = useCallback(() => {
        setIsTrue(prevIsTrue => !prevIsTrue)
    }, [])

    const set = useCallback(val => {
        setIsTrue(val)
    }, [])

    return useMemo(() => {
        return {
            isTrue,
            isFalse: !isTrue,
            setTrue,
            setFalse,
            toggle,
            set,
        }
    }, [isTrue, set, setFalse, setTrue, toggle])
}
