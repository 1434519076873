import { Autocomplete } from '@material-ui/lab'
import {
    TextField,
    makeStyles,
    Box,
    Typography,
    useTheme,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { useBoolean } from 'hooks'

type Option<T extends string> = {
    value: T
    label: string
    [propName: string]: string
}

type Props<T extends string> = {
    autoFocus?: boolean
    value: T
    onChange: (value: T) => void
    options: Array<Option<T>>
    defaultOption?: Option<T>
    loading?: boolean
    defaultValue?: string
    disabled?: boolean
    labelIcon?: any
    groupBy?: (option: Option<T>) => string
    id?: string
}

const useStyles = makeStyles(theme => ({
    closedContainer: {
        cursor: 'pointer',
    },
    popper: {
        position: 'absolute',
        top: '32px',
    },
    paper: {
        backgroundColor: '#fff',
        borderRadius: '4px',
        border: '1px solid rgb(220, 224, 228)',
        boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.13)',
    },
    listbox: {
        padding: 0,
    },
    option: {
        padding: theme.spacing(1, 1.5),
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(20),
    },
    inputBase: {
        flexDirection: 'row',
    },
}))

export const FilterSelectSingle = <T extends string>({
    autoFocus = false,
    value,
    onChange,
    options,
    defaultOption,
    loading = false,
    defaultValue,
    disabled = false,
    labelIcon,
    groupBy,
    id,
}: Props<T>) => {
    const theme = useTheme()
    const classes = useStyles()

    const allOptions = defaultOption ? [defaultOption, ...options] : options

    const foundOption = allOptions.find(o => o.value === value) ?? null

    const open = useBoolean(autoFocus)

    if (foundOption === null) {
        if (defaultOption) {
            onChange(defaultOption?.value)
        }
        return null
    }

    if (open.isFalse) {
        return (
            <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                className={classes.closedContainer}
                tabIndex={disabled ? undefined : 0}
                onFocus={disabled ? undefined : open.setTrue}
                id={id}
            >
                <Typography
                    style={{
                        display: 'flex',
                        color:
                            foundOption.value === defaultOption?.value ||
                            foundOption.value === defaultValue
                                ? theme.palette.text.primary
                                : theme.palette.primary.main,
                    }}
                >
                    {labelIcon}
                    {foundOption.label}
                </Typography>
                {!disabled && <ExpandMoreIcon />}
            </Box>
        )
    }

    return (
        <Autocomplete
            open
            id={id}
            innerRef={node => {
                if (node) {
                    const input = node.getElementsByTagName('input')[0]
                    setImmediate(() => {
                        input.select()
                    })
                }
            }}
            autoHighlight
            multiple={false}
            loading={loading}
            style={{ width: 200 }}
            value={foundOption}
            blurOnSelect
            onChange={(_event, newValue) => {
                if (newValue !== null) {
                    open.setFalse()
                    onChange(newValue.value)
                }
            }}
            onBlur={open.setFalse}
            popupIcon={null}
            options={allOptions}
            groupBy={groupBy}
            disablePortal
            renderInput={({ InputProps, ...params }) => (
                <TextField
                    {...params}
                    InputProps={{
                        ...InputProps,
                        classes: { formControl: classes.inputBase },
                        disableUnderline: true,
                        startAdornment: <SearchIcon />,
                    }}
                    autoFocus={autoFocus}
                />
            )}
            getOptionLabel={o => o.label}
            classes={{
                popper: classes.popper,
                paper: classes.paper,
                listbox: classes.listbox,
                option: classes.option,
            }}
        />
    )
}
