import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles'
import { Divider, FormControlLabel, Radio, Typography } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows'
import Box from '@material-ui/core/Box'

import { useBoolean, useMicrophone } from 'hooks'
import { MicSetting } from 'UI/Components'

import {
    getFromExtLocalStorage,
    setToExtLocalStorage,
} from 'services/extServices'
import { LINK_REPLACER_FEATURE, WEBCAM_SETTING } from 'background/constants'
import { RootState } from 'ducks/rootReducer'

const useStyles = makeStyles(theme => ({
    main: {
        padding: theme.spacing(3),
        backgroundColor: 'white',
    },
    content: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    label: {
        fontSize: '14px',
    },
}))

const WEBCAM_OPTIONS = [
    { value: 'mini', label: 'Screen & Camera' },
    { value: 'disabled', label: 'Screen only' },
    { value: 'maxi', label: 'Camera only' },
]

export const PageSettings = () => {
    const replaceLinks = useBoolean(false)
    const [webcamSetting, setWebcamSetting] = useState(WEBCAM_OPTIONS[1].value)
    const { chosenMic, micOptions, onMicChange } = useMicrophone()
    const classes = useStyles()
    const { status: recordingStatus } = useSelector(
        (state: RootState) => state.screenCapture
    )

    useEffect(() => {
        getFromExtLocalStorage(
            [LINK_REPLACER_FEATURE, WEBCAM_SETTING],
            result => {
                const enableLinkReplacer = result?.[LINK_REPLACER_FEATURE]
                replaceLinks.set(Boolean(enableLinkReplacer))

                const webcamSettingSaved = result?.[WEBCAM_SETTING]

                if (webcamSettingSaved) {
                    setWebcamSetting(webcamSettingSaved)
                }
            }
        )
    }, [])

    const toggleLinkReplacer = newState => {
        setToExtLocalStorage(LINK_REPLACER_FEATURE, newState, () => {
            replaceLinks.set(newState)
        })
    }

    const changeWebcamSetting = newValue => {
        setToExtLocalStorage(WEBCAM_SETTING, newValue, () => {
            setWebcamSetting(newValue)
        })
    }

    return (
        <div className={classes.main}>
            <Typography variant="subtitle1">
                <b>Recording Settings</b>
            </Typography>

            <div className={classes.content}>
                {recordingStatus === 'recording' ? (
                    <Typography>
                        You can not change settings during a recording
                    </Typography>
                ) : (
                    <>
                        <MicSetting
                            chosenMic={chosenMic}
                            micOptions={micOptions}
                            onMicChange={onMicChange}
                        />

                        <Box display="flex" alignItems="center" mt={2}>
                            <Box mr={2}>
                                <DesktopWindowsIcon />
                            </Box>
                            <TextField
                                color="secondary"
                                select
                                value={webcamSetting}
                                onChange={e =>
                                    changeWebcamSetting(e.target.value)
                                }
                                label="Choose what to record"
                                InputLabelProps={{ shrink: true }}
                                style={{ width: '100%' }}
                            >
                                {WEBCAM_OPTIONS.map(({ value, label }) => (
                                    <MenuItem key={value} value={value}>
                                        {label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Box>
                    </>
                )}
            </div>

            <Divider className={classes.content} />

            <Typography variant="subtitle1">
                <b>Auto Play Settings</b>
            </Typography>

            <div className={classes.content}>
                <FormControlLabel
                    control={
                        <Radio
                            checked={replaceLinks.isFalse}
                            onChange={() => toggleLinkReplacer(false)}
                        />
                    }
                    label="Automatically play a Guidde video link in a new tab"
                    classes={{ label: classes.label }}
                />
                <FormControlLabel
                    control={
                        <Radio
                            checked={replaceLinks.isTrue}
                            onChange={() => toggleLinkReplacer(true)}
                        />
                    }
                    label="Automatically play a Guidde video link on app"
                    classes={{ label: classes.label }}
                />
            </div>
        </div>
    )
}
