import React from 'react'
import {
    Avatar,
    Grid,
    GridSize,
    makeStyles,
    Typography,
} from '@material-ui/core'
import clsx from 'clsx'

type StyleProps = {
    lW: number
    iconSizes: {
        width: string
        height: string
    }
}

const useStyles = makeStyles(theme => ({
    wrapper: {
        marginBottom: theme.spacing(1),
        height: '65px',
    },
    line: {
        width: ({ lW }: StyleProps) => `${lW}%`,
        height: '7px',
        backgroundColor: '#73008d',
        borderRadius: '2px',
    },
    lineTitle: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        width: '100%',
    },
    iconWrap: {
        display: 'flex',
    },
    icon: {
        width: ({ iconSizes }: StyleProps) => iconSizes.width,
        height: ({ iconSizes }: StyleProps) => iconSizes.height,
        display: 'flex',
        margin: 0,
    },
    vertCenter: {
        display: 'flex',
        alignItems: 'center',
    },
    horizCenter: {
        justifyContent: 'center',
    },
    hovering: {
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
}))

type LineChartProps = {
    Icon?: any
    iconSizes?: {
        width: string
        height: string
    }
    title: string
    value: number
    maxValue: number
    children?: React.ReactChild
    columns?: {
        left: GridSize
        center: GridSize
        right: GridSize
    }
    onClick?: () => void
}

export const LineChart = ({
    Icon = Avatar,
    iconSizes = {
        width: '24px',
        height: '24px',
    },
    title,
    value,
    maxValue,
    children,
    columns = {
        left: 1,
        center: 8,
        right: 2,
    },
    onClick,
}: LineChartProps) => {
    const lineWidth = Math.round((value / maxValue) * 100)
    const classes = useStyles({ lW: lineWidth, iconSizes })

    return (
        <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
            className={clsx(classes.wrapper, onClick && classes.hovering)}
            onClick={onClick}
        >
            <Grid item xs={columns.left} className={classes.vertCenter}>
                <Icon className={classes.icon} />
            </Grid>
            <Grid item xs={columns.center}>
                {children || (
                    <>
                        <Typography className={classes.lineTitle}>
                            {title}
                        </Typography>
                        <div className={classes.line} />
                    </>
                )}
            </Grid>
            <Grid
                item
                xs={columns.right}
                className={[classes.vertCenter, classes.horizCenter].join(' ')}
            >
                <Typography>
                    <b>{value}</b>
                </Typography>
            </Grid>
        </Grid>
    )
}
