import { useMemo, useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'

import { Form, Formik } from 'formik'
import * as yup from 'yup'

import {
    makeStyles,
    Box,
    Typography,
    Button,
    Grid,
    LinearProgress,
} from '@material-ui/core'

import { ConnectedInput } from 'UI/Components'
import { TrackLinksTable } from './TrackLinksTable'

import { useBoolean, useNotification } from 'hooks'

import { RootState } from 'ducks/rootReducer'
import { PlaybookType } from 'types'

import {
    createTrackingLink,
    getTrackingLinks,
    logToMixpanel,
} from 'background/services'
import { TrackingLinkType } from 'background/trackingLinks'
import { uuid } from 'services/utils'

type TrackingLinkToMixpanel = Omit<TrackingLinkType, 'name' | 'domain'> & {
    track_name: string
    track_domain: string
    utm_campaign: string
}

const useStyles = makeStyles(theme => ({
    publicViewContainer: {
        padding: theme.spacing(2),
        width: '100%',
        maxHeight: '62vh',
        overflow: 'auto',
        borderRadius: '4px',
        position: 'absolute',
        top: '80px',
        background: 'white',
    },
    title: {
        textAlign: 'center',
        fontWeight: 'bold',
    },
    text: {
        marginBlock: theme.spacing(),
        fontSize: 12,
    },
    inputsForm: {
        marginTop: theme.spacing(),
    },
    submitBtn: {
        float: 'right',
    },
}))

const validateStringsArray = (stringsArray: string[] = []) => {
    return stringsArray.length > 0
        ? new RegExp(`^(?!(${stringsArray.join('|')})$)`)
        : new RegExp('.*')
}

type Props = {
    playbook: PlaybookType
    onCopyClick?: () => void
}

export const TrackLinkBody = ({ playbook, onCopyClick }: Props) => {
    const { id: playbookId } = playbook

    const linksLoading = useBoolean()
    const classes = useStyles()

    const {
        user: { uid },
        roles: { o: orgId },
    } = useSelector((state: RootState) => state.user)

    const { showErrorNotification, showSuccessNotification } = useNotification()
    const [trackingLinks, setTrackingLinks] = useState<
        Record<string, TrackingLinkType>
    >({})

    const fetchLinks = useCallback(
        ({ showLoader } = { showLoader: true }) => {
            if (showLoader) {
                linksLoading.setTrue()
            }

            getTrackingLinks(
                links => {
                    linksLoading.setFalse()
                    setTrackingLinks(links)
                },
                { orgId, playbookId, uid }
            )
        },
        // linksLoading dependency is unnecessary here
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [orgId, playbookId, uid]
    )

    useEffect(fetchLinks, [fetchLinks])

    const companyData = useMemo(() => {
        const trackingLinksValues = Object.values(trackingLinks).filter(Boolean)

        return {
            currentCompanyNames: trackingLinksValues.map(tLink => tLink.name),
            currentCompanyDomains: trackingLinksValues.map(
                tLink => tLink.domain
            ),
        }
    }, [trackingLinks])

    const validateCompanyNames = validateStringsArray(
        companyData.currentCompanyNames
    )

    const handleSubmit = (
        {
            companyName,
            emailDomain,
        }: {
            companyName: string
            emailDomain: string
        },
        { resetForm }: { resetForm: () => void }
    ) => {
        const id = uuid()
        const trackingLink = {
            id,
            uid,
            orgId,
            playbookId,
            name: companyName,
            domain: emailDomain,
            createdAt: Date.now(),
        }

        const errorHandler = (err: { code?: string }) => {
            console.error(err)
            if (err.code === 'permission-denied') {
                return showErrorNotification(
                    'You don’t have permissions to track this Playbook.'
                )
            }
            return showErrorNotification(
                'Something went wrong. Please try again later'
            )
        }

        const callback = error => {
            if (error) {
                return errorHandler(error)
            }

            fetchLinks({ showLoader: false })
            resetForm?.()

            const { name, domain, ...rest } = trackingLink
            const trackingLinkToMixpanel: TrackingLinkToMixpanel = {
                ...rest,
                track_domain: domain,
                track_name: name,
                utm_campaign: name,
            }

            logToMixpanel('add_tracking_link', trackingLinkToMixpanel)
            showSuccessNotification('New link was created.')
        }

        return createTrackingLink(callback, {
            id: trackingLink.id,
            data: trackingLink,
        })
    }

    if (linksLoading.isTrue) return <LinearProgress />

    return (
        <>
            <Box className={classes.publicViewContainer}>
                <Box
                    position="relative"
                    flexDirection="column"
                    alignItems="center"
                >
                    <Typography className={classes.title}>
                        Get tracking link
                    </Typography>
                    <Typography className={classes.text}>
                        Share tracking links to help you track the activity when
                        users watch public content
                    </Typography>
                </Box>
                <Box
                    display="flex"
                    alignItems="center"
                    mb={2}
                    flexDirection="column"
                >
                    <Formik
                        onSubmit={handleSubmit}
                        initialValues={{
                            companyName: '',
                            emailDomain: '',
                        }}
                        validationSchema={yup.object().shape({
                            companyName: yup
                                .string()
                                .min(1)
                                .required('Field is required')
                                .matches(
                                    validateCompanyNames,
                                    `Company name already exists`
                                ),
                            emailDomain: yup
                                .string()
                                .matches(
                                    new RegExp(
                                        '(^(([A-Za-z0-9-]{0,61})\\.)+[A-Za-z][A-Za-z]{0,61}[A-Za-z])$'
                                    ),
                                    'Invalid domain'
                                )
                                .nullable(),
                        })}
                    >
                        {({ dirty, isValid }) => (
                            <Form>
                                <Grid
                                    container
                                    spacing={2}
                                    alignItems="flex-start"
                                    className={classes.inputsForm}
                                >
                                    <Grid item xs={4} style={{ height: 70 }}>
                                        <ConnectedInput
                                            fullWidth
                                            placeholder="Company name"
                                            name="companyName"
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <ConnectedInput
                                            fullWidth
                                            placeholder="Email Domain"
                                            name="emailDomain"
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Button
                                            disabled={dirty && !isValid}
                                            type="submit"
                                            className={classes.submitBtn}
                                        >
                                            Add link
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Box>

                <TrackLinksTable
                    onCopyClick={onCopyClick}
                    onLinkDelete={() => fetchLinks({ showLoader: false })}
                    playbook={playbook}
                    trackingLinks={Object.values(trackingLinks || {}).filter(
                        Boolean
                    )}
                />
            </Box>
        </>
    )
}
