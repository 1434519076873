import { useState, useEffect, memo, useContext } from 'react'
import { useSelector } from 'react-redux'

import { Formik, Form } from 'formik'
import * as yup from 'yup'

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
    LinearProgress,
    makeStyles,
    Grid,
    Box,
    Theme,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import {
    ConnectedAutocomplete,
    ConnectedInput,
} from 'UI/Components/ConnectedFields'
import { ShareableLink } from './ShareableLink'
import { TrackShareableLink } from './TrackShareableLink'

import { sharePlaybook, logToMixpanel, getOrgUsers } from 'background/services'

import { useBoolean } from 'hooks'

import { playbookToMixpanelProps } from 'services/mixpanel'
import { option } from 'services/utils'

import { RootState } from 'ducks/rootReducer'
import { PlaybookType } from 'types'
import { AppContext } from 'app/AppProvider'

type PlaybookPayload = {
    action: string
    data: { email: null | string; notes?: null | string }
    playbookId: string
}

const handleSharePlaybook = (payload: PlaybookPayload, onSubmit) =>
    sharePlaybook(() => onSubmit(), payload)

const useStyles = makeStyles<Theme, { showPublishDialog: boolean }>(theme => ({
    dialogPaper: {
        overflow: 'visible !important',
        // ShareableLink height is 100px, If I'll move general dialog
        // on 50px to the top all dialogs will be centered
        transform: 'translateY(-50px)',
        marginTop: ({ showPublishDialog }) =>
            showPublishDialog ? 'unset' : -300,
        '& *': {
            boxSizing: 'border-box',
        },
    },
    title: {
        textAlign: 'center',
        fontWeight: theme.typography.fontWeightBold as number,
    },
    closeButton: {
        position: 'absolute',
        right: 0,
        top: 0,
        padding: theme.spacing(0.5),
        margin: theme.spacing(0.5),
    },
    actions: {
        padding: theme.spacing(2),
        justifyContent: 'center',
    },
}))

type FormikValues = {
    notes: string | null
    email: Array<{ value: string; label: string }>
}
type Props = {
    isOpen: boolean
    onClose: () => void
    playbook: PlaybookType
    title: string
    subtitle: string
}
type OrgUsersType = Array<{ email: string; displayName: string }>

export const ShareDialog = memo(
    ({ isOpen, onClose, playbook, title, subtitle }: Props) => {
        const publishDialog = useBoolean(true)
        const loading = useBoolean()

        const showPublishDialog = publishDialog.isTrue
        const classes = useStyles({ showPublishDialog })
        const { active } = useContext(AppContext)

        const [orgUsers, setOrgUsers] = useState<OrgUsersType>([])

        const orgId = useSelector((state: RootState) => state.user.roles.o)

        useEffect(() => {
            getOrgUsers(setOrgUsers, {
                orgId,
            })
        }, [orgId])

        const emailOptions = orgUsers
            ?.map(user => option(user.email, user.displayName || user.email))
            .sort((a, b) =>
                a?.label?.toLowerCase() > b?.label?.toLowerCase() ? 1 : -1
            )

        const onCopyClick = () => {
            active.setFalse()
            onClose()
        }

        return (
            <Dialog
                open={isOpen}
                onClose={onClose}
                fullWidth={true}
                maxWidth="sm"
                classes={{
                    paper: classes.dialogPaper,
                }}
            >
                {loading.isTrue && <LinearProgress />}
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>

                {showPublishDialog ? (
                    <>
                        <Formik
                            onSubmit={({ notes, email }: FormikValues) => {
                                const payload = {
                                    action: 'share',
                                    playbookId: playbook.id,
                                    data: {
                                        notes,
                                        email: email
                                            .map(it => it.value)
                                            .join(', '),
                                    },
                                }

                                loading.setTrue()

                                handleSharePlaybook(payload, () => {
                                    loading.setFalse()
                                    onClose()
                                    logToMixpanel(
                                        'share_playbook',
                                        playbookToMixpanelProps(playbook)
                                    )
                                })
                            }}
                            initialValues={{
                                email: [],
                                notes: null,
                            }}
                            validationSchema={yup.object().shape({
                                email: yup
                                    .array()
                                    .min(1, 'Field is required')
                                    .nullable(),
                            })}
                        >
                            {() => (
                                <Form>
                                    <DialogTitle>
                                        <Typography className={classes.title}>
                                            {title}
                                        </Typography>
                                    </DialogTitle>
                                    <DialogContent>
                                        <Box mb={2}>
                                            <Typography variant="body1">
                                                {subtitle}
                                            </Typography>
                                        </Box>
                                        <Box mb={1}>
                                            <Grid
                                                container
                                                alignItems="center"
                                                style={{ flexDirection: 'row' }}
                                            >
                                                <Grid
                                                    item
                                                    xs={3}
                                                    style={{
                                                        padding: '6px 0 7px',
                                                    }}
                                                >
                                                    <Typography>
                                                        <b>Email</b>
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <ConnectedAutocomplete
                                                        options={emailOptions}
                                                        name="email"
                                                        freeSolo={true}
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box mb={1}>
                                            <Grid
                                                container
                                                style={{ flexDirection: 'row' }}
                                            >
                                                <Grid
                                                    item
                                                    xs={3}
                                                    style={{
                                                        padding: '6px 0 7px',
                                                    }}
                                                >
                                                    <Typography>
                                                        <b>Notes</b>
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <ConnectedInput
                                                        name="notes"
                                                        multiline={true}
                                                        placeholder="Add a note to the Playbook"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </DialogContent>
                                    <DialogActions className={classes.actions}>
                                        <Button
                                            size="medium"
                                            color="default"
                                            type="button"
                                            onClick={onClose}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            size="medium"
                                            type="submit"
                                            disabled={loading.isTrue}
                                        >
                                            Share
                                        </Button>
                                    </DialogActions>
                                </Form>
                            )}
                        </Formik>

                        <ShareableLink
                            playbook={playbook}
                            onClose={publishDialog.setFalse}
                            disabled={true}
                            onCopyClick={onCopyClick}
                        />
                    </>
                ) : (
                    <TrackShareableLink
                        playbook={playbook}
                        onCopyClick={onCopyClick}
                        onClose={publishDialog.setTrue}
                    />
                )}
            </Dialog>
        )
    }
)
