import { useContext, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
    LinearProgress,
    makeStyles,
    Divider,
    TextField,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/Delete'
import MenuItem from '@material-ui/core/MenuItem'

import { VideoRecorder } from 'UI/Components/VideoRecorder'

import {
    sendFeedback,
    logToMixpanel,
    deleteRecordings,
} from 'background/services'
import { useBoolean, useNotification } from 'hooks'
import { RootState } from 'ducks/rootReducer'
import {
    clearUserFeedbackModal,
    hideUserFeedbackModal,
    openUserFeedbackModal,
    overWriteUserFeedbackFields,
} from 'ducks/actions'

import { AppContext } from 'app/AppProvider'
import { Recording, RecordingPathE } from 'types/recording'
import { getDialogSharedStyles, REQUEST_TYPES } from './shared'
import { ExtensionOnly } from 'UI/Components/EnvComponents'

type UserFeedbackRequest = {
    subject: string
    originalURL: string
    requestType: string
    recordings?: Recording[]
}

const useStyles = makeStyles(getDialogSharedStyles)

export const UserFeedbackDialog = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const loading = useBoolean()
    const { showSuccessNotification, showErrorNotification } = useNotification()
    const { active: extensionOpened } = useContext(AppContext)
    const openDialogRef = useRef<typeof openUserFeedbackModal | null>(null)
    const userFeedbackState = useSelector(
        (state: RootState) => state.userFeedback
    )

    const { subject, recordings, requestType, isModalOpen } = userFeedbackState

    const onClose = (deleteFromStorage = false) => {
        if (deleteFromStorage) {
            deleteRecordings(null, {
                path: RecordingPathE.FEEDBACK,
                recordings,
            })
        }
        openDialogRef.current = null
        dispatch(clearUserFeedbackModal())
    }

    const changeField = (field: string, value: any) => {
        dispatch(overWriteUserFeedbackFields({ [field]: value }))
    }

    const onSubmit = () => {
        const payload: UserFeedbackRequest = {
            subject: subject.trim(),
            requestType,
            originalURL: window.location.href,
            ...(recordings.length ? { recordings } : {}),
        }

        loading.setTrue()

        sendFeedback(res => {
            logToMixpanel('user_feedback_sent', payload)
            loading.setFalse()

            if (res.error) {
                showErrorNotification('Something went wrong. Try again later.')
                return
            }

            showSuccessNotification(
                'Thank you! Your feedback was successfully sent.'
            )
            onClose()
        }, payload)
    }
    const isSubjectInvalid = !subject.trim()

    return (
        <Dialog
            open={isModalOpen}
            fullWidth
            onClose={() => onClose(true)}
            maxWidth="md"
        >
            {loading.isTrue && <LinearProgress />}
            <IconButton
                className={classes.closeButton}
                onClick={() => onClose(true)}
            >
                <CloseIcon />
            </IconButton>

            <DialogTitle classes={{ root: classes.titleWrapper }}>
                <Typography className={classes.title}>
                    Give feedback to Guidde
                </Typography>
                <Divider />
            </DialogTitle>
            <DialogContent className={classes.content}>
                <TextField
                    fullWidth
                    color="secondary"
                    value={subject}
                    error={isSubjectInvalid}
                    onChange={e => changeField('subject', e.target.value)}
                    label="Subject"
                    InputLabelProps={{
                        shrink: true,
                        required: true,
                        classes: { root: classes.fieldLabel },
                    }}
                />
                <TextField
                    select
                    fullWidth
                    value={requestType}
                    color="secondary"
                    onChange={e => changeField('requestType', e.target.value)}
                    label="Request Type"
                    InputLabelProps={{
                        shrink: true,
                        classes: { root: classes.fieldLabel },
                    }}
                >
                    {REQUEST_TYPES.map(({ value, label }) => (
                        <MenuItem key={value} value={value}>
                            {label}
                        </MenuItem>
                    ))}
                </TextField>

                <ExtensionOnly>
                    <>
                        <Typography
                            variant="subtitle2"
                            className={classes.recordingCTA}
                        >
                            Add a video recording to your feedback (optional)
                        </Typography>
                        {recordings.map(recording => (
                            <iframe
                                key={recording.uploadId}
                                title="Request Recording"
                                src={recording.url}
                                className={classes.iframe}
                            />
                        ))}
                        <div className={classes.panel}>
                            {recordings.length ? (
                                <Button
                                    variant="text"
                                    color="default"
                                    size="small"
                                    className={classes.btn}
                                    onClick={() => {
                                        changeField('recordings', [])
                                        deleteRecordings(null, {
                                            path: RecordingPathE.FEEDBACK,
                                            recordings,
                                        })
                                    }}
                                >
                                    <DeleteIcon />
                                    <Typography>
                                        Delete recording
                                        {recordings.length > 1 && 's'}
                                    </Typography>
                                </Button>
                            ) : (
                                <>
                                    <h1>test</h1>
                                    <VideoRecorder
                                        customCTA={({ onClick }) => (
                                            <Button
                                                variant="text"
                                                className={classes.btn}
                                                onClick={onClick}
                                            >
                                                <div
                                                    className={classes.recorder}
                                                />
                                                <Typography>
                                                    New recording
                                                </Typography>
                                            </Button>
                                        )}
                                        recordingType="feedbackRecordings"
                                        onStart={() => {
                                            extensionOpened.setFalse()
                                            dispatch(hideUserFeedbackModal())
                                            openDialogRef.current =
                                                openUserFeedbackModal
                                        }}
                                        onRecordingCancelled={() => {
                                            if (!openDialogRef.current) return

                                            extensionOpened.setTrue()
                                            dispatch(openDialogRef.current())
                                            openDialogRef.current = null
                                        }}
                                        onInfoReceived={feedbackRecording => {
                                            extensionOpened.setTrue()
                                            dispatch(
                                                openUserFeedbackModal({
                                                    recordings: [
                                                        feedbackRecording,
                                                    ],
                                                })
                                            )
                                            openDialogRef.current = null
                                        }}
                                    />
                                </>
                            )}
                        </div>
                    </>
                </ExtensionOnly>
            </DialogContent>
            <DialogActions className={classes.actions}>
                <Button
                    size="medium"
                    type="submit"
                    disabled={loading.isTrue || isSubjectInvalid}
                    onClick={onSubmit}
                >
                    Send feedback
                </Button>
            </DialogActions>
        </Dialog>
    )
}
