import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Typography } from '@material-ui/core'

import { MicSetting } from 'UI/Components/MicSetting'

const useStyles = makeStyles(theme => ({
    title: {
        textAlign: 'center',
        padding: theme.spacing(1.5),
        '& h2': {
            color: theme.palette.text.primary,
        },
    },
    actions: {
        display: 'flex',
        justifyContent: 'center',
        '& > *': {
            marginRight: theme.spacing(0.5),
        },
    },
    description: {
        maxWidth: '400px',
        fontSize: '13px',
        fontWeight: 300,
        color: '#686868',
        marginBottom: theme.spacing(3),
    },
}))

export const RecordingSettingsDialog = ({
    isOpen,
    onClose,
    startCapture,
    micSettings,
}) => {
    const classes = useStyles()
    const { chosenMic, micOptions, onMicChange } = micSettings

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle className={classes.title}>
                Recording Settings
            </DialogTitle>
            <DialogContent>
                <Typography className={classes.description}>
                    Which microphone should we use by default?
                    <br />
                    You can always change your choice in Settings.
                </Typography>

                <MicSetting
                    chosenMic={chosenMic}
                    micOptions={micOptions}
                    onMicChange={onMicChange}
                />
            </DialogContent>
            <DialogActions className={classes.actions}>
                <Button onClick={onClose} color="default">
                    Cancel
                </Button>
                <Button
                    disabled={!chosenMic}
                    onClick={() => startCapture(chosenMic)}
                    color="primary"
                >
                    Start recording
                </Button>
            </DialogActions>
        </Dialog>
    )
}
