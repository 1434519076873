import { useCallback, useEffect, useMemo, useState } from 'react'

import { getAvailableDevices } from 'background/services'
import { PREFFERRED_MIC_SETTING } from 'background/constants'
import * as EXT_SERVICES from 'services/extServices'

const MUTE_OPTION = {
    deviceId: 'mute',
    label: 'Mute Microphone',
}

export const useMicrophone = () => {
    const [chosenMic, setChosenMic] = useState<string>('')
    const [micOptions, setMicOptions] = useState<
        { deviceId: string; label: string }[]
    >([])

    const fetchMics = useCallback(
        () =>
            new Promise<string>(resolve => {
                // Fetch mics from background, otherwise it will use host page mic permissions
                getAvailableDevices(devices => {
                    EXT_SERVICES.getFromExtLocalStorage(
                        [PREFFERRED_MIC_SETTING],
                        result => {
                            const prefferedMicId =
                                result[PREFFERRED_MIC_SETTING]
                            const mics = devices.filter(
                                device =>
                                    device?.kind === 'audioinput' &&
                                    device.deviceId &&
                                    device.deviceId !== 'default'
                            )

                            const preferredMic = prefferedMicId
                                ? mics.find(
                                      device =>
                                          device.deviceId === prefferedMicId
                                  )
                                : null

                            if (prefferedMicId === 'mute') {
                                setChosenMic('mute')
                                resolve('mute')
                            } else if (preferredMic) {
                                setChosenMic(preferredMic.deviceId)
                                resolve(preferredMic.deviceId)
                            } else if (mics.length === 1) {
                                setChosenMic(mics[0]?.deviceId)
                                resolve(mics[0]?.deviceId)
                            }

                            setMicOptions([...mics, MUTE_OPTION])
                            resolve('')
                        }
                    )
                })
            }),
        []
    )

    useEffect(() => {
        fetchMics()
    }, [fetchMics])

    const onMicChange = useCallback(newMic => {
        EXT_SERVICES.setToExtLocalStorage(
            PREFFERRED_MIC_SETTING,
            newMic,
            () => {
                setChosenMic(newMic)
            }
        )
    }, [])

    return useMemo(
        () => ({
            chosenMic,
            micOptions,
            onMicChange,
            fetchMics,
        }),
        [chosenMic, micOptions, onMicChange, fetchMics]
    )
}
