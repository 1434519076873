import React from 'react'

import { Grid, Typography, SvgIcon, makeStyles } from '@material-ui/core'

import { ReactComponent as MagicIcon } from 'assets/icons/icons-magic.svg'

const useStyles = makeStyles(() => ({
    comingSoon: {
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    whiteText: {
        color: '#00bcd4',
    },
}))

export const PageComingSoon = () => {
    const classes = useStyles()

    return (
        <Grid
            container
            spacing={3}
            direction="column"
            className={classes.comingSoon}
        >
            <Grid item>
                <SvgIcon>
                    <MagicIcon />
                </SvgIcon>
            </Grid>
            <Grid item>
                <Typography variant="h5" className={classes.whiteText}>
                    <b>Guidde is in action :)</b>
                </Typography>
            </Grid>
            <Grid item>
                <Typography className={classes.whiteText}>
                    we're collecting your data... in a few days you'll see your
                    team insights here
                </Typography>
            </Grid>
        </Grid>
    )
}
