import React from 'react'
import ReactDOM, { Renderer } from 'react-dom'
import { Provider } from 'react-redux'

import {
    createGenerateClassName,
    StylesProvider,
    MuiThemeProvider,
} from '@material-ui/core'

import { checkIsExtension } from 'services/environmentService'
import { decreaseZIndex } from 'services/styleFixer'

import { App } from 'App.ext'
import { AppSdk } from './App.sdk'
import './index.css'

import theme from 'app/theme'
import { AppProvider } from 'app/AppProvider'
import { NotificationProvider, NotificationsProvider } from 'UI/Components'
import { store } from 'ducks/store'
import { logSentryError } from './background/services'
import { DoubleComponent } from './UI/Components'

type RootElement = Parameters<Renderer>['0'][0]
type ContainerSelector = string

interface AppSetupConfig {
    rootElement: RootElement
    injectExtensionTo: ContainerSelector
    injectWebAppTo: ContainerSelector
}

const findElementInDOM = (selector: ContainerSelector) => {
    return document.querySelector(selector)
}

const renderAppToDOM = (element: RootElement, selector: ContainerSelector) => {
    ReactDOM.render(element, document.querySelector(selector))
}

const injectExtensionToDOM = (
    element: RootElement,
    selector: ContainerSelector
) => {
    const rootElementId = 'guidde-extension'

    const appContainer = document.createElement('div')
    appContainer.id = rootElementId
    appContainer.className = rootElementId

    const elementInDOM = findElementInDOM(selector)

    if (elementInDOM) {
        elementInDOM.append(appContainer)
        renderAppToDOM(element, `#${rootElementId}`)
    }
}

const initExtension = (element: RootElement, selector: ContainerSelector) => {
    const interval = setInterval(() => {
        // Can't inject the extension to DOM.
        if (!findElementInDOM(selector)) {
            return
        }

        clearInterval(interval)
        injectExtensionToDOM(element, selector)
    }, 100)
} // check every 100ms

const setupProject = (config: AppSetupConfig) => {
    if (checkIsExtension()) {
        initExtension(config.rootElement, config.injectExtensionTo)
    } else {
        renderAppToDOM(config.rootElement, config.injectWebAppTo)
    }
}

const initReact = (open: boolean, isFirstInstall: boolean = false) => {
    try {
        // In order to prevent style conflicts
        const generateClassName = createGenerateClassName({
            productionPrefix: 'guidde',
            disableGlobal: true,
            seed: 'ext',
        })

        decreaseZIndex()

        // Renders the web-app/extension content to DOM.
        setupProject({
            rootElement: (
                <React.StrictMode>
                    <MuiThemeProvider theme={theme}>
                        <StylesProvider generateClassName={generateClassName}>
                            <Provider store={store}>
                                <AppProvider isFirstInstall={isFirstInstall}>
                                    <NotificationProvider>
                                        <DoubleComponent
                                            sdkComponent={<AppSdk />}
                                            extComponent={
                                                <NotificationsProvider>
                                                    <App isOpen={open} />
                                                </NotificationsProvider>
                                            }
                                        />
                                    </NotificationProvider>
                                </AppProvider>
                            </Provider>
                        </StylesProvider>
                    </MuiThemeProvider>
                </React.StrictMode>
            ),
            injectExtensionTo: 'body',
            injectWebAppTo: '#root',
        })
    } catch (error) {
        logSentryError(JSON.stringify(error, Object.getOwnPropertyNames(error)))
    }
}

export { initReact }
