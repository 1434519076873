import { PlaybookType } from 'types'

import { TrackLinkHeader } from './TrackLinkHeader'
import { TrackLinkBody } from './TrackLinkBody'

type Props = {
    playbook: PlaybookType
    onClose: () => void
    onCopyClick?: () => void
}

export const TrackShareableLink = ({
    playbook,
    onClose,
    onCopyClick,
}: Props) => {
    return (
        <>
            <TrackLinkHeader playbook={playbook} onClose={onClose} />
            <TrackLinkBody playbook={playbook} onCopyClick={onCopyClick} />
        </>
    )
}
