import { Typography, Grid, makeStyles } from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'

import { PlaybookType } from 'types'

const useStyles = makeStyles(theme => ({
    wrapperGrid: {
        padding: theme.spacing(1, 2),
        cursor: 'pointer',
    },
    shareText: {
        fontSize: '12px',
        fontWeight: 'bold',
        paddingBottom: theme.spacing(),
    },
    spacesAndUsersAmount: { fontSize: '12px' },
}))

type Props = {
    playbook: PlaybookType
    onClose: () => void
}

export const TrackLinkHeader = ({ playbook, onClose }: Props) => {
    const classes = useStyles()

    const { teams = [], isPlaylist } = playbook

    const sharedSpaces = teams?.length
        ? teams.map(({ name }) => name).join(', ')
        : '0 Spaces'

    return (
        <Grid
            container
            spacing={2}
            justifyContent="flex-start"
            direction="row"
            alignItems="center"
            className={classes.wrapperGrid}
            onClick={onClose}
        >
            <NavigateNextIcon />
            <Grid item>
                <Typography className={classes.shareText}>
                    Share {isPlaylist ? 'Playlist' : 'Playbook'}
                </Typography>
                <Typography className={classes.spacesAndUsersAmount}>
                    Shared with: {sharedSpaces}
                </Typography>
            </Grid>
        </Grid>
    )
}
