import React, { useState, useEffect } from 'react'

import { useSelector } from 'react-redux'
import { RootState } from 'ducks/rootReducer'

import {
    Box,
    Typography,
    Grid,
    LinearProgress,
    Divider,
    CircularProgress,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import {
    getTopCreatorsReports,
    getTopPlaybooksReports,
} from 'background/services'
import { TopCreators } from './TopCreators'
import { TopPlaybooks } from './TopPlaybooks'
import { RateItem } from './RateItem'
import { PageComingSoon } from './PageComingSoon'
import moment from 'moment'

const emptyBestPlaybook = {
    title: '',
    value: 0,
    screenshotUrl: '',
    pbID: '',
    id: '',
}

const useStyles = makeStyles(theme => ({
    hoveringRate: {
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
}))

const NO_RANK_MSG = 'Not Ranked'
type ReportData = {
    value?: number
    times?: number
}
export type ReportState = {
    data?: Array<ReportData>
}

export const PageInsights = () => {
    const { user } = useSelector((state: RootState) => state.user)
    const [userRank, setUserRank] = useState(NO_RANK_MSG)
    const [bestPlaybook, setBestPlaybook] = useState(emptyBestPlaybook)
    const classes = useStyles()

    const currDate = moment(),
        creationDate = moment(user.createdAt, 'x')
    if (currDate.diff(creationDate, 'days') <= 7) {
        return <PageComingSoon />
    }

    return (
        <Box p={3}>
            <Typography
                component="h1"
                style={{ fontSize: '24px', fontWeight: 'bold' }}
            >
                Weekly Insights for {user.displayName}
            </Typography>
            <Box mt={2}>
                <Divider />
            </Box>
            <Grid container spacing={2} justifyContent="space-between">
                <Grid item xs={6}>
                    <Box mt={2} mb={2}>
                        <Typography variant="h5">
                            Your Creator Ranking
                        </Typography>
                        {user ? (
                            <RateItem
                                name={user?.displayName}
                                rate={userRank}
                                imgURL={user?.photoURL}
                            />
                        ) : (
                            <LinearProgress />
                        )}
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box mt={2} mb={2}>
                        <Typography variant="h5">Your Top Playbook</Typography>
                        {bestPlaybook && bestPlaybook !== emptyBestPlaybook ? (
                            <RateItem
                                name={bestPlaybook.title}
                                rate={bestPlaybook.value}
                                imgURL={bestPlaybook.screenshotUrl}
                                id={bestPlaybook.pbID || bestPlaybook.id}
                                imgVariant="square"
                                className={classes.hoveringRate}
                            />
                        ) : (
                            <Box p={2} pl={0}>
                                <Typography>
                                    Start creating playbooks to see the best one
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </Grid>
            </Grid>
            <Grid container spacing={2} justifyContent="space-between">
                <Grid item xs={6}>
                    <TopCreators
                        userId={user.uid}
                        setRank={rank => setUserRank(rank || NO_RANK_MSG)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TopPlaybooks
                        userId={user.uid}
                        setUserPlaybook={setBestPlaybook}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}
