import { useSelector } from 'react-redux'

import { option, uiId } from 'services/utils'
import * as AssetsService from 'services/extServices'
import { RootState } from 'ducks/rootReducer'

import { makeStyles } from '@material-ui/core'
import { FilterSelectSingle } from 'UI/Components'
import IconApps from 'assets/icons/icon-apps.svg'

const useStyles = makeStyles(theme => ({
    labelIcon: {
        marginRight: theme.spacing(0.5),
    },
}))

type Props = {
    value: string
    onUpdate: any
}

export const Applications = ({ value, onUpdate }: Props) => {
    const classes = useStyles()

    const appOptions: {
        value: string
        label: string
    }[] = useSelector((state: RootState) =>
        state.apps?.map(app => option(app.id, app.title))
    )

    return (
        <FilterSelectSingle
            value={value}
            onChange={onUpdate}
            id={uiId('application-filter')}
            options={[
                { label: 'Any application', value: '' },
                ...appOptions?.sort((a, b): number =>
                    a.label > b.label ? 1 : -1
                ),
            ]}
            labelIcon={
                <img
                    className={classes.labelIcon}
                    src={AssetsService.getResourceURL(IconApps)}
                    alt="app"
                />
            }
        />
    )
}
