import { useSelector } from 'react-redux'

import Box from '@material-ui/core/Box'

import { RootState } from 'ducks/rootReducer'
import { option } from 'services/utils'

import { Tag } from 'UI/Components/Tag'
import { AddTag } from 'UI/Components/AddTag'

type Props = {
    tags: Array<string>
    defaultTags: Array<string> | null
    onUpdateTags: (value: Array<string>) => void
    onUpdateDefTags: (value: Array<string>) => void
}

export const Tags = ({
    tags,
    defaultTags,
    onUpdateTags,
    onUpdateDefTags,
}: Props) => {
    const handleDeleteTag = index => {
        const newTags = [...tags]
        newTags.splice(index, 1)

        onUpdateTags(newTags)
    }

    const handleDeleteDefTag = index => {
        if (defaultTags) {
            const newDefTags = [...defaultTags]
            newDefTags.splice(index, 1)
            onUpdateDefTags(newDefTags)
        }
    }

    const handleAddTag = (tagValue: string) => {
        if (
            !!tagValue.replaceAll(' ', '') &&
            !tags.includes(tagValue) &&
            !defaultTags?.includes(tagValue)
        ) {
            onUpdateTags([...tags, tagValue])
        }
    }

    const availableTags = useSelector((state: RootState) => state.tags)

    const tagOptions: Array<{
        value: string
        label: string
    }> = availableTags
        ? Object.keys(availableTags).map((tag, idx) =>
              option(tag, `${tag} (${Object.values(availableTags)[idx]})`)
          )
        : []

    return (
        <Box display="flex" flexDirection="row" alignItems="center">
            <Box display="flex" flexDirection="row" alignItems="center">
                {defaultTags?.map((defaultTag, idx) => (
                    <Tag
                        key={idx}
                        isHighlighted
                        tag={defaultTag}
                        onDelete={() => handleDeleteDefTag(idx)}
                    />
                ))}
                {(defaultTags
                    ? tags.filter(tag => !defaultTags.includes(tag))
                    : tags
                ).map((tag, idx) => (
                    <Tag
                        key={idx}
                        tag={tag}
                        onDelete={() => handleDeleteTag(tags.indexOf(tag))}
                    />
                ))}
            </Box>

            <AddTag autocompleteOptions={tagOptions} onApply={handleAddTag} />
        </Box>
    )
}
