import { useState } from 'react'
import { useSelector } from 'react-redux'

import { Box, makeStyles, Tab, Tabs } from '@material-ui/core'

import { RootState } from 'ducks/rootReducer'
import { TranscriptTab, InfoTab, Playlist } from './tabs'

const useStyles = makeStyles({
    tab: {
        minWidth: '140px',
    },
})

const TabPanel = props => {
    const { children, value, index, ...other } = props

    return (
        <Box hidden={value !== index} {...other}>
            <Box p={3}>{children}</Box>
        </Box>
    )
}

export const SidePanel = () => {
    const classes = useStyles()

    const [tabValue, setTabValue] = useState(0)

    const handleTabChange = (_, newValue) => {
        setTabValue(newValue)
    }

    const { playbookList, active } = useSelector(
        (state: RootState) => state.playlist
    )

    const playbook = playbookList?.[active]

    const { files, links, transcriptUrl } = playbook || {}

    const isInfoVisible = !!(files?.length || links?.length)

    return (
        <Box p={2}>
            <Tabs
                value={tabValue}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                variant={isInfoVisible ? 'scrollable' : 'standard'}
            >
                <Tab label="Playlist" className={classes.tab} />
                <Tab label="Transcript" className={classes.tab} />
                {isInfoVisible && <Tab label="Info" className={classes.tab} />}
            </Tabs>

            <TabPanel value={tabValue} index={0}>
                <Playlist />
            </TabPanel>

            <TabPanel value={tabValue} index={1}>
                <TranscriptTab transcriptUrl={transcriptUrl} />
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
                <InfoTab
                    files={files}
                    links={links}
                    isInfoVisible={isInfoVisible}
                />
            </TabPanel>
        </Box>
    )
}
